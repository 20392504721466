import clsx from "clsx";
import { FILE_STORAGE_SERVER_URL, ICON_SIZES } from "../../helpers/constants";
import {
  MdArrowForward,
  MdCancel,
  MdDelete,
  MdFreeCancellation,
  MdInfo,
  MdNoAccounts,
  MdOutlineCancel,
  MdPerson,
  MdPersonAdd,
  MdPersonRemove,
  MdRemove,
  MdUnsubscribe,
} from "react-icons/md";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import NetworkProfile from "../NetworkProfile/NetworkProfile";
import { useState } from "react";

interface IPeopleList {
  people: Array<any>;
  actions?: Array<string>;
  onActionClick?: ({
    action,
    invite,
    user,
  }: {
    action: "CANCEL" | "ACCEPT" | "SEND" | "INFO" | "REJECT" | "DELETE";
    invite: string;
    user: any;
  }) => void;
  twoColumns?: boolean;
}

const PeopleList: React.FC<IPeopleList> = ({
  people,
  actions,
  onActionClick,
  twoColumns = false,
}) => {
  const [openedProfileUserData, setOpenedProfileUserData] = useState<any>(null);

  return (
    <>
      <ul
        className={clsx([
          "w-full",
          "gap-2",
          twoColumns ? ["grid", "grid-cols-2"] : ["flex", "flex-col"],
        ])}
      >
        {people?.map?.((el: any, index: any) => {
          return (
            <li
              key={index}
              className={clsx([
                "max-w-md",
                "w-full",
                "backdrop-blur-sm",
                "bg-lime-100",
                "shadow-md",
                "rounded-lg",
                "overflow-hidden",
                "pointer-events-auto",
                "flex",
                "text-black",
                {
                  "flex-row": !twoColumns,
                  "flex-col": twoColumns,
                },
              ])}
            >
              <div
                className={clsx([
                  "flex-1",
                  {
                    "p-4": !twoColumns,
                    "p-2": twoColumns,
                  },
                ])}
                onClick={() => setOpenedProfileUserData(el)}
              >
                <div
                  className={clsx([
                    "flex",
                    "items-center",
                    {
                      "flex-row": !twoColumns,
                      "flex-col gap-2": twoColumns,
                    },
                  ])}
                >
                  <div className="flex-shrink-0 pt-0.5 text-green-500">
                    {el.picture ? (
                      <img
                        src={`${FILE_STORAGE_SERVER_URL}/${el.picture}`}
                        alt={el.name}
                        className={clsx([
                          {
                            "h-[50px] w-[50px]": !twoColumns,
                            "h-[60px] w-[60px]": twoColumns,
                          },
                          "object-cover",
                          "rounded-full",
                          "overflow-hidden",
                        ])}
                      />
                    ) : (
                      <MdPerson
                        className={clsx([
                          "h-[50px]",
                          "w-[50px]",
                          "object-cover",
                          "rounded-full",
                          "overflow-hidden",
                          "text-gray-300",
                          "bg-white",
                        ])}
                      />
                    )}
                  </div>
                  <div className="ml-3 flex-1 truncate text-gray-800">
                    <p className="text-sm font-medium truncate">{el.name}</p>
                    <p className="text-xs truncate">{el.email}</p>
                  </div>
                </div>
              </div>
              {!twoColumns && (
                <div
                  className={clsx([
                    "flex",
                    "justify-center",
                    "items-center",
                    "pr-4",
                  ])}
                  onClick={(e) => {
                    e?.stopPropagation?.();
                  }}
                >
                  <ButtonGroup
                    buttons={[
                      {
                        kind: "delete",
                        onClick: () =>
                          onActionClick?.({
                            action: "REJECT",
                            invite: el._id,
                            user: el,
                          }),
                        hide: !actions?.includes("REJECT"),
                        icon: <MdCancel size={ICON_SIZES.SM} />,
                      },
                      {
                        kind: "delete",
                        onClick: () =>
                          onActionClick?.({
                            action: "CANCEL",
                            invite: el._id,
                            user: el,
                          }),
                        hide: !actions?.includes("CANCEL"),
                        icon: <MdCancel size={ICON_SIZES.SM} />,
                      },
                      {
                        kind: "save",
                        onClick: () =>
                          onActionClick?.({
                            action: "ACCEPT",
                            invite: el._id,
                            user: el,
                          }),
                        hide: !actions?.includes("ACCEPT"),
                        icon: <MdPersonAdd size={ICON_SIZES.SM} />,
                      },
                      {
                        kind: "save",
                        onClick: () =>
                          onActionClick?.({
                            action: "SEND",
                            invite: el._id,
                            user: el,
                          }),
                        hide: !actions?.includes("SEND"),
                        icon: <MdPersonAdd size={ICON_SIZES.SM} />,
                      },
                      {
                        kind: "normal",
                        onClick: () => {
                          setOpenedProfileUserData(el);
                        },
                        hide: !actions?.includes("INFO"),
                        icon: <MdInfo size={ICON_SIZES.SM} />,
                      },
                    ]}
                    gap={1}
                  />
                </div>
              )}
            </li>
          );
        })}
      </ul>
      {openedProfileUserData && (
        <NetworkProfile
          profileInfo={openedProfileUserData}
          onClose={() => setOpenedProfileUserData(null)}
          actions={[
            {
              kind: "delete",
              onClick: () => {
                onActionClick?.({
                  action: "REJECT",
                  invite: openedProfileUserData._id,
                  user: openedProfileUserData,
                });
              },
              hide: !actions?.includes("REJECT"),
              icon: <MdCancel size={ICON_SIZES.SM} />,
              text: "Rechazar",
            },
            {
              kind: "delete",
              onClick: () => {
                onActionClick?.({
                  action: "CANCEL",
                  invite: openedProfileUserData._id,
                  user: openedProfileUserData,
                });
              },
              hide: !actions?.includes("CANCEL"),
              icon: <MdCancel size={ICON_SIZES.SM} />,
              text: "Cancelar",
            },
            {
              kind: "delete",
              onClick: () => {
                onActionClick?.({
                  action: "DELETE",
                  invite: openedProfileUserData._id,
                  user: openedProfileUserData,
                });
              },
              hide: !actions?.includes("DELETE"),
              icon: <MdPersonRemove size={ICON_SIZES.SM} />,
              text: "Eliminar",
            },
            {
              kind: "save",
              onClick: () => {
                onActionClick?.({
                  action: "ACCEPT",
                  invite: openedProfileUserData._id,
                  user: openedProfileUserData,
                });
              },
              hide: !actions?.includes("ACCEPT"),
              icon: <MdPersonAdd size={ICON_SIZES.SM} />,
            },
            {
              kind: "save",
              onClick: () => {
                onActionClick?.({
                  action: "SEND",
                  invite: openedProfileUserData._id,
                  user: openedProfileUserData,
                });
              },
              hide: !actions?.includes("SEND"),
              icon: <MdPersonAdd size={ICON_SIZES.SM} />,
              text: "Invitar",
            },
          ]}
        />
      )}
    </>
  );
};

export default PeopleList;
