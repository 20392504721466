import React from "react";
import clsx from "clsx";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import { MdAdd, MdDelete } from "react-icons/md";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useTranslation } from "react-i18next";
import CustomLinks from "../../components/CustomLinks/CustomLinks";
import PageTitle from "../../components/PageTitle/PageTitle";
import useModalsStore from "../../store/modalsStore";
import { BUTTON_STYLES, ICON_SIZES } from "../../helpers/constants";
import { useMediaQuery } from "react-responsive";

const LinksPage: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { setShowCreateNewTask } = useModalsStore();

  return (
    <TwoSidebars>
      <div
        className={clsx([
          "flex",
          "items-center",
          "justify-center",
          "w-full",
          "gap-4",
          "flex-col",
          "text-gray-500",
        ])}
      >
        <PageTitle
          title={t("shared.my_links")}
          subtitle={t("notes.notes_page_subtitle")}
          rightSide={
            <ButtonGroup
              align="right"
              gap={1}
              buttons={[
                {
                  icon: <MdAdd size={ICON_SIZES.SM} />,
                  tooltip: t("shared.add_note"),
                  onClick: () => {
                    setShowCreateNewTask(true);
                  },
                  classes: isMobile ? BUTTON_STYLES.HEADING : "",
                },
                {
                  icon: <MdDelete size={ICON_SIZES.SM} />,
                  onClick: () => {
                    setShowCreateNewTask(true);
                  },
                  classes: isMobile ? BUTTON_STYLES.HEADING : "",
                },
              ]}
            />
          }
        />
        <CustomLinks />
      </div>
    </TwoSidebars>
  );
};

export default LinksPage;
