import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  searchTransactions,
  updateTransaction,
} from "../api/transactions/transactions";
import { useCallback, useMemo } from "react";
import {
  getTodayTransactionsFromTransactions,
  getTotalByTransactionType,
  getUniqueDescriptionsFromTransactions,
  getUniquePaidToFromTransactions,
} from "../helpers/utils";
import { EXPENSE_TYPE, INCOME_TYPE } from "../helpers/constants";
import useMainFilter from "./useMainFilter";

interface IuseTransactions {
  // extraKeys?: Array<any>;
}

export default function useTransactions() {
  const queryClient = useQueryClient();
  const { mainFilter } = useMainFilter();

  const getParams = useCallback(() => {
    const params = new URLSearchParams();
    const {
      dateFrom,
      dateTo,
      payTo,
      type,
      status,
      paymentMethod,
      tags,
      category,
      account,
    } = mainFilter;

    if (dateFrom) {
      params.append("dateFrom", dateFrom);
    }
    if (dateTo) {
      params.append("dateTo", dateTo);
    }
    if (payTo?.length) {
      params.append("payTo", JSON.stringify(payTo));
    }
    if (type) {
      params.append("type", type);
    }

    if (category?.length) {
      params.append("category", JSON.stringify(category));
    }

    if (account) {
      params.append("account", account);
    }

    if (status?.length) {
      params.append("status", JSON.stringify(status));
    }
    if (paymentMethod?.length) {
      params.append("paymentMethod", JSON.stringify(paymentMethod));
    }

    if (tags?.length) {
      params.append("tags", JSON.stringify(tags));
    }

    return params;
  }, [mainFilter]);

  const resetCache = async () => {
    return await Promise.allSettled([
      await queryClient.refetchQueries({ queryKey: ["all-transactions"] }),
      await queryClient.refetchQueries({ queryKey: ["all-categories"] }),
      await queryClient.refetchQueries({
        queryKey: ["transactions", JSON.stringify(mainFilter)],
      }),
    ]);
  };

  const transactions = useQuery({
    queryKey: ["transactions", JSON.stringify(mainFilter)],
    queryFn: async () => {
      return await searchTransactions({
        fieldsToSearch: getParams(),
      });
    },
  });

  return {
    transactions,
    resetCache,
  };
}

export function useAllTransactions() {
  const allTransactions: any = useQuery({
    queryKey: ["all-transactions"],
    queryFn: async () => {
      return await searchTransactions({
        fieldsToSearch: null,
      });
    },
  });

  const totalIncome = useMemo(
    () =>
      getTotalByTransactionType({
        type: INCOME_TYPE,
        transactions: allTransactions?.data?.data,
      }),
    [allTransactions?.data?.data]
  );

  const totalExpenses = useMemo(
    () =>
      getTotalByTransactionType({
        type: EXPENSE_TYPE,
        transactions: allTransactions?.data?.data,
      }),
    [allTransactions?.data?.data]
  );

  const todayTransactions = useMemo(
    () =>
      getTodayTransactionsFromTransactions({
        transactions: allTransactions?.data?.data,
      }),
    [allTransactions?.data?.data]
  );

  return {
    allTransactions,
    totalExpenses,
    totalIncome,
    todayTotals: {
      income: totalIncome,
      expenses: totalExpenses,
    },
    todayTransactions,
  };
}

export function useAutocomplete() {
  const { allTransactions } = useAllTransactions();

  const payTo = useMemo(
    () =>
      getUniquePaidToFromTransactions({
        transactions: allTransactions?.data?.data,
      })
        ?.sort?.()
        ?.map((value) => ({
          label: `${value}`?.trim?.(),
          value: `${value}`?.trim?.(),
        })),
    [allTransactions?.data?.data]
  );

  const tags = useMemo(
    () =>
      allTransactions?.data?.data?.reduce?.((acc: any, current: any) => {
        if (current.tags?.length) {
          return [...acc, ...current.tags];
        }

        return acc;
      }, []),
    [allTransactions?.data?.data]
  );

  const descriptions = useMemo(
    () =>
      getUniqueDescriptionsFromTransactions({
        transactions: allTransactions?.data?.data,
      })
        ?.sort?.()
        ?.map?.((value) => ({
          label: `${value}`?.trim?.(),
          value: `${value}`?.trim?.(),
        })) || [],
    [allTransactions?.data?.data]
  );

  return {
    allPayToList: payTo,
    allTagList: Array.from(new Set(tags))?.sort?.(),
    allDescriptionList: descriptions,
  };
}
