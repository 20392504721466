import {
  MdAccountBox,
  MdArrowBack,
  MdAttachMoney,
  MdCheck,
  MdCloudDone,
  MdCompareArrows,
  MdFace,
  MdFace2,
  MdFace3,
  MdFace4,
  MdFace5,
  MdFace6,
  MdInsertPhoto,
  MdNoPhotography,
  MdOutlinePhotoLibrary,
  MdOutlineShoppingCart,
  MdPerson,
  MdPerson2,
  MdPerson3,
  MdPerson4,
  MdPhoto,
  MdPhotoLibrary,
  MdSettings,
  MdViewSidebar,
} from "react-icons/md";
import PageTitle from "../../components/PageTitle/PageTitle";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { Link, useNavigate } from "react-router-dom";
import userPreferences from "../../store/userPreferences";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import TextInput from "../../components/TextInput/TextInput";
import Select from "../../components/Select/Select";
import { useTranslation } from "react-i18next";
import { useEffect, useId, useState } from "react";
import usersStore from "../../store/usersStore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingWithText from "../../components/LoadingWithText/LoadingWithText";
import apiClient from "../../helpers/api-client";
import {
  API_ENDPOINT_UPDATE_PROFILE,
  APP_USER_ID_KEY,
  BUTTON_STYLES,
  FILE_STORAGE_SERVER_URL,
  ICON_SIZES,
} from "../../helpers/constants";
import { toast } from "react-toastify";
import { COUNTRY_SELECT_OPTIONS, showNotification } from "../../helpers/utils";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { CONFIRM_DIALOG_ICON_SIZE } from "../../constants/icons";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { QRCodeSVG } from "qrcode.react";
import Button from "../../components/Button/Button";
import { classNames } from "@react-pdf-viewer/core";
import useCurrentUser from "../../hooks/useCurrentUser";

const FORM_ID = "update-profile";

const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const { userInfo } = useCurrentUser();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [country, setCountry] = useState<string>("");
  const [name, setName] = useState<string>(userInfo?.name || "");
  const [picture, setPicture] = useState<string>(
    userInfo?.picture ? `${FILE_STORAGE_SERVER_URL}/${userInfo?.picture}` : ""
  );

  useEffect(() => {
    setName(userInfo?.name || "");
    setPicture(
      userInfo?.picture ? `${FILE_STORAGE_SERVER_URL}/${userInfo?.picture}` : ""
    );
  }, [userInfo]);

  const [isUpdatingProfile, setIsUpdatingProfile] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const tooltipId = useId();
  const { t } = useTranslation();

  const { rightPanelIsVisible, setRightPanelIsVisible } = userPreferences();

  const updateProfile = () => {
    setIsUpdatingProfile(true);

    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    }

    formData.append("name", name);

    apiClient
      .put(API_ENDPOINT_UPDATE_PROFILE, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(async (res: any) => {
        showNotification({
          kind: "success",
          message: "Perfil actualizado correctamente",
        });
        setShowConfirmDialog(false);
      })
      .catch((e) => {
        showNotification({
          message: "Error actualizando tu perfil",
          kind: "error",
        });
      })
      .finally(() => {
        setIsUpdatingProfile(false);
      });
  };

  return (
    <>
      <TwoSidebars
        right={
          <>
            {<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}
            {/* <TasksListPanel />
            <CustomLinks /> */}
          </>
        }
      >
        <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
          <PageTitle
            title={t("settings.title")}
            subtitle={t("profile_page.title")}
            titleLink="/settings"
            isLoading={false}
            rightSide={
              <>
                <ButtonGroup
                  align="right"
                  gap={1}
                  buttons={[
                    {
                      icon: <MdArrowBack size={ICON_SIZES.SM} />,
                      tooltip: "Atras",
                      onClick: () => {
                        navigate("/settings");
                      },
                      hide: !isMobile,
                      classes: isMobile ? BUTTON_STYLES.HEADING : "",
                    },
                    {
                      onClick: () => {
                        const el = document?.getElementById?.(
                          "select-file-to-upload"
                        );

                        if (el) {
                          el?.click?.();
                        }
                      },

                      icon: <MdPhotoLibrary size={ICON_SIZES.SM} />,
                      tooltip: "Selecciona una foto",
                      classes: BUTTON_STYLES.HEADING,
                    },
                    {
                      icon: <MdOutlineShoppingCart size={ICON_SIZES.SM} />,
                      tooltip: "Añadir gasto",
                      onClick: () => {
                        navigate("/transactions/expense");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdAttachMoney size={ICON_SIZES.SM} />,
                      tooltip: "Añadir ingreso",
                      hide: isMobile,
                      onClick: () => {
                        navigate("/transactions/income");
                      },
                    },
                    {
                      icon: <MdCompareArrows size={ICON_SIZES.SM} />,
                      tooltip: "Transacciones",
                      onClick: () => {
                        navigate("/transactions");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdViewSidebar size={ICON_SIZES.SM} />,
                      tooltip: "Panel lateral",
                      kind: rightPanelIsVisible ? "primary" : "secondary",
                      hide: isMobile,
                      onClick: () => {
                        setRightPanelIsVisible(!rightPanelIsVisible);
                      },
                    },
                  ]}
                />
              </>
            }
          />
          <div className="grid grid-cols-1 gap-4">
            <input
              className="hidden"
              accept="image/*"
              type="file"
              id="select-file-to-upload"
              onChange={async (e: any) => {
                const attachment = e?.target?.files;

                if (attachment?.length) {
                  setFile(attachment?.[0]);
                  setPicture(URL.createObjectURL(attachment?.[0]));
                } else {
                  setFile(null);
                  setPicture("");
                }
              }}
              required
            />
            <SectionTitle title={t("profile_page.personal_info_title")} />

            <div
              className={clsx(["flex", "flex-col", "gap-4", "items-center"])}
            >
              {picture ? (
                <div
                  className={clsx([
                    "flex",
                    "justify-between",
                    "items-end",
                    "w-full",
                    "gap-4",
                    // "bg-red-400",
                  ])}
                >
                  <div>
                    <form
                      className={clsx([
                        "grid",
                        "grid-cols-1",
                        "md:grid-cols-2",
                        "gap-4",
                      ])}
                      id={FORM_ID}
                      onSubmit={async (e: any) => {
                        e?.preventDefault?.();
                        setShowConfirmDialog(true);
                      }}
                    >
                      <TextInput
                        value={name}
                        onChange={(e: any) => setName(e?.target?.value)}
                        label={t("profile_page.name_label")}
                        placeholder={t("profile_page.name_placeholder")}
                        required
                        autoComplete="name nickname"
                      />
                      <Select
                        options={COUNTRY_SELECT_OPTIONS}
                        value={country}
                        onChange={(e: any) => setCountry(e?.target?.value)}
                        label={t("profile_page.country_label")}
                        autoComplete="country"
                      />
                    </form>
                  </div>
                  <LazyLoadImage
                    alt=""
                    className={clsx([
                      "w-full md:w-[200px]",
                      "rounded-md",
                      "shadow-md",
                      "cursor-pointer",
                      // "rounded-full",
                      "bg-gray-300",
                      "!h-[120px] !w-[120px] !max-h-[120px] !max-w-[120px]",
                      // "mb-5 md:mb-10",
                      "object-cover",
                    ])}
                    data-tooltip-id={tooltipId}
                    data-tooltip-content="Insertar imagen"
                    data-tooltip-float
                    data-tooltip-position-strategy="fixed"
                    data-tooltip-offset={20}
                    src={picture}
                    placeholder={
                      <div
                        className={clsx([
                          "flex",
                          "h-full",
                          "flex",
                          "items-center",
                          "justify-center",
                          "w-full",
                        ])}
                      >
                        <LoadingWithText />
                      </div>
                    }
                    onClick={() => {
                      const el = document?.getElementById?.(
                        "select-file-to-upload"
                      );

                      if (el) {
                        el?.click?.();
                      }
                    }}
                  />
                </div>
              ) : (
                <>
                  <MdOutlinePhotoLibrary
                    onClick={() => {
                      const el = document?.getElementById?.(
                        "select-file-to-upload"
                      );

                      if (el) {
                        el?.click?.();
                      }
                    }}
                    size={128}
                    className="text-gray-600"
                  />
                </>
              )}
              {/* <div className={clsx(["flex", "items-center", "justify-center"])}>
                <Button
                  onClick={() => {
                    const el = document?.getElementById?.(
                      "select-file-to-upload"
                    );

                    if (el) {
                      el?.click?.();
                    }
                  }}
                  icon={<MdPhoto size={ICON_SIZES.SM} />}
                  text="Selecciona una foto"
                />
              </div> */}
            </div>

            <div className={clsx(["flex", "gap-4", "flex-col"])}>
              {/* <InfoBlock message={t("profile_page.personal_info_note")} /> */}
              <form
                className={clsx([
                  "grid",
                  "grid-cols-1",
                  "md:grid-cols-2",
                  "gap-4",
                ])}
                id={FORM_ID}
                onSubmit={async (e: any) => {
                  e?.preventDefault?.();
                  setShowConfirmDialog(true);
                }}
              >
                <TextInput
                  value={name}
                  onChange={(e: any) => setName(e?.target?.value)}
                  label={t("profile_page.name_label")}
                  placeholder={t("profile_page.name_placeholder")}
                  required
                  autoComplete="name nickname"
                />
                <Select
                  options={COUNTRY_SELECT_OPTIONS}
                  value={country}
                  onChange={(e: any) => setCountry(e?.target?.value)}
                  label={t("profile_page.country_label")}
                  autoComplete="country"
                />
              </form>
              <div
                className={clsx([
                  "w-full",
                  "flex",
                  "justify-end",
                  "bg-inherit",
                  "md:py-3",
                  "sticky",
                  "bottom-20",
                  "md:bottom-0",
                ])}
              >
                <ButtonGroup
                  buttons={[
                    {
                      text: t("shared.update"),
                      onClick: () => {
                        setShowConfirmDialog(true);
                      },
                      icon: <MdCloudDone size={32} />,
                      kind: "update",
                      isLoading: isUpdatingProfile,
                      disabled: isUpdatingProfile,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </TwoSidebars>
      {showConfirmDialog && (
        <ConfirmDialog
          icon={<MdAccountBox size={CONFIRM_DIALOG_ICON_SIZE} />}
          title={t("dialogs.profile.update_dialog_title")}
          description={t("dialogs.profile.update_dialog_question")}
          onClose={() => {
            setShowConfirmDialog(false);
          }}
          confirmKind="save"
          onConfirm={() => {
            updateProfile();
          }}
          isLoading={isUpdatingProfile}
        />
      )}
    </>
  );
};

export default ProfilePage;
