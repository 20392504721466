import { ReactElement, useId, useState } from "react";
import Button from "../Button/Button";
import clsx from "clsx";
import { MdClose, MdMoreHoriz } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import { ICON_SIZES } from "../../helpers/constants";

interface IButtonWithMenu {
  options: Array<any>;
  icon?: ReactElement;
  tooltip?: string;
  hide?: boolean;
  classes?: string;
  text?: string;
}

const ButtonWithMenu: React.FC<IButtonWithMenu> = ({
  options,
  icon = <MdMoreHoriz size={ICON_SIZES.SM} />,
  tooltip = "",
  hide = false,
  classes = "",
  text = "",
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const tooltipId = useId();

  if (hide) {
    return null;
  }

  const hasOnlyIcons = !Boolean(
    options?.reduce?.((acc, currentValue) => {
      acc += currentValue?.text ? 1 : 0;
      return acc;
    }, 0)
  );

  return (
    <>
      {isOpen && (
        <div
          className={clsx([
            "fixed",
            "top-0",
            "left-0",
            "right-0",
            "bottom-0",
            "bg-transparent",
          ])}
          onClick={() => {
            setIsOpen(false);
          }}
        />
      )}
      <div className={clsx(["relative", "text-base", "text-gray-500"])}>
        <Button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          tooltip={tooltip}
          icon={icon}
          classes={classes}
          text={text}
        />
        {isOpen && (
          <>
            <ul
              className={clsx([
                "absolute",
                "top-[calc(100%+4px)]",
                "right-0",
                "bg-white",
                "md:border",
                "rounded-md md:rounded-lg",
                "overflow-hidden",
                "shadow-md",
                "min-w-[170px]",
                "max-h-[400px]",
              ])}
            >
              {options.map((option, index) => (
                <li
                  key={index}
                  onClick={() => {
                    if (!option?.disabled) {
                      option?.onClick?.();
                      if (!option?.keepOpenOnClick) {
                        setIsOpen(false);
                      }
                    }
                  }}
                  data-tooltip-id={tooltipId}
                  data-tooltip-content={option?.tooltip}
                  data-tooltip-float
                  data-tooltip-position-strategy="fixed"
                  data-tooltip-offset={20}
                  className={clsx([
                    "px-2 md:px-4",
                    "py-3 md:py-3",
                    "whitespace-nowrap",
                    {
                      "border-b": index < options.length - 1,
                      "opacity-50 text-gray-400 blurx-[1px]":
                        !!option?.disabled,
                      hidden: !!option?.hide,
                      "hover:bg-gray-600 hover:text-white": !option?.disabled,
                      "cursor-pointer": !option?.disabled,
                      "cursor-not-allowed": option?.disabled,
                    },
                    "flex",
                    "flex-row",
                    "gap-1",
                    "md:gap-3",
                    "items-center",
                    "text-sm",
                    "md:text-base",
                    option?.className || "",
                  ])}
                >
                  {option.icon}
                  {option.text}
                </li>
              ))}
              {/* <li
                onClick={() => {
                  setIsOpen(false);
                }}
                className={clsx([
                  "px-4",
                  "py-3",
                  "whitespace-nowrap",
                  "hover:bg-[#3b82f6]/80 hover:text-white",
                  "cursor-pointer",
                  "flex",
                  "flex-row",
                  "gap-3",
                  "items-center",
                ])}
              >
                <MdClose size={ICON_SIZES.SM} />
                {!hasOnlyIcons && <>Cerrar</>}
              </li> */}
            </ul>
          </>
        )}
      </div>
      <Tooltip id={tooltipId} />
    </>
  );
};

export default ButtonWithMenu;
