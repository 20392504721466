import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";
import clsx from "clsx";
import {
  MdAdd,
  MdDelete,
  MdEdit,
  MdEmail,
  MdLink,
  MdPhone,
} from "react-icons/md";
import useModalsStore from "../../store/modalsStore";
import linksStore from "../../store/linksStore";
import { useEffect, useState } from "react";
import Button from "../Button/Button";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import toast from "react-hot-toast";

import EmptyComponentState from "../EmptyComponentState/EmptyComponentState";
import LoadingWithText from "../LoadingWithText/LoadingWithText";
import { CONFIRM_DIALOG_ICON_SIZE, DELETE_ICON } from "../../constants/icons";
import { useTranslation } from "react-i18next";
import RenderIcon from "../RenderIcon/RenderIcon";
import { showNotification } from "../../helpers/utils";

const CustomLinks: React.FC = () => {
  const { deleteLink, refreshStoreLinks, storeLinks, isLoadingStoreLinks } =
    linksStore();

  const { t } = useTranslation();

  const { setShowCreateNewLink } = useModalsStore();
  const [showDeleteLinkDialog, setShowDeleteLinkDialog] =
    useState<boolean>(false);
  const [isDeletingLink, setIsDeletingLink] = useState<boolean>(false);
  const [linkToDelete, setLinkToDelete] = useState<string>("");
  const [userHasLinks, setUserHasLinks] = useState<boolean>(false);

  const getLink = ({ link, type }: { link: string; type: string }) => {
    switch (type) {
      case "url": {
        return link;
      }

      case "tel": {
        return `tel:${link}`;
      }

      case "email": {
        return `mailto:${link}`;
      }

      default: {
        return "";
      }
    }
  };

  useEffect(() => {
    setUserHasLinks(!!storeLinks?.length);
  }, [storeLinks]);

  useEffect(() => {
    refreshStoreLinks({});
  }, [refreshStoreLinks]);

  return (
    <>
      <div className={clsx(["flex", "flex-col", "gap-2", "w-full"])}>
        {isLoadingStoreLinks ? (
          <div className={clsx(["flex", "w-full", "justify-start"])}>
            <LoadingWithText
              text={t("shared.loading_links", { count: 1111 })}
            />
          </div>
        ) : (
          <>
            {userHasLinks ? (
              <div className={clsx(["flex", "flex-col", "gap-2", "w-full"])}>
                <SectionTitle title={t("custom_links.title")} />
                <div
                  className={clsx(["w-full", "grid", "grid-cols-3", "gap-2"])}
                >
                  {storeLinks?.map?.(
                    ({ _id, icon, url, target, name, type }, key) => (
                      <div
                        className={clsx([
                          "p-2",
                          "rounded-md",
                          "bg-gray-100",
                          "md:bg-white",
                          "hover:bg-red-400",
                          "text-gray-600 hover:text-white",
                          "justify-center",
                          "items-center",
                          "flex",
                          "w-full",
                        ])}
                        key={key}
                      >
                        <Link
                          to={getLink({ link: url, type })}
                          target={target}
                          key={key}
                          className={clsx([
                            "flex",
                            "flex-col",
                            "gap-2",
                            "items-center",
                            "justify-center",
                            "group",
                            "relative",
                            "w-full",
                            "h-full",
                          ])}
                        >
                          <RenderIcon
                            name={icon}
                            mobileSize={32}
                            desktopSize={55}
                          />
                          <div
                            className={clsx([
                              "flex",
                              "flex-col",
                              "leading-none",
                              "w-full",
                            ])}
                          >
                            <p
                              className={clsx([
                                "text-xs",
                                "text-center",
                                "truncate",
                              ])}
                            >
                              {name}
                            </p>
                          </div>
                          <div
                            className={clsx([
                              "absolute",
                              "top-0",
                              "right-0",
                              "hidden",
                              "group-hover:flex",
                              "flex",
                              "gap-1",
                            ])}
                          >
                            <Button
                              onClick={(e) => {
                                e?.preventDefault?.();
                              }}
                              icon={<MdEdit size={20} />}
                              tooltip={t("shared.edit")}
                              classes={clsx(["p-1", "rounded-md", "border-0"])}
                              hide
                            />
                            <Button
                              onClick={(e) => {
                                e?.preventDefault?.();
                                setLinkToDelete(_id);
                                setShowDeleteLinkDialog(true);
                              }}
                              icon={<MdDelete size={20} />}
                              tooltip={t("shared.delete")}
                              classes={clsx(["p-1", "rounded-md", "border-0"])}
                            />
                          </div>
                        </Link>
                      </div>
                    )
                  ) || ""}
                  <div
                    className={clsx([
                      "p-2",
                      "rounded-md",
                      "hover:bg-red-400",
                      "text-gray-400 hover:text-white",
                      "flex",
                      "flex-col",
                      "items-center",
                      "justify-center",
                      "cursor-pointer",
                      "capitalize",
                      "group",
                    ])}
                    onClick={() => {
                      setShowCreateNewLink(true);
                    }}
                  >
                    <MdLink size={54} />
                    <p
                      className={clsx([
                        "text-xs",
                        "text-center",
                        "font-medium",
                        "text-gray-600",
                        "group-hover:text-white",
                      ])}
                    >
                      {t("shared.add_link")}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <SectionTitle title={t("custom_links.title")} />
                <EmptyComponentState
                  message={t("custom_links.empty")}
                  buttons={[
                    {
                      kind: "primary",
                      icon: <MdAdd size={32} />,
                      text: t("shared.add"),
                      onClick: () => {
                        setShowCreateNewLink(true);
                      },
                    },
                  ]}
                />
              </>
            )}
          </>
        )}

        {showDeleteLinkDialog && (
          <ConfirmDialog
            title={t("dialogs.links.delete_dialog_title")}
            description={t("dialogs.links.delete_dialog_question")}
            confirmKind="delete"
            isLoading={isDeletingLink}
            onClose={() => {
              setLinkToDelete("");
              setShowDeleteLinkDialog(false);
            }}
            onConfirm={() => {
              setIsDeletingLink(true);
              deleteLink({
                linkId: linkToDelete,
                onError: () => {
                  setLinkToDelete("");
                  setIsDeletingLink(false);
                },
                onResolved: () => {
                  refreshStoreLinks({});
                  setLinkToDelete("");
                  setIsDeletingLink(false);
                  setShowDeleteLinkDialog(false);
                  showNotification({
                    message: t("notifications.links.deleted"),
                    kind: "success",
                  });
                },
              });
            }}
            icon={<DELETE_ICON size={CONFIRM_DIALOG_ICON_SIZE} />}
          />
        )}
      </div>
    </>
  );
};

export default CustomLinks;
