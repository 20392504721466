import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import useAccountsStore from "../../store/accountsStore";
import clsx from "clsx";
import { getTotalByTransactionType } from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import {
  EXPENSE_TYPE,
  INCOME_TYPE,
  PAYMENT_METHODS,
} from "../../helpers/constants";
import AccountsSummaryTable from "../../components/InvoicesChart/AccountsSummaryTable";
import { getColorFromAccountSettings } from "../../helpers/accounts";
import transactionsStore from "../../store/transactionsStore";
import _ from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ITransactionsByPaymentMethod {
  transactions: Array<any>;
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  // aspectRatio
  pointRadius: 15,
  pointHoverRadius: 30,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  borderColor: "#444",
  borderWidth: 1,
  elements: {
    line: {
      tension: 0.5,
    },
  },
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
      align: "end" as const,
      labels: {
        padding: 10,
        useBorderRadius: true,
        borderRadius: 3,
        font: {
          size: 16,
        },
      },
    },
  },
};

const TransactionsByPaymentMethod: React.FC<ITransactionsByPaymentMethod> = ({
  transactions,
}) => {
  const availableMethods = PAYMENT_METHODS();
  return (
    <div className={clsx("flex", "flex-col-reverse", "gap-4")}>
      <div>
        <Bar
          className="w-full"
          options={options}
          data={{
            datasets: [
              {
                data: availableMethods?.map(() => _.random(400)),
              },
            ],
            labels: availableMethods?.map(({ name }) => name),
          }}
        />
      </div>
    </div>
  );
};

export default TransactionsByPaymentMethod;
