import transactionsStore from "../store/transactionsStore";

export default function useCurrencyFilter() {
  const { filters, setFilter } = transactionsStore();

  const {
    currency = {
      selectedCurrency: "",
    },
  } = filters || {};

  return {
    currencyFilter: currency.selectedCurrency,
    setCurrencyFilter: (values: any) => {
      setFilter({
        name: "currency",
        values,
      });
    },
  };
}
