import { useQuery, useQueryClient } from "@tanstack/react-query";
import { APP_USER_ID_KEY } from "../helpers/constants";
import { getUser } from "../api/users";

export default function useCurrentUser() {
  const queryClient = useQueryClient();
  const currentUserId = localStorage?.getItem?.(APP_USER_ID_KEY) || "";

  const user = useQuery({
    queryFn: async () => await getUser({ _id: currentUserId }),
    queryKey: ["current-user"],
  });

  const resetCache = async () => {
    return await queryClient.refetchQueries({ queryKey: ["current-user"] });
  };

  return {
    id: currentUserId,
    userInfo: user?.data?.data?.data,
    enabledPaymentMethods:
      user?.data?.data?.data?.settings?.payment_methods || [],
    resetCache,
  };
}
