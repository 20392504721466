import { MdArrowRight } from "react-icons/md";
import Button from "../Button/Button";
import clsx from "clsx";
import { ICON_SIZES } from "../../helpers/constants";

const SliderArrowRight: React.FC = (props: any) => {
  const { onClick } = props;

  return (
    <div className={clsx("h-full", "flex", "items-center")}>
      <Button
        icon={<MdArrowRight size={ICON_SIZES.SM} />}
        onClick={onClick}
        classes={clsx(["p-0", "border-1"])}
      />
    </div>
  );
};

export default SliderArrowRight;
