import { create } from "zustand";
import apiClient from "../helpers/api-client";
import {
  API_ENDPOINT_ACCEPT_INVITE,
  API_ENDPOINT_DELETE_INVITE,
  API_ENDPOINT_GET_INVITES,
  API_ENDPOINT_SEARCH_NETWORK,
  API_ENDPOINT_SEND_INVITE,
  INVITE_STATUS_SENT,
} from "../helpers/constants";
import moment from "moment";

const networkStore = (set: any, get: any) => ({
  searchNetwork: async (search: string) => {
    return await apiClient
      .get(API_ENDPOINT_SEARCH_NETWORK, {
        params: new URLSearchParams({ search }),
      })
      .then((res: any) => {
        return res?.data || [];
      })
      .catch((e: any) => {
        return e;
      });
  },

  sendInvite: async ({ to }: { to: string }) => {
    return await apiClient
      .post(API_ENDPOINT_SEND_INVITE, {
        to,
        status: INVITE_STATUS_SENT,
        date: moment().format("YYYY-MM-DD hh:mm:ss tt"),
      })
      .then((res: any) => {
        return res?.data?.success || false;
      })
      .catch((e: any) => {
        return e;
      });
  },

  deleteInvite: async ({ inviteIds }: { inviteIds: Array<any> }) => {
    return await apiClient
      .delete(`${API_ENDPOINT_DELETE_INVITE}/${JSON.stringify(inviteIds)}`, {})
      .then((res: any) => {
        return res?.data?.success || false;
      })
      .catch((e: any) => {
        return e;
      });
  },

  acceptInvite: async ({ inviteIds }: { inviteIds: Array<any> }) => {
    return await apiClient
      .put(`${API_ENDPOINT_ACCEPT_INVITE}`, {
        inviteIds,
        status: "ACCEPTED",
      })
      .then((res: any) => {
        return res?.data?.success || false;
      })
      .catch((e: any) => {
        return e;
      });
  },

  setTableResultsPerPage: (perPage: number) => {
    set({ tableResultsPerPage: perPage });
  },
});

export default create(networkStore);
