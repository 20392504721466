import {
  MdBarChart,
  MdCompareArrows,
  MdLogout,
  MdSettings,
} from "react-icons/md";
import BlocksNavigation from "../Navigation/BlocksNavigation";
import userPreferences from "../../store/userPreferences";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import modalsStore from "../../store/modalsStore";

const DesktopNavigation: React.FC = () => {
  const { navIsCollapsed } = userPreferences();
  const { setShowLogoutDialog, showLogoutDialog } = modalsStore();

  const { t } = useTranslation();
  const iconSize = navIsCollapsed ? 48 : 32;

  return (
    <BlocksNavigation
      links={[
        {
          link: "/reports",
          text: navIsCollapsed ? null : t("menu.reports"),
          icon: <MdBarChart size={iconSize} />,
          tooltip: navIsCollapsed ? t("menu.reports") : "",
        },
        {
          link: "/transactions",
          text: navIsCollapsed ? null : t("menu.transactions"),
          icon: <MdCompareArrows size={iconSize} />,
          tooltip: navIsCollapsed ? t("menu.transactions") : "",
        },

        {
          link: "/settings",
          text: navIsCollapsed ? null : t("menu.settings"),
          icon: <MdSettings size={iconSize} />,
          tooltip: navIsCollapsed ? t("menu.settings") : "",
        },
        {
          onClick: () => setShowLogoutDialog(true),
          text: navIsCollapsed ? null : t("menu.signout"),
          icon: (
            <MdLogout
              size={navIsCollapsed ? 24 : iconSize}
              className={clsx([
                {
                  "text-red-500": showLogoutDialog,
                },
              ])}
            />
          ),
          tooltip: navIsCollapsed ? t("menu.signout") : "",
          active: showLogoutDialog,
        },
      ]}
    />
  );
};

export default DesktopNavigation;
