import clsx from "clsx";

interface ICellWithBackground {
  value: string;
  color: string;
  background: string;
  blur?: boolean;
}

const CellWithBackground: React.FC<ICellWithBackground> = ({
  value,
  color,
  background,
  blur = false,
}) => {
  return (
    <div
      className={clsx([
        "inline-flex",
        "whitespace-nowrap",
        "rounded-full md:rounded-full",
        "py-1",
        "px-2",
        "shrink-0",
        {
          "blur-sm hover:blur-none": blur,
        },
      ])}
      style={{ color, background }}
    >
      {value}
    </div>
  );
};

export default CellWithBackground;
