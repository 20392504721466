import { MdAccountBalance, MdCheck, MdClose, MdDelete } from "react-icons/md";
import Modal from "../../Modal/Modal";
import TextInput from "../../TextInput/TextInput";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Select from "../../Select/Select";
import {
  CURRENCY_SELECT_OPTIONS,
  showErrorNotification,
  showNotification,
} from "../../../helpers/utils";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import clsx from "clsx";
import BankCard from "../../BankCard/BankCard";
import useAccountsStore from "../../../store/accountsStore";

import {
  ACCOUNT_ICON,
  CONFIRM_DIALOG_ICON_SIZE,
} from "../../../constants/icons";
import { useTranslation } from "react-i18next";
import ColorPicker from "../../ColorPicker/ColorPicker";
import { getColorFromAccountSettings } from "../../../helpers/accounts";
import toast from "react-hot-toast";
import ButtonGroup from "../../ButtonGroup/ButtonGroup";
import useModalsStore from "../../../store/modalsStore";
import { ICON_SIZES } from "../../../helpers/constants";

interface INewAccountModal {
  onClose?: () => void;
  onCreated?: (e: any) => void;
}

const NewAccountModal: React.FC<INewAccountModal> = ({
  onClose = () => {},
  onCreated = () => {},
}) => {
  const FORM_ID = "create-account-modal";

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const { showEditAccount } = useModalsStore();

  const [name, setName] = useState<string>("");
  const [bank, setBank] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [textColor, setTextColor] = useState<any>();
  const [bgColor, setBgColor] = useState<any>("");
  const [settings, setSettings] = useState<any>({});

  const { t } = useTranslation();

  const [showSaveConfirmDialog, setShowSaveConfirmDialog] =
    useState<boolean>(false);
  const [showUpdateConfirmDialog, setShowUpdateConfirmDialog] =
    useState<boolean>(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] =
    useState<boolean>(false);

  const {
    registerNewAccount,
    deleteAccount,
    updateExistingAccount,
    getAccountById,
  } = useAccountsStore();

  const isEditMode = showEditAccount !== "";

  useEffect(() => {
    if (isEditMode) {
      const { name, bank, number, currency, settings } =
        getAccountById(showEditAccount);
      const { bg, color } = settings;

      setName(name);
      setSettings(settings);
      setBank(bank);
      setNumber(number);
      setCurrency(currency);
      setTextColor(color);
      setBgColor(bg);
    }
  }, [isEditMode]);

  const isPerformingAction = [isUpdating, isDeleting, isRegistering].includes(
    true
  );

  return (
    <>
      <Modal width="md:min-w-[500px]" onEscape={() => onClose()}>
        <Modal.Header
          title={
            isEditMode
              ? t("accounts_page.edit_title")
              : t("accounts_page.add_title")
          }
          onClose={() => onClose()}
          icon={<MdAccountBalance size={CONFIRM_DIALOG_ICON_SIZE} />}
        />
        <Modal.Body>
          <div className={clsx(["flex", "flex-col", "sm:flex-row", "gap-6"])}>
            <form
              className="flex flex-col gap-4 md:min-w-[350px]"
              id={FORM_ID}
              onSubmit={(e) => {
                e?.preventDefault?.();

                if (isEditMode) {
                  setShowUpdateConfirmDialog(true);
                } else {
                  setShowSaveConfirmDialog(true);
                }
              }}
            >
              <div className="flex flex-col gap-4">
                <TextInput
                  placeholder={t("accounts_page.short_name_placeholder")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  label={t("accounts_page.short_name_label")}
                  type="text"
                  form={FORM_ID}
                  required
                  disabled={isPerformingAction}
                  autoComplete="name nickname"
                />
                <TextInput
                  placeholder={t("accounts_page.account_number_placeholder")}
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  label={t("accounts_page.account_number_label")}
                  type="text"
                  form={FORM_ID}
                  disabled={isPerformingAction}
                />
                <TextInput
                  placeholder={t("accounts_page.bank_placeholder")}
                  value={bank}
                  onChange={(e) => setBank(e.target.value)}
                  label={t("accounts_page.bank_label")}
                  type="text"
                  form={FORM_ID}
                  disabled={isPerformingAction}
                />
                <Select
                  required
                  label={t("accounts_page.currency_label")}
                  placeholder={t("accounts_page.currency_placeholder")}
                  options={CURRENCY_SELECT_OPTIONS}
                  value={currency}
                  form={FORM_ID}
                  onChange={(e: any) => setCurrency(e?.target?.value)}
                  disabled={isPerformingAction}
                  autoComplete="transaction-currency"
                />
              </div>
            </form>
            <div
              className={clsx([
                "flex",
                "flex-col",
                "gap-2",
                "md:max-h-[470px]",
                "overflow-auto",
                "md:min-w-[300px]",
              ])}
            >
              <label
                className={clsx(["text-md", "text-gray-700", "font-medium"])}
              >
                {t("accounts_page.appearance")}
              </label>
              <BankCard
                account=""
                number={number}
                owner={name}
                bank={bank}
                name={name}
                variant="custom"
                customStyles={getColorFromAccountSettings({
                  bg: bgColor,
                  color: textColor,
                })}
                currency={currency}
              />
              <ColorPicker
                onChange={(color: any) => {
                  setTextColor(color.rgb);
                }}
                textColor={bgColor}
                color={textColor}
                label={t("accounts_page.text_color_label")}
              />

              <ColorPicker
                textColor={textColor}
                onChange={(color: any) => {
                  setBgColor(color.rgb);
                }}
                color={bgColor}
                label={t("accounts_page.bg_color_label")}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          leftContent={
            <ButtonGroup
              align="left"
              buttons={[
                {
                  type: "button",
                  icon: <MdDelete size={32} />,
                  onClick: () => {
                    setShowDeleteConfirmDialog(true);
                  },
                  kind: "delete",
                  tooltip: t("shared.delete"),
                  disabled: isDeleting,
                  isLoading: isDeleting,
                  hide: !isEditMode,
                },
              ]}
            />
          }
          actions={[
            {
              text: t("shared.save"),
              onClick: () => {},
              kind: "save",
              icon: <MdCheck size={ICON_SIZES.MD} />,
              type: "submit",
              form: FORM_ID,
              isLoading: isRegistering,
              hide: isEditMode,
              disabled: isRegistering,
            },
            {
              text: t("shared.update"),
              onClick: () => {},
              kind: "save",
              icon: <MdCheck size={ICON_SIZES.MD} />,
              type: "submit",
              form: FORM_ID,
              isLoading: isUpdating,
              hide: !isEditMode,
              disabled: isUpdating,
            },
            {
              text: t("shared.close"),
              onClick: () => onClose(),
              kind: "normal",
              icon: <MdClose size={ICON_SIZES.MD} />,
              disabled: isPerformingAction,
            },
          ]}
        />
      </Modal>
      {showSaveConfirmDialog && (
        <ConfirmDialog
          description={t("dialogs.accounts.save_dialog_question")}
          title={t("dialogs.accounts.save_dialog_title")}
          onClose={() => {
            if (!isRegistering) {
              setShowSaveConfirmDialog(false);
            }
          }}
          onConfirm={async () => {
            setIsRegistering(true);

            const response = await registerNewAccount({
              account: uuidv4(),
              name,
              bank,
              number,
              currency,
              settings: {
                bg: bgColor,
                color: textColor,
              },
            });

            if (response.success) {
              setShowSaveConfirmDialog(false);
              onClose();
              showNotification({
                message: "Cuenta agregada correctamente",
                kind: "success",
              });
            } else {
              showNotification({
                message: "Error agregando la nueva cuenta",
                kind: "error",
              });
            }

            setIsRegistering(false);
          }}
          confirmKind="save"
          isLoading={isRegistering}
          icon={<ACCOUNT_ICON size={CONFIRM_DIALOG_ICON_SIZE} />}
        />
      )}

      {showUpdateConfirmDialog && (
        <ConfirmDialog
          description={t("dialogs.accounts.update_dialog_question")}
          title={t("dialogs.accounts.update_dialog_title")}
          onClose={() => {
            if (!isUpdating) {
              setShowUpdateConfirmDialog(false);
            }
          }}
          onConfirm={async () => {
            setIsUpdating(true);

            const res = await updateExistingAccount({
              account: showEditAccount,
              name,
              bank,
              number,
              currency,
              settings: {
                ...settings,
                bg: bgColor,
                color: textColor,
              },
            });

            if (res.success) {
              setShowUpdateConfirmDialog(false);
              onClose();
              showNotification({
                message: "Cuenta actualizada correctamente",
                kind: "success",
              });
            } else {
              showNotification({
                message: "Error actualizando la cuenta",
                kind: "error",
              });
            }

            setIsUpdating(false);
          }}
          confirmKind="save"
          isLoading={isUpdating}
          icon={<ACCOUNT_ICON size={CONFIRM_DIALOG_ICON_SIZE} />}
        />
      )}

      {showDeleteConfirmDialog && (
        <ConfirmDialog
          description={t("dialogs.accounts.delete_dialog_question")}
          title={t("dialogs.accounts.delete_dialog_title")}
          onClose={() => {
            if (!isDeleting) {
              setShowDeleteConfirmDialog(false);
            }
          }}
          onConfirm={async () => {
            setIsDeleting(true);

            const res = await deleteAccount({ account: showEditAccount });

            if (res.success) {
              setShowDeleteConfirmDialog(false);
              onClose();
              showNotification({
                message: "Cuenta eliminada correctamente",
                kind: "success",
              });
            } else {
              showNotification({
                message: "Error eliminando la cuenta",
                kind: "error",
              });
            }

            setIsDeleting(false);
          }}
          confirmKind="delete"
          isLoading={isDeleting}
          icon={<ACCOUNT_ICON size={CONFIRM_DIALOG_ICON_SIZE} />}
        />
      )}
    </>
  );
};

export default NewAccountModal;
