import { MdHelpCenter, MdPlusOne } from "react-icons/md";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface IEmptyComponentState {
  message: string;
  buttons?: Array<any>;
  borderColor?: string;
}

const EmptyComponentState: React.FC<IEmptyComponentState> = ({
  message,
  buttons = [],
  borderColor = "border-gray-400",
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx([
        "flex",
        "flex-col",
        "items-center",
        "justify-center",
        "border",
        borderColor,
        "border-dashed",
        "bg-white",
        "gap-4",
        "md:gap-6",
        "p-4",
        "md:p-8",
        "text-gray-500",
        "rounded-lg",
        "hover:border-red-400",
        "hover:text-red-400",
        "transition-colors",
        "w-full",
      ])}
    >
      <p className={clsx(["lg:text-xl", "text-sm", "w-full"])}>{message}</p>
      <div className={clsx(["flex", "justify-center", "w-full"])}>
        <ButtonGroup
          gap={1}
          buttons={[
            ...buttons,
            // {
            //   icon: <MdHelpCenter size={32} />,
            //   tooltip: t("shared.help"),
            //   onClick: () => {},
            // },
          ]}
        />
      </div>
    </div>
  );
};

export default EmptyComponentState;
