import { create } from "zustand";
import {
  PAGINATION_MIN,
  PAYMENT_METHODS,
  TRANSACTIONS_TABLE_VIEW,
} from "../helpers/constants";

const userPreferencesStore = (set: any, get: any) => ({
  rightPanelIsVisible: true,
  navIsCollapsed: false,
  transactionsFilterIsOpen: false,
  transactionsActiveTab: 0,
  reportsActiveTab: 0,
  transactionsViewStyle: TRANSACTIONS_TABLE_VIEW,
  lastUsedPaymentMethod: PAYMENT_METHODS()[5].id,
  lastUsedAccount: "",
  reportsSelectedAccount: "",
  transactionsSelectedAccount: "",
  tableResultsPerPage: PAGINATION_MIN,
  blurAmounts: false,

  setBlurAmounts: (blur: boolean) => {
    set({ blurAmounts: blur });
  },

  setRightPanelIsVisible: (isVisible: boolean) => {
    set({ rightPanelIsVisible: isVisible });
  },

  setNavIsCollapsed: (isCollapsed: boolean) => {
    set({ navIsCollapsed: isCollapsed });
  },

  setTransactionsFilterIsOpen: (isOpen: boolean) => {
    set({ transactionsFilterIsOpen: isOpen });
  },

  setTransactionsActiveTab: (tab: number) => {
    set({ transactionsActiveTab: tab });
  },

  setReportsActiveTab: (tab: number) => {
    set({ reportsActiveTab: tab });
  },

  setTransactionsViewStyle: (viewStyle: number) => {
    set({ transactionsViewStyle: viewStyle });
  },

  setLastUsedPaymentMethod: (methodId: string) => {
    set({ lastUsedPaymentMethod: methodId });
  },

  setLastUsedAccount: (accountId: string) => {
    set({ lastUsedAccount: accountId });
  },

  setReportsSelectedAccount: (accountId: string) => {
    set({ reportsSelectedAccount: accountId });
  },

  setTransactionsSelectedAccount: (accountId: string) => {
    set({ transactionsSelectedAccount: accountId });
  },

  setTableResultsPerPage: (perPage: number) => {
    set({ tableResultsPerPage: perPage });
  },
});

export default create(userPreferencesStore);
