import clsx from "clsx";
import { MdAdd, MdRefresh, MdShare } from "react-icons/md";
import LoadingWithText from "../LoadingWithText/LoadingWithText";
import BankCard from "../BankCard/BankCard";
import EmptyComponentState from "../EmptyComponentState/EmptyComponentState";
import SectionTitle from "../SectionTitle/SectionTitle";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import useAccountsStore from "../../store/accountsStore";
import SliderArrowRight from "../Slider/SliderArrowRight";
import SliderArrowLeft from "../Slider/SliderArrowLeft";
import _ from "lodash";
import { rgbaObjectToString } from "../../helpers/utils";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { ICON_SIZES } from "../../helpers/constants";

interface IAccountsSlider {
  onCardClick?: (data: any) => void;
  showTitle?: boolean;
  layout?: "vertical" | "horizontal";
  mobileSettings?: any;
  desktopSettings?: any;
  showActions?: boolean;
  onSlideChange?: (data: any) => void;
  activeAccount?: string;
}

const AccountsSlider: React.FC<IAccountsSlider> = ({
  onCardClick = () => {},
  showTitle = true,
  layout = "vertical",
  mobileSettings = {},
  desktopSettings = {},
  showActions = true,
  onSlideChange = () => {},
  activeAccount = null,
}) => {
  const {
    allAccounts,
    isLoading,
    error: accountsLoadingError,
  } = useAccountsStore();
  const navigate = useNavigate();

  let initialSlide = 0;
  if (activeAccount) {
    initialSlide = _.findIndex(allAccounts as any, { account: activeAccount });
  }

  const settings: any = {
    arrows: false,
    nextArrow: <SliderArrowRight />,
    prevArrow: <SliderArrowLeft />,
    beforeChange: (_prev: number, next: number) => {
      onSlideChange(allAccounts[next]);
    },
    initialSlide,
    responsive: [
      ...(allAccounts?.length === 1
        ? [
            {
              breakpoint: 100000,
              settings: "unslick" as "unslick",
            },
          ]
        : [
            {
              breakpoint: 100000,
              settings:
                layout === "vertical"
                  ? ("unslick" as "unslick")
                  : {
                      infinite: true,
                      speed: 500,
                      dots: true,
                      slidesToShow: 3,
                      slidesToScroll: 1,
                      ...desktopSettings,
                    },
            },
            {
              breakpoint: 768,
              settings: mobileSettings?.unslick
                ? ("unslick" as "unslick")
                : {
                    infinite: true,
                    speed: 500,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    ...mobileSettings,
                  },
            },
          ]),
    ],
  };

  const sliderRef: any = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    if (sliderRef?.current) {
      sliderRef?.current?.slickGoTo?.(
        _.findIndex(allAccounts as any, { account: activeAccount })
      );
    }
  }, [activeAccount]);

  return (
    <>
      <div className="flex flex-col gap-2 w-full">
        {isLoading ? (
          <div
            className={clsx([
              "flex",
              "w-full",
              "justify-center",
              "min-h-[124px]",
              "bg-white",
              "rounded-md",
              "border",
            ])}
          >
            <LoadingWithText
              text={t("shared.loading_accounts", { count: 1111 })}
            />
          </div>
        ) : (
          <>
            {accountsLoadingError ? (
              <>
                {showTitle && (
                  <SectionTitle title={t("transactions_page.accounts_title")} />
                )}
                <EmptyComponentState
                  message={t("empty_states.error_loading_accounts")}
                  buttons={[
                    {
                      kind: "delete",
                      icon: <MdRefresh size={ICON_SIZES.SM} />,
                      text: t("shared.retry"),
                      onClick: () => {
                        window?.location?.reload?.();
                      },
                      isLoading: false,
                      disabled: false,
                    },
                  ]}
                />
              </>
            ) : (
              <>
                {allAccounts?.length ? (
                  <>
                    {showTitle && (
                      <SectionTitle
                        title={t("transactions_page.accounts_title")}
                      />
                    )}
                    <Slider
                      {...settings}
                      className={clsx([
                        {
                          "gap-2": true,
                          flex: true,
                          "flex-col": true,
                        },
                      ])}
                      ref={sliderRef}
                    >
                      {allAccounts.map?.(
                        (
                          {
                            name,
                            bank,
                            account,
                            owner,
                            number,
                            currency,
                            settings,
                          }: {
                            name: any;
                            bank: any;
                            account: any;
                            owner: any;
                            number: any;
                            currency: any;
                            settings: any;
                          },
                          index
                        ) => (
                          <div
                            // onDragOver={(e) => {
                            //   e?.preventDefault?.();
                            // }}
                            // onDrop={async (e) => {
                            //   const data = e?.dataTransfer.getData("transaction");
                            //   if (data) {
                            //     const parsedData = JSON.parse(data);

                            //     if (parsedData.contentType === "transaction") {
                            //       const { id, account: transactionAccount } =
                            //         parsedData;

                            //       if (transactionAccount !== account) {
                            //         try {
                            //           const result = await moveTransactions({
                            //             to: account,
                            //             transactions: [id],
                            //           });

                            //           if (result.success) {
                            //             toast.success(
                            //               "Transacción movida correctamente"
                            //             );
                            //             // onUpdateNeeded();
                            //           } else {
                            //             toast.error(
                            //               "Error moviendo la transacción"
                            //             );
                            //           }
                            //         } catch (e) {
                            //           toast.error("Error moviendo la transacción");
                            //         }
                            //       } else {
                            //         toast.info(
                            //           "La transacción ya está en esta cuenta"
                            //         );
                            //       }
                            //     }
                            //   }
                            // }}
                            // onClick={() => {
                            //   if (onCardClick) {
                            //     onCardClick(account);
                            //   } else {
                            //     if (theAccount === account) {
                            //       setFilter({
                            //         name: "main",
                            //         values: {
                            //           account: "",
                            //         },
                            //       });
                            //     } else {
                            //       setFilter({
                            //         name: "main",
                            //         values: {
                            //           account: account,
                            //         },
                            //       });
                            //     }
                            //   }
                            // }}
                            className={clsx(["cursor-pointer"])}
                            key={index}
                            onClick={() => {
                              onCardClick(account);
                            }}
                          >
                            <BankCard
                              name={name}
                              owner={owner}
                              bank={bank}
                              number={number}
                              customStyles={{
                                background: rgbaObjectToString({
                                  ...(settings?.bg || { r: 0, g: 0, b: 0 }),
                                  a: 1,
                                }),
                                color: rgbaObjectToString(
                                  settings?.color || { r: 0, g: 0, b: 0 }
                                ),
                              }}
                              className={clsx(["hover:opacity-100"])}
                              actions={
                                showActions
                                  ? [
                                      {
                                        hide: [!!number, !!bank].includes(
                                          false
                                        ),
                                        icon: <MdShare size={ICON_SIZES.SM} />,
                                        onClick: async (e: any) => {
                                          e?.preventDefault();
                                          await navigator
                                            .share({
                                              title: name,
                                              text: [
                                                `Banco: ${
                                                  bank || "Desconocido"
                                                }`,
                                                `Número de cuenta: ${
                                                  number || "Desconocido"
                                                }`,
                                                `Moneda: ${
                                                  currency || "Desconocida"
                                                }`,
                                              ].join("\n"),
                                            })
                                            .then((a) => {})
                                            .catch((e) => {});
                                          // if (navigator.canShare({})) {
                                          //   navigator.share({
                                          //     title: account.name,
                                          //     text: ["Hola", "Mundo"].join("\n"),
                                          //   });
                                          // } else {
                                          //   navigator.clipboard
                                          //     .writeText(number)
                                          //     .then(
                                          //       () => {
                                          //         toast.info(
                                          //           "Número de cuenta copiado"
                                          //         );
                                          //       },
                                          //       () => {
                                          //         toast.error(
                                          //           "Error copiando el número de cuenta"
                                          //         );
                                          //       }
                                          //     );
                                          // }
                                        },
                                        tooltip: t("shared.share"),
                                        classes: clsx([
                                          "!bg-transparent",
                                          "!border-0",
                                          "!text-current",
                                        ]),
                                        disabled: !number,
                                      },
                                    ]
                                  : []
                              }
                              account={account}
                              variant="custom"
                              currency={currency}
                            />
                          </div>
                        )
                      )}
                    </Slider>
                  </>
                ) : (
                  <>
                    {showTitle && (
                      <SectionTitle
                        title={t("transactions_page.accounts_title")}
                      />
                    )}
                    <EmptyComponentState
                      message={t("empty_states.add_account")}
                      buttons={[
                        {
                          kind: "save",
                          icon: <MdAdd size={ICON_SIZES.MD} />,
                          text: t("shared.add"),
                          onClick: () => navigate("/settings/accounts/new"),
                          isLoading: false,
                          disabled: false,
                        },
                      ]}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AccountsSlider;
