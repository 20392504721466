import React, { useState } from "react";
import { MdFullscreen, MdMenuOpen } from "react-icons/md";
import SidebarLogo from "../SidebarLogo/SidebarLogo";
import userPreferences from "../../store/userPreferences";
import clsx from "clsx";
import { hasFullscreenSupport, toggleFullscreen } from "../../helpers/utils";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import ExpenserMobileTopLogo from "../../Logo/ExpenserMobileTopLogo";
import { useTranslation } from "react-i18next";
import DesktopNavigation from "../Navigations/DesktopNavigation";

const Sidebar: React.FC = () => {
  const { navIsCollapsed, setNavIsCollapsed } = userPreferences();
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const { t } = useTranslation();

  return (
    <>
      <ExpenserMobileTopLogo />
      <div
        className={clsx([
          "hidden",
          "md:flex",
          "flex-col",
          "md:w-full",
          "md:h-full",
          "md:justify-between",
          "bg-gray-800",
          "text-white",
          "text-md",
          "font-light",
          "gap-14",
          "py-2 md:py-4",
          "px-4",
          "justify-start",
          "md:border-r-4",
          "z-10",
          "shadow-md shadow-gray-100/80 md:shadow-none",
          {
            "items-center": navIsCollapsed,
            "md:px-5": !navIsCollapsed,
          },
          "z-50 fixed top-0 md:relative",
          "rounded-full md:rounded-none mt-4 md:mt-0",
          "left-4 right-4 md:left-auto md:right-auto",
        ])}
      >
        <div
          className={clsx([
            "flex",
            "flex-row",
            "md:flex-col",
            "gap-4",
            "md:gap-14",
            "w-full",
            "justify-between",
            "md:justify-normal",
            "md:overflow-hidden",
            "md:h-full",
          ])}
        >
          <div className={clsx(["hidden", "md:flex", "justify-center"])}>
            <SidebarLogo rotate={navIsCollapsed} />
          </div>
          <div
            className={clsx([
              "flex",
              "max-h-full",
              "flex-col",
              "flex-grow",
              "md:overflow-auto",
              "h-full",
            ])}
          >
            <div
              className={clsx([
                "hidden",
                "md:flex",
                "flex-col",
                "h-full",
                "gap-14",
                "justify-between",
              ])}
            >
              <DesktopNavigation />
              <div className={clsx(["sticky", "bottom-28"])}>
                <ButtonGroup
                  align={navIsCollapsed ? "center" : "left"}
                  buttons={[
                    {
                      icon: <MdFullscreen size={32} />,
                      tooltip: t("menu.fullscreen"),
                      onClick: () => toggleFullscreen(),
                      hide: !hasFullscreenSupport() || navIsCollapsed,
                      active: isFullscreen,
                      classes: clsx([
                        "border-0",
                        "p-1",
                        "rounded-md",
                        "bg-[#212f3c] dark:hover:bg-gray-900",
                      ]),
                    },
                    {
                      onClick: () => setNavIsCollapsed(!navIsCollapsed),
                      icon: navIsCollapsed ? (
                        <MdMenuOpen size={32} />
                      ) : (
                        <MdMenuOpen
                          size={32}
                          className="text-primary dark:text-red-500"
                        />
                      ),
                      classes: clsx([
                        "border-0",
                        "p-1",
                        "rounded-md",
                        "bg-gray-800 hover:bg-gray-900",
                      ]),
                      tooltip: t("menu.menu"),
                    },
                  ]}
                  gap={1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
