import clsx from "clsx";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

interface ISelectionTotals {
  subTotal: string | number;
  taxes: string | number;
  discounts: string | number;
  selectionCount: number;
}

const SelectionTotals: React.FC<ISelectionTotals> = ({
  subTotal,
  taxes,
  discounts,
  selectionCount,
}) => {
  const total = Number(subTotal) - Number(discounts) + Number(taxes);
  const { t } = useTranslation();

  return (
    <div
      className={clsx([
        "fixed",
        "md:right-[366px]",
        "left-4",
        "md:left-auto",
        "bottom-20",
        "md:bottom-2",
        "bg-gray-600/90 md:bg-gray-800",
        "py-1 px-2",
        "md:py-2",
        "md:px-4",
        "text-white",
        "text-4xl",
        "rounded-md md:rounded-md",
        "z-20",
      ])}
    >
      <div>
        <p
          className={clsx([
            "text-xs",
            "md:text-sm",
            "uppercase",
            "font-medium",
            "whitespace-nowrap",
          ])}
        >
          {t("shared.selection_totals_text", { count: selectionCount })}
        </p>
        <div
          className={clsx([
            "flex",
            "flex-row",
            "items-center",
            "gap-3",
            "text-white md:text-red-600",
          ])}
        >
          {
            <CountUp
              end={total}
              decimalPlaces={2}
              duration={1}
              redraw={false}
              preserveValue
              enableScrollSpy
            />

            // formatNumber(total)
          }
        </div>
        {/* <div className={clsx(["text-lg"])}>
          <Button onClick={() => {}} icon={<MdImageSearch size={24} />} />
        </div> */}
      </div>
      {/* <div>
        <p className="text-xs uppercase font-medium">Impuestos</p>
        <div className="flex flex-row items-center gap-3"> */}
      {/* <MdInfoOutline size={32} /> */}
      {/* <div>{formatNumber(taxes)}</div>
        </div>
      </div>
      <div>
        <p className="text-xs uppercase font-medium">Descuentos</p>
        <div className="flex flex-row items-center gap-3"> */}
      {/* <MdInfoOutline size={32} /> */}
      {/* <div>{formatNumber(discounts)}</div>
        </div>
      </div> */}
    </div>
  );
};

export default SelectionTotals;
