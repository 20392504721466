import clsx from "clsx";
import SidebarLogo from "../../components/SidebarLogo/SidebarLogo";
import LanguageSwitcher from "../../components/LanguageSwitcher/LanguageSwitcher";
import ExpenserHorizontalLogo from "../../Logo/ExpenserLogoHorizontal";
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import { useTranslation } from "react-i18next";
import { ReactElement } from "react";

interface ILoginFlowLayout {
  children: ReactElement;
  title?: any;
  description?: any;
}

const LoginFlowLayout: React.FC<ILoginFlowLayout> = ({
  children,
  title,
  description,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={clsx([
          "flex",
          "w-full",
          "flex-row",
          "min-h-[90vh]",
          "md:min-h-[100vh]",
        ])}
      >
        <div
          className={clsx([
            "md:w-1/2",
            "hidden",
            "md:flex",
            "items-center",
            "flex-col",
            "justify-center",
            "md:bg-gray-700",
            "gap-5",
            "md:gap-0",
          ])}
        >
          <div className="text-white">
            <SidebarLogo />
          </div>
          <div
            className={clsx([
              "text-gray-700 md:text-white",
              "italic",
              "hidden",
              "md:block",
              "[writing-mode:vertical-lr]",
              "md:[writing-mode:unset]",
            ])}
          >
            The most flexible
            <strong className="mx-1">Income and Expenses Tracking</strong>
            tool.
          </div>
        </div>
        <div
          className={clsx([
            "w-full md:w-1/2",
            "flex",
            "justify-start",
            "md:p-0",
            "flex-col",
          ])}
        >
          <div
            className={clsx([
              "flex",
              "flex-col",
              "w-full",
              "bg-gray-800",
              "p-4",
              "gap-10",
              "border-b-4",
              "border-gray-400",
            ])}
          >
            <div className={clsx(["flex"])}>
              <LanguageSwitcher />
            </div>
            <div
              className={clsx([
                "flex",
                "w-full",
                "justify-center",
                "md:hidden",
                "pb-10",
              ])}
            >
              <ExpenserHorizontalLogo color="text-white" />
            </div>
          </div>
          <div
            className={clsx([
              "flex",
              "flex-col",
              "gap-6",
              "w-full",
              "md:w-auto",
              "p-6",
            ])}
          >
            {[!!title || !!description].includes(true) === true && (
              <div
                className={clsx([
                  "flex",
                  "gap-2",
                  "flex-col",
                  "w-full md:max-w-[320px]",
                ])}
              >
                <h1 className={clsx(["text-xl", "md:text-3xl", "font-medium"])}>
                  {title}
                </h1>
                {description && (
                  <p className={clsx(["text-sm", "md:text-base"])}>
                    {description}
                  </p>
                )}
              </div>
            )}
            {children}
            <div className={clsx(["flex", "w-full", "flex-col", "gap-2"])}>
              <InfoBlock
                title={t("active_development.title")}
                message={t("active_development.description")}
              />
              <p className="text-xs">{t("active_development.note")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginFlowLayout;
