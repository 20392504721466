import TextInput from "../../components/TextInput/TextInput";
import { useEffect, useState } from "react";
import Select from "../../components/Select/Select";
import { v4 as uuidv4 } from "uuid";
import { QRCodeSVG } from "qrcode.react";

import clsx from "clsx";
import BankCard from "../../components/BankCard/BankCard";

import { useTranslation } from "react-i18next";
import ColorPicker from "../../components/ColorPicker/ColorPicker";
import {
  CURRENCY_SELECT_OPTIONS,
  getRandomRgbaColor,
  rgbaObjectToString,
  showNotification,
} from "../../helpers/utils";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import PageTitle from "../../components/PageTitle/PageTitle";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import {
  MdAdd,
  MdArrowBack,
  MdAttachMoney,
  MdClose,
  MdCloudDone,
  MdCompareArrows,
  MdDelete,
  MdOutlineShoppingCart,
  MdQrCode,
  MdQrCode2,
  MdShare,
} from "react-icons/md";
import {
  BUTTON_STYLES,
  ICON_SIZES,
  PAYMENT_METHODS,
} from "../../helpers/constants";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import accountsStore from "../../store/accountsStore";
import LoadingWithText from "../../components/LoadingWithText/LoadingWithText";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";
import AccountsSlider from "../../components/Accounts/AccountsSlider";
import Modal from "../../components/Modal/Modal";
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import useCurrentUser from "../../hooks/useCurrentUser";

interface INewAccountPage {
  onClose?: () => void;
  onCreated?: (e: any) => void;
  editMode?: boolean;
}

const NewAccountPage: React.FC<INewAccountPage> = ({
  onClose = () => {},
  onCreated = () => {},
  editMode = false,
}) => {
  const FORM_ID = "create-account-modal";

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const [isLoadingAccount, setIsLoadingAccount] = useState<boolean>(editMode);

  const [name, setName] = useState<string>("");
  const [bank, setBank] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [textColor, setTextColor] = useState<any>(getRandomRgbaColor(1));
  const [bgColor, setBgColor] = useState<any>(getRandomRgbaColor(1));
  const [settings, setSettings] = useState<any>({});
  const [showQrCode, setShowQrCode] = useState<boolean>(false);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<string>("");

  const { enabledPaymentMethods } = useCurrentUser();

  const paymentMethods: any = () =>
    PAYMENT_METHODS()
      ?.filter?.(({ id }) => enabledPaymentMethods.includes(id))
      ?.map((paymentMethod: any) => ({
        label: `${paymentMethod?.name}`,
        value: `${paymentMethod?.id}`,
      }));

  const { t } = useTranslation();

  const [showSaveConfirmDialog, setShowSaveConfirmDialog] =
    useState<boolean>(false);
  const [showUpdateConfirmDialog, setShowUpdateConfirmDialog] =
    useState<boolean>(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] =
    useState<boolean>(false);

  const {
    registerNewAccount,
    getAccount,
    updateExistingAccount,
    deleteAccount,
  } = accountsStore();

  const { accountId = "" } = useParams();

  useEffect(() => {
    const nada = async () => {
      return await getAccount({ account: accountId })
        .then((res: any) => {
          if (res?.success) {
            const { bank, currency, name, number, settings } =
              res?.data?.data || {};

            setBank(bank);
            setCurrency(currency);
            setName(name);
            setNumber(number);
            setSettings(settings);
            setTextColor(settings?.color);
            setBgColor(settings?.bg);
          } else {
            console.log("Error en esto", res);
          }
        })
        .catch((e: any) => {
          console.log("Error en esta parte", e);
        })
        .finally(() => {
          setIsLoadingAccount(false);
        });
    };

    if (editMode) {
      nada();
    }
  }, [accountId, editMode, getAccount]);

  const isPerformingAction = [isUpdating, isDeleting, isRegistering].includes(
    true
  );

  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <>
      <TwoSidebars
        customTopBackground={{
          bg: isLoadingAccount ? "" : "bg-gray-800",
        }}
        right={
          <>{<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}</>
        }
      >
        <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
          <PageTitle
            title={"Cuentas"}
            subtitle={(() => {
              if (isLoadingAccount) {
                return "Loading...";
              }

              if (editMode) {
                return "Editar cuenta";
              }

              return "Agregar una cuenta";
            })()}
            titleLink="/settings"
            isLoading={false}
            rightSide={
              <>
                <ButtonGroup
                  align="right"
                  gap={1}
                  buttons={[
                    {
                      icon: <MdAdd size={ICON_SIZES.SM} />,
                      tooltip: "Add new",
                      onClick: () => {
                        navigate("/settings/accounts/new");
                        setBank("");
                        setName("");
                        setNumber("");
                        setCurrency("");
                        setBgColor(getRandomRgbaColor(1));
                        setTextColor(getRandomRgbaColor(1));

                        // setShowCreateNewAccount(true);
                      },
                      hide: !isMobile && !editMode,
                      classes: isMobile ? BUTTON_STYLES.HEADING : "",
                    },
                    {
                      icon: <MdArrowBack size={ICON_SIZES.SM} />,
                      classes: BUTTON_STYLES.HEADING,

                      tooltip: "Atras",
                      onClick: () => {
                        navigate("/settings/accounts");
                      },
                      hide: !isMobile,
                    },
                    {
                      icon: <MdOutlineShoppingCart size={ICON_SIZES.SM} />,
                      tooltip: "Añadir gasto",
                      onClick: () => {
                        navigate("/transactions/expense");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdAttachMoney size={ICON_SIZES.SM} />,
                      tooltip: "Añadir ingreso",
                      hide: isMobile,
                      onClick: () => {
                        navigate("/transactions/income");
                      },
                    },
                    {
                      icon: <MdCompareArrows size={ICON_SIZES.SM} />,
                      tooltip: "Transacciones",
                      onClick: () => {
                        navigate("/transactions");
                      },
                      hide: isMobile,
                    },

                    // {
                    //   icon: <MdViewSidebar size={ICON_SIZES.SM} />,
                    //   tooltip: "Panel lateral",
                    //   kind: rightPanelIsVisible ? "primary" : "secondary",
                    //   hide: isMobile,
                    //   onClick: () => {
                    //     setRightPanelIsVisible(!rightPanelIsVisible);
                    //   },
                    // },
                  ]}
                />
              </>
            }
          />
          {isLoadingAccount ? (
            <div
              className={clsx([
                "flex",
                "justify-center",
                "w-full",
                "items-center",
                "flex-grow",
              ])}
            >
              <LoadingWithText text="Cargando..." />
            </div>
          ) : (
            <div className={clsx(["flex", "gap-4", "flex-col"])}>
              <>
                <div
                  className={clsx(["flex", "flex-col", "sm:flex-row", "gap-4"])}
                >
                  <div
                    className={clsx([
                      "flex",
                      "flex-col",
                      "gap-4",
                      "md:max-h-[470px]",
                      "overflow-auto",
                      "md:min-w-[300px]",
                    ])}
                  >
                    <div>
                      {/* <label
                        className={clsx([
                          "text-sm",
                          "text-gray-700",
                          "font-medium",
                        ])}
                      >
                        {t("accounts_page.appearance")}
                      </label> */}

                      <BankCard
                        account=""
                        number={number}
                        owner={name}
                        bank={bank}
                        name={name}
                        variant="custom"
                        customStyles={{
                          background: bgColor
                            ? rgbaObjectToString({ ...bgColor, a: 1 })
                            : {},
                          color: textColor
                            ? rgbaObjectToString({ ...textColor, a: 1 })
                            : {},
                        }}
                        currency={currency}
                        actions={[
                          // {
                          //   icon: <MdQrCode size={ICON_SIZES.SM} />,
                          //   onClick: () => setShowQrCode(true),
                          //   classes: clsx([
                          //     "!bg-transparent",
                          //     "!border-0",
                          //     "!text-current",
                          //   ]),
                          // },
                          {
                            hide: [!!number, !!bank].includes(false),
                            icon: <MdShare size={ICON_SIZES.SM} />,
                            onClick: async (e: any) => {
                              e?.preventDefault();
                              await navigator
                                .share({
                                  title: name,
                                  text: [
                                    `Banco: ${bank || "Desconocido"}`,
                                    `Número de cuenta: ${
                                      number || "Desconocido"
                                    }`,
                                    `Moneda: ${currency || "Desconocida"}`,
                                  ].join("\n"),
                                })
                                .then((a) => {})
                                .catch((e) => {});
                            },
                            tooltip: t("shared.share"),
                            classes: clsx([
                              "!bg-transparent",
                              "!border-0",
                              "!text-current",
                            ]),
                            disabled: !number,
                          },
                        ]}
                      />
                    </div>
                    {[!number, !bank].includes(true) && (
                      <InfoBlock
                        title="Compartir cuenta"
                        message="Para habilitar el botón de compartir debes completar los campos Número de cuenta y Banco."
                      />
                    )}
                    <div className={clsx(["grid", "grid-cols-2", "gap-2"])}>
                      <ColorPicker
                        onChange={(color: any) => {
                          setBgColor(color.rgb);
                        }}
                        textColor={textColor}
                        color={bgColor}
                        label={t("accounts_page.bg_color_label")}
                      />
                      <ColorPicker
                        onChange={(color: any) => {
                          setTextColor(color.rgb);
                        }}
                        textColor={bgColor}
                        color={textColor}
                        label={t("accounts_page.text_color_label")}
                      />
                    </div>
                  </div>
                  <form
                    className="flex flex-col gap-4 md:min-w-[350px]"
                    id={FORM_ID}
                    onSubmit={(e) => {
                      e?.preventDefault?.();

                      if (editMode) {
                        setShowUpdateConfirmDialog(true);
                      } else {
                        setShowSaveConfirmDialog(true);
                      }
                    }}
                  >
                    <div className="flex flex-col gap-4">
                      <div className={clsx(["grid", "grid-cols-2", "gap-2"])}>
                        <TextInput
                          placeholder={t(
                            "accounts_page.short_name_placeholder"
                          )}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          label={t("accounts_page.short_name_label")}
                          type="text"
                          form={FORM_ID}
                          required
                          disabled={isPerformingAction}
                          autoComplete="name nickname"
                        />
                        <TextInput
                          placeholder={t(
                            "accounts_page.account_number_placeholder"
                          )}
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                          label={t("accounts_page.account_number_label")}
                          type="text"
                          form={FORM_ID}
                          disabled={isPerformingAction}
                        />
                      </div>
                      <div className={clsx(["grid", "grid-cols-2", "gap-2"])}>
                        <TextInput
                          placeholder={t("accounts_page.bank_placeholder")}
                          value={bank}
                          onChange={(e) => setBank(e.target.value)}
                          label={t("accounts_page.bank_label")}
                          type="text"
                          form={FORM_ID}
                          disabled={isPerformingAction}
                        />
                        <Select
                          required
                          label={t("accounts_page.currency_label")}
                          placeholder={t("accounts_page.currency_placeholder")}
                          options={CURRENCY_SELECT_OPTIONS}
                          value={currency}
                          form={FORM_ID}
                          onChange={(e: any) => setCurrency(e?.target?.value)}
                          disabled={isPerformingAction}
                          autoComplete="transaction-currency"
                        />
                      </div>
                      <div className={clsx(["grid", "grid-cols-1", "gap-2"])}>
                        <Select
                          required
                          label={t("Método de pago predeterminado")}
                          placeholder={t("accounts_page.currency_placeholder")}
                          options={paymentMethods()}
                          value={defaultPaymentMethod}
                          form={FORM_ID}
                          onChange={(e: any) =>
                            setDefaultPaymentMethod(e?.target?.value)
                          }
                          disabled={isPerformingAction}
                          autoComplete="transaction-currency"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  className={clsx([
                    "w-full",
                    "flex",
                    "bg-inherit",
                    "md:py-3",
                    "sticky",
                    "bottom-20",
                    "md:bottom-0",
                    {
                      "justify-between": editMode,
                      "justify-end": !editMode,
                    },
                  ])}
                >
                  <ButtonGroup
                    align="left"
                    buttons={[
                      {
                        tooltip: t("shared.delete"),
                        onClick: () => {
                          setShowDeleteConfirmDialog(true);
                        },
                        icon: <MdDelete size={ICON_SIZES.MD} />,
                        kind: "delete",
                        type: "button",
                        form: FORM_ID,
                        hide: !editMode,
                      },
                    ]}
                  />
                  <ButtonGroup
                    buttons={[
                      {
                        text: t("shared.update"),
                        onClick: () => {},
                        icon: <MdCloudDone size={ICON_SIZES.MD} />,
                        kind: "save",
                        type: "submit",
                        form: FORM_ID,
                        hide: !editMode,
                      },
                      {
                        text: t("shared.save"),
                        onClick: () => {},
                        icon: <MdCloudDone size={ICON_SIZES.MD} />,
                        kind: "update",
                        type: "submit",
                        form: FORM_ID,
                        hide: editMode,
                      },
                    ]}
                  />
                </div>
              </>
            </div>
          )}
        </div>
      </TwoSidebars>
      <>
        {showSaveConfirmDialog && (
          <ConfirmDialog
            description={t("dialogs.accounts.save_dialog_question")}
            title={t("dialogs.accounts.save_dialog_title")}
            onClose={() => {
              if (!isRegistering) {
                setShowSaveConfirmDialog(false);
              }
            }}
            onConfirm={async () => {
              setIsRegistering(true);
              const accountId = uuidv4();

              const response = await registerNewAccount({
                account: accountId,
                name,
                bank,
                number,
                currency,
                settings: {
                  bg: bgColor,
                  color: textColor,
                },
              });

              if (response.success) {
                setShowSaveConfirmDialog(false);
                onClose();
                showNotification({
                  message: "Cuenta agregada correctamente",
                  kind: "success",
                });
                navigate(`/settings/accounts/${accountId}`, { replace: true });
              } else {
                showNotification({
                  message: "Error agregando la nueva cuenta",
                  kind: "error",
                });
              }

              setIsRegistering(false);
            }}
            confirmKind="save"
            isLoading={isRegistering}
          />
        )}

        {showUpdateConfirmDialog && (
          <ConfirmDialog
            description={t("dialogs.accounts.update_dialog_question")}
            title={t("dialogs.accounts.update_dialog_title")}
            onClose={() => {
              if (!isUpdating) {
                setShowUpdateConfirmDialog(false);
              }
            }}
            onConfirm={async () => {
              setIsUpdating(true);

              const res = await updateExistingAccount({
                account: accountId,
                name,
                bank,
                number,
                currency,
                settings: {
                  ...settings,
                  bg: bgColor,
                  color: textColor,
                },
              });

              if (res.success) {
                setShowUpdateConfirmDialog(false);
                onClose();
                showNotification({
                  message: "Cuenta actualizada correctamente",
                  kind: "success",
                });
              } else {
                showNotification({
                  message: "Error actualizando la cuenta",
                  kind: "error",
                });
              }

              setIsUpdating(false);
            }}
            confirmKind="save"
            isLoading={isUpdating}
          />
        )}

        {showDeleteConfirmDialog && (
          <ConfirmDialog
            description={t("dialogs.accounts.delete_dialog_question")}
            title={t("dialogs.accounts.delete_dialog_title")}
            onClose={() => {
              if (!isDeleting) {
                setShowDeleteConfirmDialog(false);
              }
            }}
            onConfirm={async () => {
              setIsDeleting(true);

              const res = await deleteAccount({ account: accountId });

              if (res.success) {
                setShowDeleteConfirmDialog(false);
                onClose();
                showNotification({
                  message: "Cuenta eliminada correctamente",
                  kind: "success",
                });
                navigate("/settings/accounts/", { replace: true });
              } else {
                showNotification({
                  message: "Error eliminando la cuenta",
                  kind: "error",
                });
              }

              setIsDeleting(false);
            }}
            confirmKind="delete"
            isLoading={isDeleting}
          />
        )}

        {showQrCode && (
          <Modal onEscape={() => setShowQrCode(false)}>
            <Modal.Header title="Scan" onClose={() => setShowQrCode(false)} />
            <Modal.Body>
              <div className={clsx(["flex", "items-center", "justify-center"])}>
                <QRCodeSVG
                  value={[
                    `Banco: ${bank || "Desconocido"}`,
                    `Número de cuenta: ${number || "Desconocido"}`,
                    `Moneda: ${currency || "Desconocida"}`,
                  ].join("\n")}
                  title={"eXpenser.net"}
                  size={256}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"L"}
                  type="text"
                />
              </div>
            </Modal.Body>
            <Modal.Footer
              actions={[
                {
                  icon: <MdClose size={ICON_SIZES.SM} />,
                  onClick: () => setShowQrCode(false),
                  text: t("shared.close"),
                },
              ]}
            />
          </Modal>
        )}
      </>
    </>
  );
};

export default NewAccountPage;
