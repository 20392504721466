import clsx from "clsx";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import { useNavigate, useParams } from "react-router-dom";

import PageTitle from "../../components/PageTitle/PageTitle";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import {
  MdArrowBack,
  MdArrowForward,
  MdAttachMoney,
  MdCategory,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdCompareArrows,
  MdEdit,
  MdFilterAlt,
  MdOutlineShoppingCart,
  MdViewSidebar,
} from "react-icons/md";
import userPreferences from "../../store/userPreferences";
import { useEffect, useState } from "react";
import transactionsStore from "../../store/transactionsStore";
import _ from "lodash";
import categoriesStore from "../../store/categoriesStore";
import CategoryEditPanel from "../../components/CategoryEditPanel/CategoryEditPanel";
import LoadingWithText from "../../components/LoadingWithText/LoadingWithText";
import { ALL_USER_ICONS } from "../../helpers/user-icons";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";
import { BUTTON_STYLES, ICON_SIZES } from "../../helpers/constants";
import useMainFilter from "../../hooks/useMainFilter";

interface ICategoryIcon {
  name: string;
}

export const CategoryIcon: React.FC<ICategoryIcon> = ({ name }) => {
  const Icon = ALL_USER_ICONS.find(
    ({ name: iconName }) => name === iconName
  )?.icon;

  if (Icon) {
    return (
      <>
        <Icon size={ICON_SIZES.SM} className="hidden md:flex" />
        <Icon size={ICON_SIZES.SM} className="md:hidden" />
      </>
    );
  }

  return (
    <>
      <MdCategory size={ICON_SIZES.SM} className="hidden md:flex" />
      <MdCategory size={ICON_SIZES.SM} className="md:hidden" />
    </>
  );
};

const CategoryPage: React.FC = () => {
  const { rightPanelIsVisible, setRightPanelIsVisible } = userPreferences();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { t } = useTranslation();
  const { getCategory } = categoriesStore();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [categoryData, setCategoryData] = useState<any>({});
  const { setMainFilter } = useMainFilter();
  const { setTransactionsActiveTab } = userPreferences();

  const navigate = useNavigate();

  const { category: categoryParam } = useParams();

  useEffect(() => {
    const nada = async () => {
      if (categoryParam) {
        const hello = await getCategory({ category: categoryParam });

        setCategoryData({
          settings: hello?.data?.settings,
          category: hello?.data?.category,
        });
        setIsLoading(false);
      }
    };
    nada();
  }, [categoryParam, getCategory]);

  return (
    <TwoSidebars
      rightClasses={clsx(["flex-col-reverse md:flex-col"])}
      right={
        <>
          {/* <SettingsBlocks layout="vertical" /> */}
          {/*<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />*/}

          {<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}
        </>
      }
    >
      <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
        <PageTitle
          title={"Categorias"}
          subtitle={"Editar categoria"}
          isLoading={false}
          titleLink="/settings"
          rightSide={
            <>
              <ButtonGroup
                align="right"
                gap={1}
                buttons={[
                  {
                    icon: <MdFilterAlt size={ICON_SIZES.SM} />,
                    classes: isMobile ? BUTTON_STYLES.HEADING : "",
                    onClick: () => {
                      setTransactionsActiveTab(0);

                      setMainFilter({
                        dateFrom: "",
                        dateTo: "",
                        type: "",
                        status: [],
                        payTo: [],
                        // account: "",
                        searchTerm: "",
                        paymentMethod: [],
                        tags: [],
                        // category: [],
                        currency: [],
                        category: [categoryParam],
                      });
                      navigate("/transactions");
                    },
                    kind: "normal",
                  },
                  {
                    icon: <MdArrowBack size={ICON_SIZES.SM} />,
                    classes: isMobile ? BUTTON_STYLES.HEADING : "",

                    tooltip: "Atras",
                    onClick: () => {
                      navigate("/settings/categories");
                    },
                    hide: !isMobile,
                  },
                  {
                    icon: <MdOutlineShoppingCart size={ICON_SIZES.SM} />,
                    tooltip: "Añadir gasto",
                    onClick: () => {
                      navigate("/transactions/expense");
                    },
                    hide: isMobile,
                  },

                  {
                    icon: <MdAttachMoney size={ICON_SIZES.SM} />,
                    tooltip: "Añadir ingreso",
                    hide: isMobile,
                    onClick: () => {
                      navigate("/transactions/income");
                    },
                  },
                  {
                    icon: <MdCompareArrows size={ICON_SIZES.SM} />,
                    tooltip: "Transacciones",
                    onClick: () => {
                      navigate("/transactions");
                    },
                    hide: isMobile,
                  },

                  {
                    icon: <MdViewSidebar size={ICON_SIZES.SM} />,
                    tooltip: "Panel lateral",
                    kind: rightPanelIsVisible ? "primary" : "secondary",
                    hide: isMobile,
                    onClick: () => {
                      setRightPanelIsVisible(!rightPanelIsVisible);
                    },
                  },
                ]}
              />
            </>
          }
        />

        {isLoading ? (
          <div className={clsx(["flex", "w-full", "justify-start"])}>
            <LoadingWithText
              text={t("shared.loading_categories", { count: 1 })}
            />
          </div>
        ) : (
          <>
            {categoryParam && categoryParam ? (
              <CategoryEditPanel category={categoryData} />
            ) : null}
          </>
        )}
      </div>
    </TwoSidebars>
  );
};

export default CategoryPage;
