import clsx from "clsx";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import { useNavigate } from "react-router-dom";

import PageTitle from "../../components/PageTitle/PageTitle";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import {
  MdArrowBack,
  MdArrowForward,
  MdAttachMoney,
  MdCategory,
  MdCompareArrows,
  MdOutlineShoppingCart,
  MdViewSidebar,
} from "react-icons/md";
import userPreferences from "../../store/userPreferences";
import { useMemo, useState } from "react";
import { rgbaObjectToString } from "../../helpers/utils";
import LoadingWithText from "../../components/LoadingWithText/LoadingWithText";
import { ALL_USER_ICONS } from "../../helpers/user-icons";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";
import {
  BUTTON_STYLES,
  CATEGORIES_DEFAULT_BG_COLOR,
  CATEGORIES_DEFAULT_ICON,
  CATEGORIES_DEFAULT_TEXT_COLOR,
  ICON_SIZES,
  PAGINATION_MIN,
  PAGINATION_ROWS_PER_PAGE_OPTIONS,
} from "../../helpers/constants";
import DataTable from "react-data-table-component";
import ButtonGroupCell from "../Transactions/ButtonGroupCell";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import TableSearch from "../../components/TableSearch/TableSearch";
import CellWithBackground from "../Transactions/BackgroundCell";
import useCategories from "../../hooks/useCategories";

interface ICategoryIcon {
  name: string;
  size?: number;
}

export const CategoryIcon: React.FC<ICategoryIcon> = ({ name, size = 24 }) => {
  const Icon = ALL_USER_ICONS.find(
    ({ name: iconName }) => name === iconName
  )?.icon;

  if (Icon) {
    return (
      <>
        <Icon size={size} className="hidden md:flex" />
        <Icon size={size} className="md:hidden" />
      </>
    );
  }

  return (
    <>
      <MdCategory size={size} className="hidden md:flex" />
      <MdCategory size={size} className="md:hidden" />
    </>
  );
};

const CategoriesPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { rightPanelIsVisible, setRightPanelIsVisible } = userPreferences();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { allCategoryList } = useCategories();
  const { setTableResultsPerPage, tableResultsPerPage } = userPreferences();

  const isLoading = false;

  const COLUMNS = [
    {
      width: "60px",
      cell: (row: any) => {
        if (row) {
          return (
            <div
              style={{
                backgroundColor: rgbaObjectToString(
                  row?.settings?.bg || CATEGORIES_DEFAULT_BG_COLOR
                ),
                color: rgbaObjectToString(
                  row?.settings?.color || CATEGORIES_DEFAULT_TEXT_COLOR
                ),
              }}
              className={clsx([
                "rounded-md md:rounded-md",
                "h-[40px] w-[40px] min-w-[40px] min-h-[40px]",
                "flex",
                "items-center",
                "justify-center",
              ])}
            >
              <CategoryIcon
                name={row?.settings?.icon || CATEGORIES_DEFAULT_ICON}
              />
            </div>
          );
        }

        return <CategoryIcon name={CATEGORIES_DEFAULT_ICON} />;
      },
      selector: (row: any) => row?.category,
    },
    {
      selector: (row: any) => row?.category,
      sortable: true,
      cell: (row: any) => {
        return (
          <CellWithBackground
            background={rgbaObjectToString(
              row?.settings?.bg || CATEGORIES_DEFAULT_BG_COLOR
            )}
            color={rgbaObjectToString(
              row?.settings?.color || CATEGORIES_DEFAULT_TEXT_COLOR
            )}
            value={row?.category}
          />
        );
      },
    },
    {
      selector: (row: any) => row?.caetgory,
      cell: (row: any) => {
        return (
          <ButtonGroupCell
            buttons={[
              {
                icon: <MdArrowForward size={ICON_SIZES.SM} />,
                onClick: () =>
                  navigate(`/settings/categories/${row?.category}`),
                kind: "normal",
              },
            ]}
          />
        );
      },
      sortable: true,
    },
  ];

  const localSearch = useMemo(() => {
    const lowerCaseTerm = searchTerm?.toLowerCase?.()?.trim?.();

    if (lowerCaseTerm) {
      return (
        allCategoryList?.data?.data?.filter?.(
          ({ category }: { category: any }) => {
            return category?.toLowerCase?.()?.includes?.(lowerCaseTerm);
          }
        ) || []
      );
    }

    return allCategoryList?.data?.data;
  }, [allCategoryList?.data?.data, searchTerm]);

  return (
    <TwoSidebars
      rightClasses={clsx(["flex-col-reverse md:flex-col"])}
      right={
        <>
          {/* <SettingsBlocks layout="vertical" /> */}
          {/*<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />*/}

          {<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}
        </>
      }
    >
      <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
        <PageTitle
          title={t("shared.settings")}
          subtitle={t("categories_page.title")}
          isLoading={false}
          titleLink="/settings"
          rightSide={
            <>
              <ButtonGroup
                align="right"
                gap={1}
                buttons={[
                  {
                    icon: <MdArrowBack size={ICON_SIZES.SM} />,
                    classes: isMobile ? BUTTON_STYLES.HEADING : "",

                    tooltip: "Atras",
                    onClick: () => {
                      navigate("/settings");
                    },
                    hide: !isMobile,
                  },
                  {
                    icon: <MdOutlineShoppingCart size={ICON_SIZES.SM} />,
                    tooltip: "Añadir gasto",
                    onClick: () => {
                      navigate("/transactions/expense");
                    },
                    hide: isMobile,
                  },

                  {
                    icon: <MdAttachMoney size={ICON_SIZES.SM} />,
                    tooltip: "Añadir ingreso",
                    hide: isMobile,
                    onClick: () => {
                      navigate("/transactions/income");
                    },
                  },
                  {
                    icon: <MdCompareArrows size={ICON_SIZES.SM} />,
                    tooltip: "Transacciones",
                    onClick: () => {
                      navigate("/transactions");
                    },
                    hide: isMobile,
                  },

                  {
                    icon: <MdViewSidebar size={ICON_SIZES.SM} />,
                    tooltip: "Panel lateral",
                    kind: rightPanelIsVisible ? "primary" : "secondary",
                    hide: isMobile,
                    onClick: () => {
                      setRightPanelIsVisible(!rightPanelIsVisible);
                    },
                  },
                ]}
              />
            </>
          }
        />

        {isLoading ? (
          <div className={clsx(["flex", "w-full", "justify-start"])}>
            <LoadingWithText
              text={t("shared.loading_categories", { count: 1111 })}
            />
          </div>
        ) : (
          <div className={clsx(["flex", "flex-col", "gap-2"])}>
            <SectionTitle title="Administra tus categorías" />
            <TableSearch
              onChange={(value) => setSearchTerm(value)}
              onSubmit={() => {}}
            />
            <DataTable
              columns={COLUMNS}
              data={localSearch}
              noHeader
              noTableHead
              selectableRows={false}
              pagination={localSearch?.length > PAGINATION_MIN}
              // striped
              onChangeRowsPerPage={(rows) => {
                setTableResultsPerPage(rows);
              }}
              customStyles={{
                rows: {
                  style: {
                    "&:not(:last-of-type)": {
                      borderBottomWidth: "0px",
                    },
                  },
                },
              }}
              paginationPerPage={tableResultsPerPage}
              paginationRowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
              paginationComponentOptions={{
                selectAllRowsItem: true,
                selectAllRowsItemText: t("pagination.all"),
                rowsPerPageText: t("pagination.rows_per_page"),
                rangeSeparatorText: t("pagination.separator"),
              }}
              noDataComponent={
                <div
                  className={clsx([
                    "px-2",
                    "py-4",
                    "flex",
                    "items-center",
                    "justify-center",
                    "text-sm",
                    "md:text-base",
                  ])}
                >
                  {t("empty_states.no_records")}
                </div>
              }
            />
          </div>
        )}
      </div>
    </TwoSidebars>
  );
};

export default CategoriesPage;
