import clsx from "clsx";
import { ALL_USER_ICONS } from "../../helpers/user-icons";

interface IIconSelector {
  onClick: (icon: string) => void;
  selected?: string;
  background?: string;
  color?: string;
}

const IconSelector: React.FC<IIconSelector> = ({
  onClick,
  selected,
  background,
  color,
}) => {
  return (
    <div className="grid grid-cols-1 gap-4 overflow-auto md:overflow-auto max-h-[270px]">
      <div className={clsx(["grid", "grid-cols-4", "gap-2"])}>
        {ALL_USER_ICONS?.map?.((a, key) => {
          return (
            <div
              key={key}
              className={clsx([
                "flex",
                "items-center",
                "justify-center",
                "cursor-pointer",
                "rounded-md",
                "h-[60px]",
                "transition-all",
                {
                  "text-gray-400": selected !== a.name,
                  "hover:text-gray-500 hover:bg-gray-200": selected !== a.name,
                },
              ])}
              style={{
                ...(selected === a.name ? { background } : {}),
                ...(selected === a.name ? { color } : {}),
              }}
              onClick={() => {
                if (selected === a.name) {
                  onClick("");
                } else {
                  onClick(a.name);
                }
              }}
              id={a.name}
            >
              <a.icon size={42} className={clsx([{}])} />
            </div>
          );
        }) || ""}
      </div>
    </div>
  );
};

export default IconSelector;
