import { create } from "zustand";
import ApiClient from "../helpers/api-client";
import { API_ENDPOINT_GET_USERS, APP_USER_ID_KEY } from "../helpers/constants";
import apiClient from "../helpers/api-client";
import _ from "lodash";

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const usersStore = (set: any, get: any) => ({
  currentUserData: null,
  getUser: async ({ _id }: { _id: string }) => {
    try {
      const response: any = await ApiClient.get(`/user`, {
        headers: DEFAULT_HEADERS,
        params: { _id },
      });

      const res = response.data;

      if (res?.success) {
        return {
          success: true,
          data: res?.data || null,
        };
      } else {
        console.log("Error");
      }

      return {
        success: false,
        data: null,
      };
    } catch (e) {
      return {
        success: false,
        message: e,
        data: null,
      };
    }
  },
});

export default create(usersStore);
