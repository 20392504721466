import clsx from "clsx";
import {
  MdBugReport,
  MdErrorOutline,
  MdHeartBroken,
  MdLinkOff,
  MdNearbyError,
  MdOutlineDescription,
  MdOutlineHeartBroken,
} from "react-icons/md";

interface IEndpointError {
  message: string;
}

const EndpointError: React.FC<IEndpointError> = ({ message }) => {
  return (
    <div
      className={clsx([
        "flex",
        "flex-col",
        "items-start",
        "justify-center",
        "bg-white",
        "gap-6",
        "text-gray-400",
        "hover:border-red-400",
        "hover:text-red-400",
        "transition-colors",
        "w-full",
      ])}
    >
      <div className={clsx(["flex", "flex-col", "items-start"])}>
        <div className={clsx(["flex", "flex-row", "items-center", "gap-1"])}>
          <MdLinkOff size={32} className={clsx(["text-red-400"])} />
          <p className={clsx(["text-gray-500", "text-xl", "font-medium"])}>
            eXpenser API
          </p>
        </div>
        <div className={clsx(["text-xs", "font-medium", "text-secondary-400"])}>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default EndpointError;
