import { MdClose, MdEmail, MdLink, MdPerson, MdPhone } from "react-icons/md";
import Modal from "../Modal/Modal";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { FILE_STORAGE_SERVER_URL, ICON_SIZES } from "../../helpers/constants";
import ButtonGroup from "../ButtonGroup/ButtonGroup";

interface INetworkProfile {
  onClose: () => void;
  profileInfo: any;
  actions: any;
}

const NetworkProfile: React.FC<INetworkProfile> = ({
  onClose,
  profileInfo,
  actions = [],
}) => {
  const { t } = useTranslation();
  return (
    <Modal onEscape={() => onClose()}>
      <Modal.Header title="Perfil" onClose={() => onClose()} />
      <Modal.Body>
        <div className={clsx(["flex", "h-full", "flex-col", "gap-4"])}>
          <div className={clsx(["flex", "flex-row", "gap-2"])}>
            <div className="flex items-center justify-start flex-shrink-0">
              {profileInfo.picture ? (
                <img
                  src={`${FILE_STORAGE_SERVER_URL}/${profileInfo.picture}`}
                  alt={profileInfo.name}
                  className={clsx([
                    "h-[100px]",
                    "w-[100px]",
                    "object-cover",
                    "rounded-full",
                    "overflow-hidden",
                  ])}
                />
              ) : (
                <MdPerson
                  className={clsx([
                    "h-[100px]",
                    "w-[100px]",
                    "object-cover",
                    "rounded-full",
                    "overflow-hidden",
                    "text-gray-300",
                    "bg-white",
                  ])}
                />
              )}
            </div>
            <div
              className={clsx([
                "flex",
                "flex-col",
                "gap-1",
                "items-end",
                "justify-center",
                "w-full",
              ])}
            >
              <div
                className={clsx([
                  "flex",
                  "flex-row",
                  "text-base",
                  "items-center",
                  "gap-1",
                  "font-medium",
                ])}
              >
                {profileInfo?.name}
              </div>
              <div
                className={clsx([
                  "flex",
                  "flex-row",
                  "text-xs",
                  "items-center",
                  "gap-1",
                ])}
              >
                <MdEmail size={ICON_SIZES.SM} />
                {profileInfo?.email}
              </div>
              {profileInfo?.phone && (
                <div
                  className={clsx([
                    "flex",
                    "flex-row",
                    "text-xs",
                    "items-center",
                    "gap-1",
                  ])}
                >
                  <MdPhone size={ICON_SIZES.SM} />
                  {profileInfo?.phone}
                </div>
              )}
              {profileInfo?.website && (
                <div
                  className={clsx([
                    "flex",
                    "flex-row",
                    "text-xs",
                    "items-center",
                    "gap-1",
                  ])}
                >
                  <MdLink size={ICON_SIZES.SM} />
                  {profileInfo?.website}
                </div>
              )}
            </div>
          </div>
          <div
            className={clsx([
              "flex",
              "flex-col",
              "gap-3",
              "overflow-auto",
              "max-h-[200px]",
            ])}
          >
            <p className={clsx(["text-xs", "text-left"])}>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Consequuntur odit voluptatum expedita suscipit laborum minus
              molestias voluptates, molestiae laboriosam praesentium laudantium,
              amet, maxime reprehenderit quas id nesciunt dicta assumenda omnis.
            </p>
            <p className={clsx(["text-xs", "text-left"])}>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Consequuntur odit voluptatum expedita suscipit laborum minus
              molestias voluptates, molestiae laboriosam praesentium laudantium,
              amet, maxime reprehenderit quas id nesciunt dicta assumenda omnis.
            </p>
            <p className={clsx(["text-xs", "text-left"])}>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Consequuntur odit voluptatum expedita suscipit laborum minus
              molestias voluptates, molestiae laboriosam praesentium laudantium,
              amet, maxime reprehenderit quas id nesciunt dicta assumenda omnis.
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        leftContent={<ButtonGroup align="left" buttons={actions} gap={1} />}
        actions={[
          {
            icon: <MdClose size={ICON_SIZES.SM} />,
            onClick: () => onClose(),
            text: t("shared.close"),
          },
        ]}
      />
    </Modal>
  );
};

export default NetworkProfile;
