// @ts-ignore
import {
  MdArrowDownward,
  MdArrowUpward,
  MdNoteAlt,
  MdViewSidebar,
  MdCompareArrows,
  MdCalendarMonth,
  MdPayment,
  MdCategory,
  MdFilterAltOff,
  MdSearch,
  MdCheck,
  MdMoreHoriz,
  MdPrint,
  MdDownload,
  MdFileOpen,
  MdRadioButtonUnchecked,
  MdBarChart,
  MdStore,
  MdEditSquare,
  MdOutlineCreditCard,
  MdCreditCard,
  MdAdd,
  MdAddCard,
} from "react-icons/md";
import SummaryBlocks from "../../components/SummaryBlocks/SummaryBlocks";
import PageTitle from "../../components/PageTitle/PageTitle";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingWithText from "../../components/LoadingWithText/LoadingWithText";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import clsx from "clsx";
import userPreferences from "../../store/userPreferences";
import useAccountsStore from "../../store/accountsStore";
import {
  TRANSACTIONS_BY_CATEGORY_CHART,
  TRANSACTIONS_BY_PAYMENT_METHOD_CHART,
  TRANSACTIONS_BY_TYPE_CHART,
  TRANSACTIONS_PER_MONTH_CHART,
  TRANSACTIONS_PER_CLIENT_PROVIDER_CHART,
} from "./TransactionsCharts";
import {
  getCurrencyDataByCode,
  getUniqueYearsFromTransactions,
  rgbaObjectToString,
} from "../../helpers/utils";
import transactionsStore from "../../store/transactionsStore";
import LinksList from "../../components/LinksList/LinksList";
import useModalsStore from "../../store/modalsStore";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import ReportByTransactionType from "../../components/Reports/ReportByTransactionType";
import ReportByCategories from "../../components/Reports/ReportByCategories";
import ReportByMonth from "../../components/Reports/ReportByMonth";
import ReportByPaymentMethods from "../../components/Reports/ReportByPaymentMethods";
import ReportByClientProvider from "../../components/Reports/ReportByClientProvider";
import TransactionsSearchModal from "../../components/Modals/TransactionsSearchModal";
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import EmptyComponentState from "../../components/EmptyComponentState/EmptyComponentState";
import AccountsSlider from "../../components/Accounts/AccountsSlider";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import TransactionsSummary from "./TransactionsSummary";
import {
  BUTTON_STYLES,
  CATEGORIES_DEFAULT_BG_COLOR,
  CATEGORIES_DEFAULT_TEXT_COLOR,
  EXPENSE_TYPE,
  ICON_SIZES,
  INCOME_TYPE,
} from "../../helpers/constants";
import Select from "../../components/Select/Select";
import TodayTotalsBar from "../../components/TodayTotalsBar/TodayTotalsBar";
import useTransactions, {
  useAllTransactions,
} from "../../hooks/useTransactions";
import useMainFilter from "../../hooks/useMainFilter";
import ConnectionErrorBanner from "../../components/ConnectionErrorBanner/ConnectionErrorBanner";
import useYearFilter from "../../hooks/useYearFilter";
import useCurrencyFilter from "../../hooks/useCurrencyFilter";
import NoTransactionsBanner from "../../components/NoTransactionsBanner/NoTransactionsBanner";

const Reports: React.FC = () => {
  const { yearFilter, setYearFilter } = useYearFilter();
  const { currencyFilter, setCurrencyFilter } = useCurrencyFilter();

  const {
    transactions: {
      data: transactions,
      isLoading: isLoadingTransactions,
      isLoadingError: isLoadingTransactionsError,
      isSuccess: isLoadingTransactionsSuccess,
      isError: isTransactionsError,
      isRefetchError: isTransactionsRefetchError,
    },
  } = useTransactions();
  const {
    allTransactions: { data: allTransactionsData },
  } = useAllTransactions();
  const {
    rightPanelIsVisible,
    setRightPanelIsVisible,
    reportsActiveTab,
    setReportsActiveTab,
  } = userPreferences();
  const [activeTab, setActiveTab] = useState<number>(reportsActiveTab);
  // const [availableYears, setAvailableYears] = useState<Array<any>>([]);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [filteredResults, setFilteredResults] = useState<Array<any>>([]);
  const { setLastUsedAccount } = transactionsStore();
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
  const { mainFilter, setMainFilter, isMainFilterActive } = useMainFilter();

  const navigate = useNavigate();
  const { account: accountParameter } = useParams();
  const { setShowCreateNewTask } = useModalsStore();
  const { t } = useTranslation();

  const {
    getAccountById,
    allAccounts,
    isLoading: isLoadingAccounts,
  } = useAccountsStore();

  const getAccountName = (accountId: string) => {
    const account = getAccountById(accountId);

    return account?.name;
  };

  useEffect(() => {
    setCurrencyFilter({ selectedCurrency: "" });
    setYearFilter({ selectedYear: "" });
    setMainFilter({ account: accountParameter || "" });
  }, [accountParameter]);

  useEffect(() => {
    setActiveTab(activeTab);
    setReportsActiveTab(activeTab);
  }, [activeTab, accountParameter, setReportsActiveTab]);

  const availableYears = useMemo(
    () =>
      getUniqueYearsFromTransactions({
        transactions: transactions?.data,
      })?.map?.((year) => ({ value: `${year}`, label: `${year}` })) || [],
    [transactions]
  );

  const isLoadingSomething = [
    isLoadingAccounts,
    isLoadingTransactions,
  ].includes(true);

  const transformedTransactions = useMemo(() => {
    return (
      transactions?.data?.map?.((transaction: any) => {
        const account = getAccountById(transaction.account);

        return {
          ...transaction,
          transactionCurrency: account?.currency,
          transactionYear: new Date(transaction.date).getFullYear(),
        };
      }) || []
    );
  }, [transactions, getAccountById]);

  useEffect(() => {
    setFilteredResults(
      transformedTransactions.filter?.((transaction: any) => {
        return (
          Number(transaction.transactionYear) === Number(yearFilter) &&
          currencyFilter === transaction.transactionCurrency
        );
      })
    );
  }, [
    transactions,
    currencyFilter,
    yearFilter,
    transformedTransactions,
    mainFilter,
  ]);

  const transactionsPerSelectedYear = useMemo(() => {
    if (yearFilter) {
      return transformedTransactions?.filter?.(
        (transaction: any) => Number(yearFilter) === transaction.transactionYear
      );
    }

    return [];
  }, [transformedTransactions, yearFilter]);

  const currenciesPerSelectedYear = useMemo(() => {
    if (yearFilter) {
      return _.sortBy(
        Array.from(
          new Set(
            transactionsPerSelectedYear?.map(
              (transaction: any) => transaction.transactionCurrency
            )
          )
        )?.filter?.((currency) => currency)
      );
    }

    return [];
  }, [yearFilter, transactionsPerSelectedYear]);

  useEffect(() => {
    if (currenciesPerSelectedYear?.length) {
      if (!currenciesPerSelectedYear.includes(currencyFilter)) {
        const [defaultCurrency] = currenciesPerSelectedYear;
        setCurrencyFilter({ selectedCurrency: defaultCurrency as string });
      }
    } else {
      setCurrencyFilter({ selectedCurrency: "" });
    }
  }, [currenciesPerSelectedYear, currencyFilter]);

  useEffect(() => {
    if (!yearFilter) {
      if (availableYears?.length) {
        const [defaultYear] = availableYears;
        setYearFilter({ selectedYear: defaultYear.value });
      } else {
        setYearFilter({ selectedYear: "" });
      }
    }
  }, [availableYears, yearFilter]);

  const uniqueYearsList = () =>
    getUniqueYearsFromTransactions({
      transactions: transactions?.data,
    })?.map?.((year) => ({
      value: `${year}`,
      label: `${year}`,
    })) || [];

  const hasAccountSelected = accountParameter;

  return (
    <>
      <TwoSidebars
        rightClasses={clsx(["!flex-col-reverse", "md:!flex-col"])}
        customTopBackground={{
          bg: hasAccountSelected ? "bg-gray-800" : "",
        }}
        isLoading={isLoadingSomething}
        right={
          <>
            {!isMobile && (
              <div className={clsx(["w-full", "flex", "flex-col", "gap-1"])}>
                <SectionTitle title={t("transactions_page.summary_title")} />
                <TransactionsSummary
                  onUpdateNeeded={() => {
                    // setRefreshKey((current) => current + 1);
                  }}
                  isLoading={isLoadingTransactions}
                  transactions={filteredResults}
                  onIncomeClick={() => {
                    const newValue =
                      mainFilter?.type === INCOME_TYPE ? "" : INCOME_TYPE;
                    setMainFilter({
                      type: newValue,
                    });
                  }}
                  onExpenseClick={() => {
                    const newValue =
                      mainFilter?.type === EXPENSE_TYPE ? "" : EXPENSE_TYPE;
                    setMainFilter({
                      type: newValue,
                    });
                  }}
                  onTotalClick={() => {
                    setMainFilter({
                      type: "",
                    });
                  }}
                  selected={mainFilter?.type}
                />
              </div>
            )}

            {!isMobile && (
              <AccountsSlider
                activeAccount={accountParameter || ""}
                showActions={false}
                layout="vertical"
                showTitle={!isMobile}
                mobileSettings={{
                  dots: true,
                  arrows: false,
                  infinite: false,
                  // centerMode: true,
                  // centerPadding: "20px",
                }}
                onCardClick={(data) => {
                  navigate(`/reports/${data}`, { replace: true });
                }}
              />
            )}
          </>
        }
      >
        <div
          className={clsx([
            "flex",
            "flex-col",
            "w-full",
            "gap-2",
            "md:gap-2",
            "h-full",
          ])}
        >
          <PageTitle
            title={t("shared.reports")}
            subtitle={
              <>
                {accountParameter ? (
                  <div
                    className={clsx([
                      "bg-red-500",
                      "text-white",
                      "rounded-full md:rounded-md",
                      "px-2",
                      "flex",
                      "flex-row",
                      "items-center",
                      "gap-1",
                    ])}
                    style={{
                      color: rgbaObjectToString(
                        getAccountById(mainFilter.account)?.settings?.color ||
                          CATEGORIES_DEFAULT_TEXT_COLOR
                      ),
                      background: rgbaObjectToString(
                        getAccountById(mainFilter.account)?.settings?.bg ||
                          CATEGORIES_DEFAULT_BG_COLOR
                      ),
                    }}
                  >
                    <MdCreditCard size={16} />
                    {getAccountName(accountParameter)}
                  </div>
                ) : (
                  t("transactions_page.subtitle_all")
                )}
              </>
            }
            link={{
              text: t("transactions_page.title"),
              icon: <MdCompareArrows />,
              link: "/accounts",
            }}
            titleLink={accountParameter ? "/reports" : ""}
            titleLinkClick={() => {
              setMainFilter({ account: "" });
              // setaccountParameter("");
            }}
            rightSide={
              <>
                <ButtonGroup
                  buttons={[
                    {
                      icon: isMainFilterActive ? (
                        <MdSearch
                          className={clsx(["text-yellow-400"])}
                          size={ICON_SIZES.SM}
                        />
                      ) : (
                        <MdSearch size={ICON_SIZES.SM} />
                      ),
                      onClick: () => {
                        setShowSearchModal(true);
                      },
                      tooltip: t("transactions_search.title"),
                      // kind:,
                      // hide: !isMobile,
                      classes: isMobile ? BUTTON_STYLES.HEADING : "",
                    },
                    {
                      icon: (
                        <MdArrowUpward
                          className={clsx(["text-red-400"])}
                          size={ICON_SIZES.SM}
                        />
                      ),
                      onClick: () => {
                        if (accountParameter) {
                          setLastUsedAccount({ account: accountParameter });
                        }
                        navigate("/transactions/expense");
                      },
                      hide: isMobile,
                      tooltip: t("shared.add_expense"),
                    },
                    {
                      icon: (
                        <MdArrowDownward
                          className={clsx(["text-green-400"])}
                          size={ICON_SIZES.SM}
                        />
                      ),
                      onClick: () => {
                        if (accountParameter) {
                          setLastUsedAccount({
                            account: accountParameter,
                          });
                        }
                        navigate("/transactions/income");
                      },
                      hide: isMobile,
                      tooltip: t("shared.add_income"),
                    },
                    {
                      icon: (
                        <MdNoteAlt
                          className={clsx(["text-yellow-400"])}
                          size={ICON_SIZES.SM}
                        />
                      ),
                      tooltip: t("shared.add_note"),
                      onClick: () => {
                        setShowCreateNewTask(true);
                      },
                      hide: isMobile,
                    },
                    {
                      icon: mainFilter.account ? (
                        <MdOutlineCreditCard size={ICON_SIZES.SM} />
                      ) : (
                        <MdOutlineCreditCard size={ICON_SIZES.SM} />
                      ),
                      classes: isMobile
                        ? clsx([
                            BUTTON_STYLES.HEADING,
                            {
                              "!text-yellow-400": accountParameter,
                            },
                          ])
                        : "",
                      options: [
                        ...(allAccounts?.map?.((account: any) => ({
                          text: account.name,
                          onClick: () => {
                            if (account?.account === mainFilter.account) {
                              setMainFilter({ account: "" });
                              navigate("/reports/", { replace: true });
                            } else {
                              setMainFilter({ account: account?.account });
                              navigate(`/reports/${account?.account}`, {
                                replace: true,
                              });
                            }
                          },
                          className:
                            mainFilter?.account === account.account
                              ? clsx(["font-medium"])
                              : "",
                          icon: (
                            <div
                              className={clsx([
                                "flex",
                                "rounded-sm",
                                "h-[16px]",
                                "w-[16px]",
                                "items-center",
                                "justify-center",
                              ])}
                              style={{
                                backgroundColor: rgbaObjectToString(
                                  account?.settings?.bg
                                ),
                              }}
                            >
                              {mainFilter?.account === account.account ? (
                                <MdCheck
                                  size={12}
                                  style={{
                                    color: rgbaObjectToString(
                                      account?.settings?.color
                                    ),
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                          ),
                        })) || []),
                        {
                          icon: !accountParameter ? (
                            <MdCheck
                              size={20}
                              className={clsx(["text-gray-800"])}
                            />
                          ) : (
                            <MdRadioButtonUnchecked
                              className={clsx(["text-gray-800"])}
                              size={20}
                            />
                          ),
                          text: t("shared.all_accounts"),
                          onClick: () => {
                            setMainFilter({ account: "" });
                            navigate(`/reports/`, { replace: true });
                          },
                        },
                        {
                          icon: (
                            <MdAddCard
                              size={20}
                              className={clsx(["text-gray-800"])}
                            />
                          ),
                          text: t("Agregar una cuenta"),
                          onClick: () => {
                            navigate(`/settings/accounts/new`);
                          },
                        },
                      ],
                      hide: !allAccounts?.length,
                    },

                    {
                      icon: <MdMoreHoriz size={ICON_SIZES.SM} />,
                      classes: isMobile ? BUTTON_STYLES.HEADING : "",
                      hide: true,
                      options: [
                        {
                          text: "Imprimir",
                          icon: <MdPrint size={ICON_SIZES.SM} />,
                          onClick: () => {
                            // createNewTransaction({ type: INCOME_TYPE });
                          },
                        },
                        {
                          text: t("shared.download"),
                          icon: <MdDownload size={ICON_SIZES.SM} />,
                          onClick: () => {
                            // createNewTransaction({ type: INCOME_TYPE });
                          },
                        },
                        {
                          text: "Exportar en CSV",
                          icon: <MdFileOpen size={ICON_SIZES.SM} />,
                          onClick: () => {
                            // createNewTransaction({ type: INCOME_TYPE });
                          },
                        },
                      ],
                    },
                    {
                      icon: <MdViewSidebar size={ICON_SIZES.SM} />,
                      tooltip: t("shared.lateral_panel"),
                      kind: rightPanelIsVisible ? "primary" : "secondary",
                      hide: isMobile,
                      onClick: () => {
                        setRightPanelIsVisible(!rightPanelIsVisible);
                      },
                    },
                  ]}
                  gap={1}
                />
              </>
            }
            isLoading={isLoadingTransactions}
          />

          {![
            isLoadingTransactionsError,
            isTransactionsError,
            isTransactionsRefetchError,
          ].includes(true) && <TodayTotalsBar />}

          {isMobile && accountParameter && (
            <div className={clsx(["pb-4"])}>
              <AccountsSlider
                activeAccount={accountParameter}
                key={mainFilter?.account ? "keep-alive" : "destroy"}
                showActions={!false}
                layout="horizontal"
                showTitle={false}
                mobileSettings={{
                  dots: true,
                  arrows: false,
                  infinite: true,
                  // centerMode: true,
                  // centerPadding: "20px",
                }}
                desktopSettings={{
                  dots: true,
                }}
                onSlideChange={(account: any) => {
                  if (account) {
                    // setMainFilter({ account: account.account });
                    // setActiveAccount(account?.account);
                    navigate(`/reports/${account?.account}`, { replace: true });
                  } else {
                    // setMainFilter({ account: "" });
                    // setActiveAccount("");
                    navigate(`/reports/`, { replace: true });
                  }
                }}
                onCardClick={(account: any) => {
                  navigate(`/reports/${account}`, { replace: true });
                }}
              />
            </div>
          )}

          {isLoadingSomething ? (
            <div
              className={clsx([
                "flex",
                "justify-center",
                "w-full",
                // "h-[40vh]",
                "items-center",
                "flex-grow",
              ])}
            >
              <LoadingWithText />
            </div>
          ) : (
            <>
              {[
                isLoadingTransactionsError,
                isTransactionsError,
                isTransactionsRefetchError,
              ].includes(true) ? (
                <ConnectionErrorBanner />
              ) : (
                <>
                  {isMobile && (
                    <SummaryBlocks transactions={transactions?.data} />
                  )}
                  <LinksList
                    links={[
                      {
                        text: t("shared.summary"),
                        onClick: () => {
                          // navigate("/transactions/stats");

                          setActiveTab(TRANSACTIONS_BY_TYPE_CHART);
                        },
                        active: activeTab === TRANSACTIONS_BY_TYPE_CHART,
                        icon: (
                          <>
                            <MdBarChart size={32} className="block md:hidden" />
                            <MdBarChart size={40} className="hidden md:block" />
                          </>
                        ),
                        isLoading:
                          isLoadingTransactions &&
                          activeTab === TRANSACTIONS_BY_TYPE_CHART,
                      },
                      {
                        text: t("shared.monthly"),
                        onClick: () => {
                          setActiveTab(TRANSACTIONS_PER_MONTH_CHART);
                        },
                        active: activeTab === TRANSACTIONS_PER_MONTH_CHART,
                        icon: (
                          <>
                            <MdCalendarMonth
                              size={32}
                              className="block md:hidden"
                            />
                            <MdCalendarMonth
                              size={40}
                              className="hidden md:block"
                            />
                          </>
                        ),
                        isLoading:
                          isLoadingTransactions &&
                          activeTab === TRANSACTIONS_PER_MONTH_CHART,
                      },
                      {
                        text: t("shared.categories"),
                        onClick: () => {
                          setActiveTab(TRANSACTIONS_BY_CATEGORY_CHART);
                        },
                        active: activeTab === TRANSACTIONS_BY_CATEGORY_CHART,
                        icon: (
                          <>
                            <MdCategory size={32} className="block md:hidden" />
                            <MdCategory size={40} className="hidden md:block" />
                          </>
                        ),
                        isLoading:
                          isLoadingTransactions &&
                          activeTab === TRANSACTIONS_BY_CATEGORY_CHART,
                      },
                      {
                        text: t("shared.client_provider"),
                        onClick: () => {
                          setActiveTab(TRANSACTIONS_PER_CLIENT_PROVIDER_CHART);
                        },
                        active:
                          activeTab === TRANSACTIONS_PER_CLIENT_PROVIDER_CHART,
                        icon: (
                          <>
                            <MdStore size={32} className="block md:hidden" />
                            <MdStore size={40} className="hidden md:block" />
                          </>
                        ),
                        isLoading:
                          isLoadingTransactions &&
                          activeTab === TRANSACTIONS_BY_PAYMENT_METHOD_CHART,
                      },
                      {
                        text: t("shared.payment_methods"),
                        onClick: () => {
                          setActiveTab(TRANSACTIONS_BY_PAYMENT_METHOD_CHART);
                        },
                        active:
                          activeTab === TRANSACTIONS_BY_PAYMENT_METHOD_CHART,
                        icon: (
                          <>
                            <MdPayment size={32} className="block md:hidden" />
                            <MdPayment size={40} className="hidden md:block" />
                          </>
                        ),
                        isLoading:
                          isLoadingTransactions &&
                          activeTab === TRANSACTIONS_BY_PAYMENT_METHOD_CHART,
                      },
                    ]}
                  />

                  {isMainFilterActive === true && (
                    <InfoBlock
                      message={t("shared.showing_filtered_results")}
                      buttons={[
                        {
                          icon: <MdEditSquare size={ICON_SIZES.SM} />,
                          onClick: () => setShowSearchModal(true),
                          tooltip: t("shared.edit_filter"),
                          kind: "edit",
                        },
                        {
                          text: t("shared.remove_filter"),
                          kind: "delete",
                          icon: <MdFilterAltOff size={ICON_SIZES.SM} />,
                          onClick: () => {
                            setMainFilter({
                              dateFrom: "",
                              dateTo: "",
                              type: "",
                              payTo: [],
                              status: [],
                              paymentMethod: [],
                              tags: [],
                              category: [],
                            });
                          },
                        },
                      ]}
                    />
                  )}

                  {allTransactionsData?.data?.length ? (
                    <>
                      <div
                        className={clsx([
                          "w-full",
                          "flex",
                          "flex-col",
                          "gap-6",
                        ])}
                      >
                        {[isLoadingTransactions, hasAccountSelected]?.includes(
                          true
                        ) ? (
                          <div
                            className={clsx([
                              "flex",
                              "justify-start",
                              "w-full",
                            ])}
                          >
                            <LoadingWithText
                              text={t("shared.loading_transactions", {
                                count: 1111,
                              })}
                            />
                          </div>
                        ) : filteredResults?.length ? (
                          <>
                            {(uniqueYearsList()?.length > 1 ||
                              currenciesPerSelectedYear?.length > 1) && (
                              <div
                                className={clsx([
                                  "w-full",
                                  "grid",
                                  `grid-cols-${
                                    uniqueYearsList()?.length > 1 &&
                                    currenciesPerSelectedYear?.length > 1
                                      ? "2"
                                      : "1"
                                  }`,
                                  "gap-4",
                                ])}
                              >
                                {uniqueYearsList()?.length > 1 && (
                                  <Select
                                    label={t("shared.year")}
                                    options={uniqueYearsList()}
                                    value={yearFilter}
                                    onChange={(e: any) =>
                                      setYearFilter({
                                        selectedYear: e?.target?.value,
                                      })
                                    }
                                    emptyOption={{ show: false }}
                                  />
                                )}
                                {currenciesPerSelectedYear?.length > 1 && (
                                  <Select
                                    options={_.sortBy(
                                      currenciesPerSelectedYear?.map(
                                        (currency: any) => ({
                                          value: currency,
                                          label:
                                            getCurrencyDataByCode({
                                              code: currency,
                                            })?.name || "",
                                        })
                                      ),
                                      "label"
                                    )}
                                    label={t("shared.currency")}
                                    value={currencyFilter}
                                    emptyOption={{ show: false }}
                                    onChange={(e: any) =>
                                      setCurrencyFilter({
                                        selectedCurrency: e?.target?.value,
                                      })
                                    }
                                  />
                                )}
                              </div>
                            )}
                            {currencyFilter && yearFilter ? (
                              <>
                                {activeTab === TRANSACTIONS_BY_TYPE_CHART && (
                                  <>
                                    <ReportByTransactionType
                                      transactions={filteredResults}
                                      currency={currencyFilter}
                                    />
                                  </>
                                )}

                                {activeTab === TRANSACTIONS_PER_MONTH_CHART && (
                                  <ReportByMonth
                                    currency={currencyFilter}
                                    transactions={filteredResults}
                                  />
                                )}

                                {activeTab ===
                                  TRANSACTIONS_BY_CATEGORY_CHART && (
                                  <ReportByCategories
                                    transactions={filteredResults}
                                    currency={currencyFilter}
                                  />
                                )}

                                {activeTab ===
                                  TRANSACTIONS_BY_PAYMENT_METHOD_CHART && (
                                  <ReportByPaymentMethods
                                    transactions={filteredResults}
                                    currency={currencyFilter}
                                  />
                                )}

                                {activeTab ===
                                  TRANSACTIONS_PER_CLIENT_PROVIDER_CHART && (
                                  <ReportByClientProvider
                                    transactions={filteredResults}
                                    currency={currencyFilter}
                                  />
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>
                            <div className={clsx(["flex", "w-full"])}>
                              <EmptyComponentState message="No hay datos para mostrar." />
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <NoTransactionsBanner />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </TwoSidebars>
      {showSearchModal && (
        <TransactionsSearchModal
          onClose={() => setShowSearchModal(false)}
          onSubmit={(filterOptions) => {
            setMainFilter({
              ...mainFilter,
              ...filterOptions,
            });
            setShowSearchModal(false);
          }}
        />
      )}
    </>
  );
};

export default Reports;
