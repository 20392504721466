import { ReactElement } from "react";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import clsx from "clsx";

interface IModalFooter {
  actions?: Array<any>;
  leftContent?: ReactElement | null;
}

const ModalFooter: React.FC<IModalFooter> = ({ actions = [], leftContent }) => {
  if (!actions?.length && !leftContent) return null;

  return (
    <div
      className={clsx([
        "flex",
        "w-full",
        "gap-3",
        {
          "justify-end": !leftContent,
          "justify-between": leftContent,
        },
        "p-4",
        "md:p-6",
        "items-center",
      ])}
    >
      {leftContent && leftContent}
      {actions?.length > 0 && (
        <ButtonGroup buttons={actions} gap={1} layout="horizontal" />
      )}
    </div>
  );
};

export default ModalFooter;
