import networkStore from "../../store/networkStore";
import accountsStore from "../../store/accountsStore";
import {
  API_ENDPOINT_ALL_ACCOUNTS,
  API_ENDPOINT_GET_INVITES,
} from "../../helpers/constants";
import apiClient from "../../helpers/api-client";
import { useQuery } from "@tanstack/react-query";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

const StoreLoaders: React.FC = () => {
  const { setAllAccounts } = accountsStore();

  const getAllUserAccounts = async () => {
    return await apiClient.get(API_ENDPOINT_ALL_ACCOUNTS);
  };

  const { isLoading: isLoadingAccounts, isError: hasErrorLoadingUserAccounts } =
    useQuery({
      queryKey: ["userAccounts"],
      queryFn: async () => {
        const res = await getAllUserAccounts();

        if (res?.data) {
          setAllAccounts(res.data);
          return res.data;
        }

        return [];
      },
    });

  const hasError = [hasErrorLoadingUserAccounts].includes(true);

  return (
    <>
      {hasError && (
        <ConfirmDialog
          onClose={() => {}}
          description="Ocurrió un error cargando tus datos. Por favor, recarga la aplicación."
          onConfirm={() => {
            window.location.reload();
          }}
          title="Error cargando"
          showCancel={false}
        />
      )}
    </>
  );
};

export default StoreLoaders;
