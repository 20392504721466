import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { lazy, Suspense, useCallback, useEffect, useState } from "react";

import transactionsStore from "./store/transactionsStore";
import clsx from "clsx";
import LoadingWithText from "./components/LoadingWithText/LoadingWithText";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import LogoutPage from "./pages/Logout/Logout";
import ProtectedRoutes from "./components/ProtectedRoute/ProtectedRoute";
import CategoriesPage from "./pages/Categories/Categories";
import SettingsPage from "./pages/Settings/SettingsPage";
import { useTranslation } from "react-i18next";
import { APP_TOKEN_KEY, EXPENSE_TYPE, INCOME_TYPE } from "./helpers/constants";
import ModalsLoader from "./components/Modals/ModalsLoader";
import PaymentMethodsPage from "./pages/PaymentMethods/PaymentMethods";
import ProfilePage from "./pages/Profile/ProfilePage";
import AccountsPage from "./pages/Accounts/AccountsPage";
import ChangePasswordPage from "./pages/Password/ChangePasswordPage";
import TransactionsSettingsPage from "./pages/Transactions/TransactionsSettingsPage";

import NotesPage from "./pages/Notes/NotesPage";
import LinksPage from "./pages/Links/LinksPage";

import Reports from "./pages/Transactions/Reports";
import OfflinePage from "./pages/Offline/OfflinePage";
import AppSettingsPage from "./pages/AppSettings/AppSettingsPage";
import PasswordRecoveryPage from "./pages/PasswordRecovery/PasswordRecoveryPage";
import StoreLoaders from "./components/StoreLoaders/StoreLoaders";
import accountsStore from "./store/accountsStore";
import usersStore from "./store/usersStore";
import MyNetworkPage from "./pages/MyNetwork/MyNetworkPage";
import networkStore from "./store/networkStore";
import NewAccountPage from "./pages/NewAccount/NewAccountPage";
import CategoryPage from "./pages/Categories/EditCategoryPage";
import useMainFilter from "./hooks/useMainFilter";

const NotFound404 = lazy(() => import("./pages/NotFound404/NotFound404"));
const RegisterTransactionPage = lazy(
  () => import("./pages/Transactions/RegisterTransaction")
);
const RegisterAccountPage = lazy(
  () => import("./pages/RegisterAccount/RegisterAccount")
);
const LoginPage = lazy(() => import("./pages/Login/Login"));

const Transactions = lazy(() => import("./pages/Transactions/Transactions"));

export const LeRoutes: React.FC = () => {
  const { mainFilter } = useMainFilter();

  return (
    <Router>
      <ScrollToTop />
      <Suspense
        fallback={
          <div
            className={clsx([
              "flex",
              "h-screen",
              "w-full",
              "items-center",
              "justify-center",
            ])}
          >
            <LoadingWithText />
          </div>
        }
      >
        <Routes>
          <Route element={<ProtectedRoutes />}>
            <Route path="/settings/network" element={<MyNetworkPage />} />
            <Route path="/notes" element={<NotesPage />} />
            <Route path="/links" element={<LinksPage />} />

            <Route
              path="/reports"
              element={
                mainFilter.account ? (
                  <Navigate replace to={`/reports/${mainFilter.account}`} />
                ) : (
                  <Reports />
                )
              }
            />
            <Route path="/reports/:account" element={<Reports />} />

            <Route path="/settings/" element={<SettingsPage />} />
            <Route path="/settings/app" element={<AppSettingsPage />} />
            <Route path="/settings/accounts" element={<AccountsPage />} />
            <Route path="/settings/accounts/new" element={<NewAccountPage />} />
            <Route
              path="/settings/accounts/:accountId"
              element={<NewAccountPage editMode />}
            />

            <Route
              path="/settings/transactions"
              element={<TransactionsSettingsPage />}
            />

            <Route path="/settings/categories" element={<CategoriesPage />} />
            <Route
              path="/settings/categories/:category"
              element={<CategoryPage />}
            />

            {/* <Route path="/settings/profile" element={<SettingsPage />} /> */}
            <Route path="/settings/password" element={<ChangePasswordPage />} />
            <Route
              path="/settings/payment-methods"
              element={<PaymentMethodsPage />}
            />
            <Route path="/settings/profile" element={<ProfilePage />} />

            <Route
              path="/transactions"
              element={
                mainFilter.account ? (
                  <Navigate
                    replace
                    to={`/transactions/${mainFilter.account}`}
                  />
                ) : (
                  <Transactions />
                )
              }
            />
            <Route path="/transactions/:account" element={<Transactions />} />

            <Route
              path="/transactions/expense/:invoice"
              element={
                <RegisterTransactionPage
                  transactionType={EXPENSE_TYPE}
                  editMode
                />
              }
            />

            <Route
              path="/transactions/expense"
              element={
                <RegisterTransactionPage transactionType={EXPENSE_TYPE} />
              }
            />

            <Route
              path="/transactions/income/:invoice"
              element={
                <RegisterTransactionPage
                  editMode
                  transactionType={INCOME_TYPE}
                />
              }
            />

            <Route
              path="/transactions/income"
              element={
                <RegisterTransactionPage transactionType={INCOME_TYPE} />
              }
            />

            <Route path="/logout" element={<LogoutPage />} />
            <Route path="*" element={<NotFound404 />} />
          </Route>
          <Route path="/password-recovery" element={<PasswordRecoveryPage />} />

          <Route path="/register" element={<RegisterAccountPage />} />
          <Route path="/" element={<LoginPage />} />
        </Routes>
      </Suspense>
      <ModalsLoader />
    </Router>
  );
};

export default LeRoutes;
