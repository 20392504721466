import clsx from "clsx";
import TransactionsByMonthChart from "../Charts/TransactionsByMonth";
import MonthlySummaryTable from "../Tables/MonthlySummaryTable";

interface IReportByMonth {
  transactions: Array<any>;
  currency: string;
}

const ReportByMonth: React.FC<IReportByMonth> = ({
  transactions,
  currency,
}) => {
  return (
    <div className={clsx(["flex", "w-full", "flex-col"])}>
      <TransactionsByMonthChart
        transactions={transactions}
        currency={currency}
      />
      <MonthlySummaryTable transactions={transactions} currency={currency} />
    </div>
  );
};

export default ReportByMonth;
