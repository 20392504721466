import { useEffect, useState } from "react";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { useTranslation } from "react-i18next";

const UpdateAvailableBanner: React.FC = () => {
  const [hasUpdate, setHasUpdate] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { t } = useTranslation();

  const onControllerChange = () => {
    setHasUpdate(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (hasUpdate && !showDialog) {
        setShowDialog(true);
      }
    }, 20000);

    return () => clearInterval(interval);
  }, [hasUpdate, showDialog]);

  useEffect(() => {
    const varas = async () => {
      navigator.serviceWorker.addEventListener(
        "controllerchange",
        onControllerChange
      );

      return () =>
        navigator.serviceWorker.removeEventListener(
          "controllerchange",
          onControllerChange
        );
    };

    varas();
  });

  useEffect(() => {
    setShowDialog(hasUpdate);
  }, [hasUpdate]);

  return showDialog ? (
    <ConfirmDialog
      description={t("update_available_banner.description")}
      title={t("update_available_banner.title")}
      onClose={() => {
        setShowDialog(false);
      }}
      onConfirm={() => {
        setShowDialog(false);
        window.location.reload();
      }}
    />
  ) : (
    <></>
  );
};

export default UpdateAvailableBanner;
