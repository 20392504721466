import { create } from "zustand";

const useHistoryStore = (set: any, get: any) => ({
  historyIsEnabled: true,
  openedTransactions: [],
  error: null,

  isTransactionInHistory: (transactionId: string) => {
    const transactions = get().openedTransactions;

    return transactions?.includes?.(transactionId);
  },

  markTransactionAsOpened: (transactionId: string) => {
    const transactions = get().openedTransactions;

    set({
      openedTransactions: Array.from(new Set([...transactions, transactionId])),
    });
  },

  clearHistory: () => {
    set({ openedTransactions: [] });
  },

  enableHistory: (isEnabled: boolean) => {
    set({ historyIsEnabled: isEnabled, openedTransactions: [] });
  },
});

export default create(useHistoryStore);
