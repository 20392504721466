import apiClient from "../helpers/api-client";
import { API_ENDPOINT_ALL_CATEGORIES } from "../helpers/constants";

export const getCategories = async () => {
  try {
    return await apiClient.get(API_ENDPOINT_ALL_CATEGORIES);
  } catch (_e) {
    throw _e;
  }

  // return await apiClient.get(API_ENDPOINT_ALL_CATEGORIES).then((data: any) => {
  //   const result = data?.data ? _.sortBy(data?.data, "category") : [];

  //   return result;
  // });
};
