import { create } from "zustand";
import apiClient from "../helpers/api-client";
import {
  API_ENDPOINT_ADD_CATEGORY,
  API_ENDPOINT_ALL_CATEGORIES,
  API_ENDPOINT_GET_CATEGORY,
} from "../helpers/constants";
import { toast } from "react-toastify";

const categoriesStore = (set: any, get: any) => ({
  storeNotes: [],
  isLoadingStoreNotes: false,
  errorLoadingStoreNotes: false,
  error: null,
  isLoading: true,

  getCategories: async ({
    onResolved,
    onError,
  }: {
    onResolved: (e: any) => void;
    onError?: (e: any) => void;
  }) => {
    apiClient
      .get(API_ENDPOINT_ALL_CATEGORIES)
      .then((notes) => {
        onResolved(notes.data);
      })
      .catch((e: any) => {
        if (onError) {
          onError(e);
        } else {
          toast.error(e);
        }
      });
  },

  getCategory: async ({ category }: { category: string }) => {
    return await apiClient
      .get(`${API_ENDPOINT_GET_CATEGORY}/${category}`, {})
      .then((notes) => {
        return notes;
      })
      .catch((e: any) => {
        return e;
      });
  },

  addCategory: async ({
    onResolved = () => {},
    onError = () => {},
    params = {},
  }: {
    onResolved: (e: any) => void;
    onError: (e: any) => void;
    params: any;
  }) => {
    apiClient
      .post(API_ENDPOINT_ADD_CATEGORY, params)
      .then((res: any) => {
        onResolved(res?.data);
      })
      .catch((e: any) => {
        onError(e);
      });
  },
});

export default create(categoriesStore);
