import { useEffect, useId, useState } from "react";
import tasksStore from "../../store/tasksStore";
import clsx from "clsx";
import { MdAdd, MdTaskAlt } from "react-icons/md";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { toast } from "react-toastify";

import SectionTitle from "../SectionTitle/SectionTitle";
import { Tooltip } from "react-tooltip";
import LoadingWithText from "../LoadingWithText/LoadingWithText";
import EmptyComponentState from "../EmptyComponentState/EmptyComponentState";
import EndpointError from "../EndpointError/EndpointError";
import useModalsStore from "../../store/modalsStore";
import { CONFIRM_DIALOG_ICON_SIZE, DELETE_ICON } from "../../constants/icons";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import SliderLeftRight from "../Slider/SliderArrowLeft";
import SliderArrowRight from "../Slider/SliderArrowRight";
import { useMediaQuery } from "react-responsive";
import { showNotification } from "../../helpers/utils";

const TasksListPanel: React.FC = () => {
  const { getTasks, tasks, deleteTask, isLoadingTasks, errorLoadingTasks } =
    tasksStore();
  const [taskToMarkAsDone, setTaskToMarkAsDone] = useState<string>("");
  const [showTaskDoneDialog, setShowTaskDoneDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { setShowCreateNewTask } = useModalsStore();
  const [isDeletingTask, setIsDeletingTask] = useState<boolean>(false);
  const [isAddingTask, setIsAddingTask] = useState<boolean>(false);
  const { addTask } = tasksStore();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const { t } = useTranslation();

  const settings = {
    adaptiveHeight: true,
    arrows: false,
    prevArrow: <SliderLeftRight />,
    nextArrow: <SliderArrowRight />,
    responsive: [
      {
        breakpoint: 100000,
        settings: "unslick" as "unslick",
      },
    ],
  };

  const markTaskAsDone = async () => {
    setIsDeletingTask(true);
    deleteTask({
      onError: (e) => {
        setShowTaskDoneDialog(false);
        setIsDeletingTask(false);
      },
      taskId: taskToMarkAsDone,
      onResolved: () => {
        showNotification({
          message: t("notes.deleted_message"),
          kind: "success",
        });
        setShowTaskDoneDialog(false);
        getTasks({
          onResolved: () => {
            setIsLoading(false);
            setIsDeletingTask(false);
          },
          onError: () => {
            setIsLoading(false);
            setIsDeletingTask(false);
          },
        });
      },
    });
  };

  useEffect(() => {
    getTasks({
      onResolved: () => setIsLoading(false),
      onError: () => setIsLoading(false),
    });
  }, [getTasks]);

  const tooltipId = useId();

  const stillLoading = isLoadingTasks || isLoading;

  return (
    <>
      <div
        className="grid grid-cols-1 gap-2"
        onDragOver={(e) => e.preventDefault()}
        onDrop={async (event) => {
          event.preventDefault();
          var data = event.dataTransfer.getData("text");

          setIsAddingTask(true);
          if (data) {
            await addTask({
              onError: (e) => {
                setIsAddingTask(false);
                showNotification({
                  message: t("notes.error_adding_note"),
                  kind: "error",
                });
              },
              content: data,
              onResolved: (e) => {
                setIsAddingTask(false);
                showNotification({
                  message: t("notes.note_added_message"),
                  kind: "success",
                });
                setShowTaskDoneDialog(false);
                getTasks({
                  onResolved: () => {},
                  onError: () => {},
                });
              },
            });
          } else {
            setIsAddingTask(false);
          }
        }}
      >
        {stillLoading ? (
          <div className={clsx(["flex", "w-full", "justify-start"])}>
            <LoadingWithText
              text={t("shared.loading_notes", { count: 1111 })}
            />
          </div>
        ) : errorLoadingTasks ? (
          <EndpointError message="No se pudo cargar la lista de notas." />
        ) : tasks?.length ? (
          <>
            {!isMobile && <SectionTitle title={t("notes.title")} />}
            <Slider
              {...settings}
              className={clsx(["gap-2", "flex", "flex-col"])}
            >
              {tasks?.map(({ task, content }) => (
                <div
                  key={task}
                  className={clsx([
                    "flex",
                    "flex-row",
                    "bg-gray-100 md:bg-yellow-200",
                    "md:shadow-sm",
                    "text-gray-800",
                    "px-3",
                    "py-3",
                    "rounded-md",
                    "gap-1",
                    "items-start",
                    "transition-colors",
                    "relative",
                    "overflow-hidden",
                    "group",
                  ])}
                >
                  <div
                    className={clsx([
                      "absolute",
                      "pointer-events-none",
                      "right-2",
                      "top-2",
                      "md:right-0",
                      "md:top-0",
                      "group-hover:text-red-300",
                      "text-yellow-200/50",
                    ])}
                  >
                    <MdTaskAlt size={40} />
                  </div>
                  <div className="flex flex-col gap-3">
                    <div
                      className={clsx([
                        "flex",
                        "flex-row",
                        "gap-2",
                        "items-start",
                      ])}
                    >
                      {/* <div className="cursor-pointer hover:text-yellow-300 dark:text-red-500 dark:hover:text-red-600">
                        <div
                          data-tooltip-id={tooltipId}
                          data-tooltip-content={t("shared.delete")}
                          data-tooltip-float
                          data-tooltip-position-strategy="fixed"
                          data-tooltip-offset={20}
                        >
                          <MdTaskAlt size={32} />
                        </div>
                      </div> */}
                      <p
                        className="text-sm break-words pt-1"
                        onClick={() => {
                          setTaskToMarkAsDone(task);
                          setShowTaskDoneDialog(true);
                        }}
                      >
                        {content}
                      </p>
                    </div>
                  </div>
                  <Tooltip id={tooltipId} />
                </div>
              ))}
            </Slider>
          </>
        ) : (
          <>
            <SectionTitle title={t("notes.title")} />
            <EmptyComponentState
              message={t("notes.empty_state")}
              buttons={[
                {
                  kind: "primary",
                  icon: <MdAdd size={32} />,
                  text: t("shared.add"),
                  onClick: () => setShowCreateNewTask(true),
                  isLoading: isAddingTask,
                  disabled: isAddingTask,
                },
              ]}
            />
          </>
        )}
        {/* {isAddingTask && (
          <div>
            <LoadingWithText text="Agregando nota..." />
          </div>
        )} */}
      </div>
      {showTaskDoneDialog && (
        <ConfirmDialog
          description={t("notes.delete_question")}
          title={t("notes.delete_note")}
          confirmKind="save"
          onClose={() => {
            setShowTaskDoneDialog(false);
          }}
          onConfirm={() => {
            markTaskAsDone();
          }}
          icon={<DELETE_ICON size={CONFIRM_DIALOG_ICON_SIZE} />}
          isLoading={isDeletingTask}
        />
      )}
    </>
  );
};

export default TasksListPanel;
