import clsx from "clsx";
import Badge from "../Badge/Badge";

interface ILinksList {
  links: Array<any>;
}

const LinksList: React.FC<ILinksList> = ({ links }) => {
  const linksList = links?.filter?.(({ hide }) => !hide);

  return (
    <div
      className={clsx([
        "flex",
        "w-full",
        "justify-start",
        // "bg-secondary-50/50",
        "overflow-hidden",
      ])}
    >
      <ul
        className={clsx([
          "gap-6",
          "md:gap-12",
          "flex",
          "md:w-full",
          "overflow-y-auto",
          "w-full",
          "box-content",
          "pb-2",
          "md:pb-0",
        ])}
      >
        {linksList?.map?.(({ text, onClick, active, icon, isLoading }) => {
          return (
            <li
              key={text}
              className={clsx([
                "transition-colors",
                "inline-flex",
                "flex-row",
                "md:flex",
                // "md:flex-col",
                "items-center",
                "gap-1",
                "md:gap-1",
                // "px-3",
                // "md:px-5",
                "pb-1",
                "text-sm",
                "md:text-base",
                "border-b-0",
                "cursor-pointer",
                "relative",
                "after:content",
                "after:absolute after:bottom-0 after:flex after:w-full",
                "after:h-[3px] after:rounded-full",
                {
                  "after:bg-red-400": active && !isLoading,
                  "border-transparent": !active && isLoading,
                  "text-gray-600": !active,
                  "text-red-500": active,
                  "hover:after:bg-red-400": !active,
                  "hover:text-red-500": !active,
                },
                "last:border-l-0",
                // "md:font-light",
                "md:py-2",
                "whitespace-nowrap",
              ])}
              onClick={onClick}
            >
              {isLoading ? (
                <div
                  className={clsx([
                    "h-[32px] md:h-[40px]",
                    "w-[32px] md:w-[40px]",
                    "flex",
                    "items-center",
                    "justify-center",
                  ])}
                >
                  <div
                    className={clsx([
                      "inline-block",
                      "h-[24px]",
                      "w-[24px]",
                      "animate-spin",
                      "rounded-full",
                      "border-4",
                      "border-solid",
                      "border-current",
                      "border-e-transparent",
                      "align-[-0.125em]",
                      "motion-reduce:animate-[spin_1.5s_linear_infinite]",
                    ])}
                  />
                </div>
              ) : (
                icon
              )}
              <span className="font-medium md:font-normal">{text}</span>
            </li>
          );
        }) || ""}
      </ul>
    </div>
  );
};

export default LinksList;
