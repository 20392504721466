import React from "react";
import {
  MdArrowBack,
  MdAttachMoney,
  MdCached,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdCheckCircle,
  MdCompareArrows,
  MdOutlineShoppingCart,
  MdRadioButtonUnchecked,
  MdViewSidebar,
} from "react-icons/md";
import PageTitle from "../../components/PageTitle/PageTitle";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useNavigate } from "react-router-dom";
import userPreferences from "../../store/userPreferences";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  BUTTON_STYLES,
  ICON_SIZES,
  MOBILE_BREAKPOINT_MEDIA_QUERY,
} from "../../helpers/constants";
import { isPwa, showNotification } from "../../helpers/utils";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Button from "../../components/Button/Button";
import historyStore from "../../store/historyStore";
import i18n from "../../i18n";
import useTransactions from "../../hooks/useTransactions";

const AppSettingsPage: React.FC = () => {
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const currentLanguage = i18n.language?.toLowerCase?.() || "en";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { resetCache } = useTransactions();
  const isMobile = useMediaQuery({ query: MOBILE_BREAKPOINT_MEDIA_QUERY });
  const [notificationsAreEnabled, setNotificationsAreEnabled] =
    useState<boolean>(Notification.permission === "granted");

  const {
    rightPanelIsVisible,
    setRightPanelIsVisible,
    blurAmounts,
    setBlurAmounts,
  } = userPreferences();
  const { enableHistory, historyIsEnabled } = historyStore();

  return (
    <>
      <TwoSidebars
        right={
          <>{<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}</>
        }
      >
        <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
          <PageTitle
            title={t("settings.title")}
            subtitle={t("app_settings_page.title")}
            titleLink="/settings"
            isLoading={false}
            rightSide={
              <>
                <ButtonGroup
                  align="right"
                  gap={1}
                  buttons={[
                    {
                      icon: <MdArrowBack size={ICON_SIZES.SM} />,
                      classes: BUTTON_STYLES.HEADING,
                      tooltip: "Atras",
                      onClick: () => {
                        navigate("/settings");
                      },
                      hide: !isMobile,
                    },
                    {
                      icon: <MdOutlineShoppingCart size={ICON_SIZES.SM} />,
                      tooltip: "Añadir gasto",
                      onClick: () => {
                        navigate("/transactions/expense");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdAttachMoney size={ICON_SIZES.SM} />,
                      tooltip: "Añadir ingreso",
                      hide: isMobile,
                      onClick: () => {
                        navigate("/transactions/income");
                      },
                    },
                    {
                      icon: <MdCompareArrows size={ICON_SIZES.SM} />,
                      tooltip: "Transacciones",
                      onClick: () => {
                        navigate("/transactions");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdViewSidebar size={ICON_SIZES.SM} />,
                      tooltip: "Panel lateral",
                      kind: rightPanelIsVisible ? "primary" : "secondary",
                      hide: isMobile,
                      onClick: () => {
                        setRightPanelIsVisible(!rightPanelIsVisible);
                      },
                    },
                  ]}
                />
              </>
            }
          />
          <div className={clsx(["flex", "gap-4", "flex-col"])}>
            <div className={clsx(["flex", "flex-col", "gap-2"])}>
              <SectionTitle title={t("app_settings_page.title")} />
              <div className="grid grid-cols-1 gap-4">
                <div className={clsx(["flex", "flex-col", "gap-2"])}>
                  {notificationsAreEnabled ? (
                    <InfoBlock
                      message={t(
                        "app_settings_page.notifications_section.description_enabled"
                      )}
                      title={t("app_settings_page.notifications_section.title")}
                    />
                  ) : (
                    <Button
                      onClick={async () => {
                        const res = await Notification.requestPermission();
                        setNotificationsAreEnabled(!!res);
                      }}
                      classes="!justify-start"
                      text={t("app_settings_page.buttons.notifications.label")}
                      icon={
                        Notification.permission === "granted" ? (
                          <MdCheckBox
                            size={ICON_SIZES.SM}
                            className="text-green-400"
                          />
                        ) : (
                          <MdCheckBoxOutlineBlank size={ICON_SIZES.SM} />
                        )
                      }
                    />
                  )}
                  <Button
                    onClick={() => setBlurAmounts(!blurAmounts)}
                    classes="!justify-start"
                    text={t("app_settings_page.buttons.privacy_mode.label")}
                    icon={
                      blurAmounts ? (
                        <MdCheckBox
                          size={ICON_SIZES.SM}
                          className="text-green-400"
                        />
                      ) : (
                        <MdCheckBoxOutlineBlank size={ICON_SIZES.SM} />
                      )
                    }
                  />
                  <Button
                    onClick={async () => {
                      enableHistory(!historyIsEnabled);
                    }}
                    text={t("app_settings_page.buttons.history.label")}
                    icon={
                      historyIsEnabled ? (
                        <MdCheckBox
                          size={ICON_SIZES.SM}
                          className="text-green-400"
                        />
                      ) : (
                        <MdCheckBoxOutlineBlank size={ICON_SIZES.SM} />
                      )
                    }
                    classes="!justify-start"
                  />
                  <Button
                    classes="!justify-start"
                    onClick={async () => {
                      if (isPwa()) {
                        if ("clearAppBadge" in navigator) {
                          (navigator as any).clearAppBadge();
                        }
                      }

                      await caches.keys().then(function (cacheNames) {
                        Promise.allSettled(
                          cacheNames.map(async function (cacheName) {
                            return await caches.delete(cacheName);
                          })
                        );
                      });

                      await resetCache();
                      window?.location?.reload?.();

                      showNotification({
                        message: t(
                          "app_settings_page.notifications.cache_cleared"
                        ),
                        kind: "success",
                      });
                    }}
                    text={t("app_settings_page.buttons.cache.label")}
                    icon={
                      <MdCached size={ICON_SIZES.SM} className="text-red-400" />
                    }
                  />
                </div>
              </div>
            </div>
            <div className={clsx(["flex", "flex-col", "gap-2"])}>
              <SectionTitle
                title={t("app_settings_page.language_section.title")}
              />
              <p className="text-sm">
                {t("app_settings_page.language_section.description")}
              </p>
              <div
                className={clsx([
                  "grid",
                  "lg:grid-cols-3",
                  "grid-cols-2",
                  "md:grid-cols-2",
                  "gap-2",
                ])}
              >
                {[
                  {
                    text: t("app_settings_page.language_section.english"),
                    code: "en",
                    icon: (
                      <span className="text-2xl rounded-sm  md:text-3xl fi fi-us"></span>
                    ),
                    onClick: () => changeLanguage("en"),
                  },
                  {
                    text: t("app_settings_page.language_section.spanish"),
                    code: "es",
                    icon: (
                      <span className="text-2xl rounded-sm  md:text-3xl fi fi-es"></span>
                    ),
                    onClick: () => changeLanguage("es"),
                  },
                ].map(({ text, icon, onClick, code }, key) => (
                  <div
                    key={key}
                    className={clsx([
                      "transition-colors",
                      "flex",
                      "flex-row",
                      "lg:flex-row",
                      "items-center",
                      "gap-2",
                      "md:gap-2",
                      "py-2",
                      "px-2",
                      "md:px-3",
                      "md:py-4",
                      "rounded-md md:rounded-md",
                      "cursor-pointer",
                    ])}
                    onClick={() => {
                      if (onClick) {
                        onClick();
                      }
                    }}
                  >
                    {code === currentLanguage ? (
                      <MdCheckCircle
                        size={ICON_SIZES.SM}
                        className="text-green-400"
                      />
                    ) : (
                      <MdRadioButtonUnchecked
                        size={ICON_SIZES.SM}
                        className="text-gray-400"
                      />
                    )}
                    <div>{icon}</div>
                    <div
                      className={clsx([
                        "flex",
                        "flex-col",
                        "items-center",
                        "lg:items-start",
                        "justify-center",
                        "lg:justify-start",
                      ])}
                    >
                      <div
                        className={clsx([
                          "md:font-medium",
                          "leading-none",
                          "md:leading-normal",
                          "w-full",
                          "text-center md:text-left",
                          "text-sm md:text-base",
                        ])}
                      >
                        {text}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </TwoSidebars>
    </>
  );
};

export default AppSettingsPage;
