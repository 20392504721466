import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllInvites } from "../api/invites";
import { getAcceptedInvites } from "../helpers/utils";
import { APP_USER_ID_KEY } from "../helpers/constants";

export default function useInvites() {
  const queryClient = useQueryClient();
  const currentUserId = localStorage?.getItem?.(APP_USER_ID_KEY) || "";

  const invites = useQuery({
    queryFn: async () => await getAllInvites(),
    queryKey: ["all-invites"],
  });

  const resetCache = async () => {
    return await queryClient.refetchQueries({ queryKey: ["all-invites"] });
  };

  const acceptedInvites = getAcceptedInvites({
    invites: invites?.data?.data?.data || [],
    user: currentUserId,
  });

  console.log(acceptedInvites);

  return {
    allInviteList: invites,
    resetCache,
    acceptedInvites: acceptedInvites,
  };
}
