import clsx from "clsx";
import InvoicesChart from "../../components/InvoicesChart/InvoicesChart";
import TransactionsByType from "./TransactionsByType";
import TransactionsByCategory from "./TransactionsByCategory";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Select from "../../components/Select/Select";
import MonthlySummaryTable from "../../components/InvoicesChart/MonthlySummaryTable";
import { EXPENSE_TYPE, INCOME_TYPE } from "../../helpers/constants";
import _ from "lodash";
import { getCurrencyDataByCode } from "../../helpers/utils";
import useAccountsStore from "../../store/accountsStore";
import TransactionsByPaymentMethod from "./TransactionsByPaymentMethod";

interface ITransactionCharts {
  transactions: Array<any>;
  chart?: number;
}

export const TRANSACTIONS_BY_TYPE_CHART = 0;
export const TRANSACTIONS_BY_CATEGORY_CHART = 1;
export const TRANSACTIONS_BY_PAYMENT_METHOD_CHART = 2;
export const TRANSACTIONS_PER_MONTH_CHART = 3;
export const TRANSACTIONS_PER_CLIENT_PROVIDER_CHART = 4;

const TransactionCharts: React.FC<ITransactionCharts> = ({
  transactions,
  chart,
}) => {
  const { t } = useTranslation();
  const [years, setYears] = useState<Array<any>>([]);
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [selectedCurrency, setSelectedCurrency] = useState<string>("");
  const [transactionsPerYear, setTransactionsPerYear] = useState<Array<any>>(
    []
  );
  const { getAccountById } = useAccountsStore();

  useEffect(() => {
    if (transactions?.length) {
      const unique = transactions
        .map((yearDate) => new Date(yearDate.date).getFullYear())
        .filter((year, index, arr) => arr.indexOf(year) === index);
      if (unique?.length) {
        setYears(unique);
        setSelectedYear(`${unique[0]}`);
      } else {
        setYears([]);
        setSelectedYear("");
      }
    } else {
      setYears([]);
    }
  }, [transactions]);

  useEffect(() => {
    if (transactions?.length) {
      if (!selectedYear) {
        setTransactionsPerYear(transactions);
      } else {
        setTransactionsPerYear(
          transactions.filter?.(
            (transaction) =>
              new Date(transaction.date).getFullYear() === Number(selectedYear)
          )
        );
      }

      setSelectedCurrency(availableCurrencies()?.[0]?.value);
    } else {
      setTransactionsPerYear([]);
    }
  }, [selectedYear]);

  const accountsCurrencies = transactionsPerYear?.reduce?.(
    (acc: any, current: any) => {
      const accountData = getAccountById(current.account);

      if (accountData) {
        const currencyIndex = _.findIndex(acc, { code: accountData.currency });

        if (currencyIndex !== -1) {
          if (current.type === INCOME_TYPE) {
            acc[currencyIndex].income =
              Number(acc[currencyIndex].income) + Number(current.total);
          } else if (current.type === EXPENSE_TYPE) {
            acc[currencyIndex].expenses =
              Number(acc[currencyIndex].expenses) + Number(current.total);
          }
          acc[currencyIndex].accounts = Array.from(
            new Set([...acc[currencyIndex].accounts, current.account])
          );
        } else {
          acc.push({
            code: accountData.currency,
            name: getCurrencyDataByCode({ code: accountData.currency })?.name,
            symbol: getCurrencyDataByCode({ code: accountData.currency })
              ?.symbol,
            income: current.type === INCOME_TYPE ? Number(current.total) : 0,
            expenses: current.type === EXPENSE_TYPE ? Number(current.total) : 0,
            accounts: [current.account],
          });
        }
      }

      return acc;
    },
    []
  );

  const availableCurrencies = () => {
    return (
      accountsCurrencies?.map?.((currency: any) => ({
        value: currency.code,
        label:
          getCurrencyDataByCode({ code: currency.code })?.name ||
          "Moneda desconocida",
      })) || []
    );
  };

  const getAccountsUsingCurrency =
    _.find(accountsCurrencies, { code: selectedCurrency })?.accounts || [];

  const finalTransactions = () =>
    transactionsPerYear?.filter?.((transaction) =>
      getAccountsUsingCurrency?.includes?.(transaction.account)
    );

  return (
    <div className={clsx(["flex", "flex-col", "gap-6", "w-full"])}>
      {years?.length > 1 && (
        <>
          <div className={clsx(["w-full", "grid", "grid-cols-2", "gap-4"])}>
            <Select
              label={t("shared.year")}
              options={years?.map((year) => {
                return {
                  label: year,
                  value: year,
                };
              })}
              value={selectedYear}
              onChange={(e: any) => {
                setSelectedYear(e.target.value);
              }}
              emptyOption={{
                label: t("shared.all_years"),
                value: "",
                selected: !selectedYear,
                show: true,
              }}
            />
            <Select
              options={availableCurrencies()}
              label={t("shared.currency")}
              value={selectedCurrency}
              emptyOption={{ show: false }}
              onChange={(e: any) => setSelectedCurrency(e?.target?.value)}
            />
          </div>
        </>
      )}
      <>
        {chart === TRANSACTIONS_BY_TYPE_CHART && (
          <div className={clsx(["flex", "flex-col", "gap-4"])}>
            <div>
              <h2 className={clsx(["text-lg", "text-gray-800"])}>
                {t("charts.transactions_by_type.title")}
              </h2>
              <p>{t("charts.transactions_by_type.description")}</p>
            </div>
            <TransactionsByType transactions={finalTransactions()} />
          </div>
        )}

        {chart === TRANSACTIONS_BY_PAYMENT_METHOD_CHART && (
          <div className={clsx(["flex", "flex-col", "gap-4"])}>
            <div>
              <h2 className={clsx(["text-lg", "text-gray-800"])}>
                {t("charts.transactions_by_category.title")}
              </h2>
              <p>{t("charts.transactions_by_category.description")}</p>
            </div>
            <TransactionsByPaymentMethod transactions={finalTransactions()} />
          </div>
        )}

        {chart === TRANSACTIONS_BY_CATEGORY_CHART && (
          <div className={clsx(["flex", "flex-col", "gap-4"])}>
            <div>
              <h2 className={clsx(["text-lg", "text-gray-800"])}>
                {t("charts.transactions_by_category.title")}
              </h2>
              <p>{t("charts.transactions_by_category.description")}</p>
            </div>
            <TransactionsByCategory invoices={finalTransactions()} />
          </div>
        )}

        {chart === TRANSACTIONS_PER_MONTH_CHART && (
          <div className={clsx(["flex", "flex-col", "gap-4"])}>
            <div>
              <h2 className={clsx(["text-lg", "text-gray-800"])}>
                {t("charts.months.title")}
              </h2>
              <p>{t("charts.months.description")}</p>
            </div>

            <InvoicesChart invoices={finalTransactions()} />
          </div>
        )}
      </>
    </div>
  );
};

export default TransactionCharts;
