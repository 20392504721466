import clsx from "clsx";
import { ReactElement, useId } from "react";
import { useTranslation } from "react-i18next";

interface ISelectOption {
  value: string;
  label: string;
}

interface ISelect {
  options: Array<ISelectOption>;
  placeholder?: string;
  label?: string | ReactElement;
  id?: string;
  required?: boolean;
  value?: string;
  onChange: (e: any) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  emptyOption?: any;
  form?: string;
  autoComplete?: string;
}

const Select: React.FC<ISelect> = ({
  options = [],
  label,
  placeholder,
  id,
  required,
  value = "",
  onChange,
  disabled = false,
  autoFocus = false,
  emptyOption = {
    label: null,
    value: "",
    selected: true,
    show: true,
  },
  form = "",
  autoComplete,
}) => {
  const selectAutoId = useId();
  const { t } = useTranslation();

  const selectOptions = [
    ...(emptyOption?.show || emptyOption?.show === undefined
      ? [
          {
            ...emptyOption,
            label:
              emptyOption?.label || `-- ${t("shared.select_placeholder")} --`,
          },
        ]
      : []),
    ...options,
  ];

  // hola

  return (
    <div className="flex flex-col w-full">
      {label && (
        <label
          htmlFor={id || selectAutoId}
          className={clsx([
            "text-sm",
            "md:text-base",
            "text-gray-700",
            "font-medium",
          ])}
        >
          {label}
          {required && (
            <>
              {value ? (
                <span className="ml-1 text-red-500">*</span>
              ) : (
                <span className="ml-1 text-red-500">*</span>
              )}
            </>
          )}
        </label>
      )}
      <select
        // x-webkit-speech
        autoComplete={autoComplete}
        id={id || selectAutoId}
        required={required}
        autoFocus={autoFocus}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={clsx([
          "border-solid",
          "border-b-2",
          "text-sm",
          "md:text-base",
          "rounded-sm",
          "leading-none",
          "h-[40px] md:h-[46px]",
          // "focus:border-gray-500",
          "font-light",
          "placeholder:text-sm",
          "placeholder:font-light",
          "bg-white",
          "px-1 md:px-2",

          {
            "border-red-300": required && !value,
            "border-gray-400": required && value,
            "border-gray-300": !required,
            "placeholder:text-gray-500": !required,
            "placeholder:text-red-500": required,
          },
        ])}
        {...(form ? { form } : {})}
      >
        {selectOptions.map(({ value, label }, key) => (
          <option value={value} key={key} className="p-2">
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
