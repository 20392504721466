import {
  TRANSACTION_STATUS_PAID,
  TRANSACTION_STATUS_PENDING,
  TRANSACTION_STATUS_VOID,
} from "../constants/transaction-status";
import { PAYMENT_METHODS, TRANSACTION_STATUSES } from "./constants";

export const getPaymentMethodName = (paymentMethod: string) => {
  const method = PAYMENT_METHODS()?.find?.(({ id }) => id === paymentMethod);

  return method?.name || "";
};

export const transactionStatus = {
  getById: (statusId: string) => {
    return TRANSACTION_STATUSES()?.find?.(({ id }) => id === statusId);
  },
  getNameById: (statusId: string) => {
    const status = transactionStatus.getById(statusId);

    return status?.name;
  },
  getIdByName: (statusName: string) => {
    const status = TRANSACTION_STATUSES()?.find?.(
      ({ name }) => name === statusName
    );

    return status?.id;
  },
  isPaid: (statusId: string) => {
    return statusId === TRANSACTION_STATUS_PAID;
  },
  isPending: (statusId: string) => {
    return statusId === TRANSACTION_STATUS_PENDING;
  },
  isVoid: (statusId: string) => {
    return statusId === TRANSACTION_STATUS_VOID;
  },
};
