import { ReactNode } from "react";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
import clsx from "clsx";
import ModalHeaderSearch from "./ModalHeaderSearch";

type ModalType = {
  children: ReactNode;
  width?: string;
  className?: string;
  onEscape: () => void;
};

type ModalSubcomponents = {
  Header: typeof ModalHeader;
  HeaderWithSearch: typeof ModalHeaderSearch;
  Body: typeof ModalBody;
  Footer: typeof ModalFooter;
};

const Modal: React.FC<ModalType> & ModalSubcomponents = ({
  children,
  width = "w-auto",
  className = "",
  onEscape = () => {},
}) => {
  return (
    <div
      className={["z-50"].join(" ")}
      onClick={(e: any) => e?.stopPropagation?.()}
      onKeyDown={({ keyCode }) => {
        if (keyCode === 27) {
          onEscape?.();
        }
      }}
    >
      <div
        className={[
          "fixed",
          "top-0",
          "bottom-0",
          "right-0",
          "left-0",
          "bg-gray-800/40",
          "z-50",
        ].join(" ")}
      />
      <div
        className={clsx([
          "bg-white",
          width,
          "fixed",
          "top-1/2",
          "-translate-y-1/2",
          "left-1/2",
          "-translate-x-1/2",
          "transform-gpu",
          "overflow-scroll",
          "rounded-md md:rounded-xl",
          "w-full md:w-auto",
          "max-w-[calc(100vw-32px)] md:max-w-[95vw]",
          "max-h-[100vh-32px] md:max-h-[95vh] xl:max-h-[98vh]",
          className,
          "z-50",
          "shadow-sm",
        ])}
      >
        {children}
      </div>
    </div>
  );
};

Modal.Header = ModalHeader;
Modal.HeaderWithSearch = ModalHeaderSearch;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
