import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { APP_TOKEN_KEY } from "../../helpers/constants";
import StoreLoaders from "../StoreLoaders/StoreLoaders";

const ProtectedRoutes: React.FC = () => {
  const token = localStorage.getItem(APP_TOKEN_KEY);
  const location = useLocation();

  return token ? (
    <>
      <StoreLoaders />
      <Outlet />
    </>
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default ProtectedRoutes;
