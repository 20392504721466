import clsx from "clsx";
import { IButton } from "../../components/Button/Button";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";

interface IButtonGroupCell {
  buttons: Array<IButton>;
}

const ButtonGroupCell: React.FC<IButtonGroupCell> = ({ buttons }) => {
  return (
    <div
      className={clsx([
        "inline-flex",
        "whitespace-nowrap",
        "rounded-full",
        "py-1",
        "px-2",
        "shrink-0",
        "flex-grow",
      ])}
    >
      <ButtonGroup gap={1} buttons={buttons} />
    </div>
  );
};

export default ButtonGroupCell;
