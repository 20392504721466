import { ALL_USER_ICONS } from "../../helpers/user-icons";

interface IRenderIcon {
  name: string;
  mobileSize?: number;
  desktopSize?: number;
}
const RenderIcon: React.FC<IRenderIcon> = ({
  name,
  mobileSize = 24,
  desktopSize = 48,
}) => {
  const Icon = ALL_USER_ICONS.find(
    ({ name: iconName }) => name === iconName
  )?.icon;

  if (Icon) {
    return (
      <>
        <Icon size={desktopSize} className="hidden md:flex" />
        <Icon size={mobileSize} className="md:hidden" />
      </>
    );
  }

  return <></>;
};

export default RenderIcon;
