import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import { Line } from "react-chartjs-2";
import {
  CHART_COLORS,
  EXPENSE_TYPE,
  INCOME_TYPE,
} from "../../helpers/constants";
import i18next from "i18next";
import clsx from "clsx";
import MediaQuery from "react-responsive";
import MonthlySummaryTable from "./MonthlySummaryTable";
import { getColorFromAccountSettings } from "../../helpers/accounts";

const options = {
  responsive: true,
  pointRadius: 15,
  pointHoverRadius: 30,
  borderColor: "#444",
  borderWidth: 1,
  maintainAspectRatio: false,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  elements: {
    line: {
      tension: 0.5,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IInvoicesChart {
  invoices?: Array<any>;
}

const mobileOptions = {
  ...options,
  pointRadius: 15,
  pointHoverRadius: 30,
};

const InvoicesChart: React.FC<IInvoicesChart> = ({ invoices = [] }) => {
  const currentLanguage = i18next.language?.toLowerCase?.() || "en";
  const accounts = Array.from(
    new Set(invoices?.map?.(({ account }) => account) || [])
  )?.filter?.((account) => account);

  const labels = Array.from(
    new Set([
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ])
  )?.sort();

  const datasets = () => {
    const res = [INCOME_TYPE, EXPENSE_TYPE].map((transactionType) => ({
      label: transactionType === INCOME_TYPE ? "Ingresos" : "Gastos",
      backgroundColor:
        transactionType === INCOME_TYPE
          ? getColorFromAccountSettings({ settings: CHART_COLORS.INCOME })
              .background
          : getColorFromAccountSettings({ settings: CHART_COLORS.EXPENSE })
              .background,
      data: labels?.map?.((label: any) => {
        const filteredInvoices =
          invoices?.filter?.(({ date, account: theAccount, type }) => {
            const month = moment(date).format("MM");

            return month === label && type === transactionType;
          }) || [];

        let total = 0;

        filteredInvoices?.forEach?.(({ total: theTotal }) => {
          if (transactionType === INCOME_TYPE) {
            total += Number(theTotal);
          } else {
            total -= Number(theTotal);
          }
        });

        return total;
      }),
    }));

    const [INCOME, EXPENSE] = res;

    return [
      INCOME,
      EXPENSE,
      {
        label: "Balance",
        backgroundColor: getColorFromAccountSettings({
          settings: CHART_COLORS.BALANCE,
        }).background,
        data: INCOME.data?.map?.((income, index) => {
          return Number(income) + Number(EXPENSE.data[index]);
        }),
      },
    ];
  };

  const [income, expenses, balance] = datasets();

  return (
    <div className={clsx("flex", "flex-col-reverse", "gap-4")}>
      {accounts?.length > 0 && (
        <div className={clsx(["flex", "overflow-auto", "w-full"])}>
          <MonthlySummaryTable transactions={datasets()} />
        </div>
      )}
      <div>
        <MediaQuery maxWidth={767}>
          <Line
            options={mobileOptions}
            height={230}
            data={{
              datasets: datasets(),
              labels: labels.map(
                (label) =>
                  `${moment(label, "MM")
                    .locale(currentLanguage)
                    .format("MMMM")}`
              ),
            }}
          />
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <Line
            options={options}
            height={250}
            data={{
              datasets: datasets(),
              labels: labels.map(
                (label) =>
                  `${moment(label, "MM")
                    .locale(currentLanguage)
                    .format("MMMM")}`
              ),
            }}
          />
        </MediaQuery>
      </div>
    </div>
  );
};

export default InvoicesChart;
