import clsx from "clsx";

import {
  MdArrowBack,
  MdArrowDownward,
  MdArrowUpward,
  MdAttachMoney,
} from "react-icons/md";
import invoicesStore from "../../store/invoicesStore";
import Button from "../../components/Button/Button";
import CountUp from "react-countup";
import Slider from "react-slick";

import { EXPENSE_TYPE, ICON_SIZES, INCOME_TYPE } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import transactionsStore from "../../store/transactionsStore";
import { useEffect, useState } from "react";
import useAccountsStore from "../../store/accountsStore";
import { getCurrencyDataByCode, showNotification } from "../../helpers/utils";
import _ from "lodash";
import SliderArrowRight from "../../components/Slider/SliderArrowRight";
import SliderArrowLeft from "../../components/Slider/SliderArrowLeft";
import userPreferences from "../../store/userPreferences";

const LOADING_TEXT = "...";

interface ITransactionsSummary {
  transactions: Array<any>;
  onIncomeClick?: () => void;
  onExpenseClick?: () => void;
  onTotalClick?: () => void;
  selected?: "INCOME" | "EXPENSE" | any;
  layout?: "vertical" | "horizontal";
  isLoading?: boolean;
  onUpdateNeeded: () => void;
}

const TransactionsSummary: React.FC<ITransactionsSummary> = ({
  transactions,
  onIncomeClick = () => {},
  onExpenseClick = () => {},
  onTotalClick = () => {},
  selected = "",
  layout = "vertical",
  isLoading = true,
  onUpdateNeeded,
}) => {
  const { t } = useTranslation();
  const { blurAmounts } = userPreferences();

  const [selectedCurrency, setSelectedCurrency] = useState<string>("");
  const [selectedAccountSlide, setSelectedAccountSlide] = useState<number>(0);

  const { getAccountById } = useAccountsStore();
  const { isSearchingTransactions } = invoicesStore();
  const { updateTransaction } = transactionsStore();

  const getTotal = ({ type }: { type: "EXPENSE" | "INCOME" }) => {
    let total = 0;
    transactions
      ?.filter?.(({ type: documentType }) => documentType === type)
      ?.forEach?.(({ total: theTotal }) => {
        total += Number(theTotal);
      });

    return total;
  };
  const accountsCurrencies = transactions?.reduce?.((acc, current) => {
    const accountData = getAccountById(current.account);

    if (accountData) {
      const currencyIndex = _.findIndex(acc, { code: accountData.currency });

      if (currencyIndex !== -1) {
        if (current.type === INCOME_TYPE) {
          acc[currencyIndex].income =
            Number(acc[currencyIndex].income) + Number(current.total);
        } else if (current.type === EXPENSE_TYPE) {
          acc[currencyIndex].expenses =
            Number(acc[currencyIndex].expenses) + Number(current.total);
        }
      } else {
        acc.push({
          code: accountData.currency,
          name: getCurrencyDataByCode({ code: accountData.currency })?.name,
          symbol: getCurrencyDataByCode({ code: accountData.currency })?.symbol,
          income: current.type === INCOME_TYPE ? Number(current.total) : 0,
          expenses: current.type === EXPENSE_TYPE ? Number(current.total) : 0,
        });
      }
    }

    return acc;
  }, []);

  const availableCurrencies = () => {
    return (
      accountsCurrencies?.map?.((currency: any) => ({
        value: currency.code,
        label:
          getCurrencyDataByCode({ code: currency.code })?.name ||
          "Moneda desconocida",
      })) || []
    );
  };

  const totalIncome = getTotal({ type: INCOME_TYPE });
  const totalExpenses = getTotal({ type: EXPENSE_TYPE });
  const total = totalIncome - totalExpenses;

  useEffect(() => {
    if (accountsCurrencies?.length) {
      const [first] = accountsCurrencies;
      setSelectedCurrency(first.code);
    } else {
      setSelectedCurrency("");
    }
  }, [accountsCurrencies]);

  const settings = {
    arrows: false,
    beforeChange: (_prev: number, next: number) => {
      setSelectedCurrency(availableCurrencies()[next].value);
      setSelectedAccountSlide(next);
    },
    nextArrow: <SliderArrowRight />,
    prevArrow: <SliderArrowLeft />,
    responsive: [
      ...(availableCurrencies()?.length <= 1
        ? [
            {
              breakpoint: 100000,
              settings: "unslick" as "unslick",
            },
          ]
        : [
            {
              breakpoint: 100000,
              settings: {
                infinite: true,
                speed: 500,
                dots: true,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]),
    ],
  };

  const nada = () => {
    const res = [];

    if (selected === INCOME_TYPE || !selected) {
      res.push({
        key: INCOME_TYPE,
        text: t("summary.incomes"),
        value: totalIncome,
        icon: (
          <>
            <MdArrowDownward
              size={48}
              className={clsx(["text-green-400", "hidden", "md:block"])}
            />
            <MdArrowDownward
              size={48}
              className={clsx(["text-white", "md:hidden"])}
            />
          </>
        ),
        onClick: onIncomeClick,
      });
    }
    if (selected === EXPENSE_TYPE || !selected) {
      res.push({
        key: EXPENSE_TYPE,
        text: t("summary.expenses"),
        value: totalExpenses,
        icon: (
          <>
            <MdArrowUpward
              size={48}
              className={clsx(["text-red-400", "hidden", "md:block"])}
            />
            <MdArrowUpward
              size={48}
              className={clsx(["text-white", "md:hidden"])}
            />
          </>
        ),
        onClick: onExpenseClick,
      });
    }
    // if (!selected) {
    //   res.push({
    //     key: "",
    //     text: t("summary.balance"),
    //     value: total,
    //     icon: (
    //       <>
    //         <MdAttachMoney
    //           size={48}
    //           className={clsx(["text-gray-400", "hidden", "md:block"])}
    //         />
    //         <MdAttachMoney
    //           size={38}
    //           className={clsx(["text-gray-400", "md:hidden"])}
    //         />
    //       </>
    //     ),
    //     onClick: onTotalClick,
    //   });
    // }

    return res;
  };

  const data = nada();

  return (
    <div className={clsx("flex", "gap-4", "flex-col", "w-full")}>
      {/* {selectedCurrency &&
        availableCurrencies?.()?.[selectedAccountSlide]?.label && (
          <div
            className={clsx([
              "flex",
              "w-full",
              "items-center",
              "md:items-start",
              "justify-center",
              "md:justify-start",
            ])}
          >
            <div
              className={clsx([
                "inline-flex",
                "bg-gray-500",
                "text-white",
                "rounded-md",
                "text-xs",
                "font-medium",
                "px-2",
                "py-1",
              ])}
            >
              {availableCurrencies?.()?.[selectedAccountSlide]?.label}
            </div>
          </div>
        )} */}
      <div>
        <Slider {...settings}>
          {accountsCurrencies.map?.((accountCurrency: any) => {
            return (
              <div
                className={clsx([
                  "!grid",
                  "w-full",
                  "gap-2",
                  {
                    "md:grid-cols-1": layout === "vertical",
                    "lg:grid-cols-3": layout === "horizontal",
                    "grid-cols-2": true,
                    // "grid-cols-3": !selected,
                    "rounded-md md:rounded-lg": layout === "vertical",
                  },
                  // "overflow-hidden",
                ])}
                key={accountCurrency.code}
              >
                {data.map((block: any, index) => (
                  <div
                    className={clsx([
                      "flex",
                      "flex-col",
                      "md:flex-row",
                      "items-center",
                      "justify-start",
                      "transition-colors",
                      "md:gap-1",
                      "gap-1",
                      "shadow-sm",
                      "md:bg-white",
                      {
                        "bg-green-400/100": INCOME_TYPE === block?.key,
                        "bg-red-400/100": EXPENSE_TYPE === block?.key,
                        "mdLhover:bg-gray-200": !!block?.key,
                        "cursor-pointer": block?.key,
                        "blur-sm md:hover:blur-none":
                          (block?.key === INCOME_TYPE && blurAmounts) ||
                          (block?.key === "" && blurAmounts),
                      },
                      {
                        "lg:px-2": layout === "vertical",
                        "py-2 lg:py-2": layout === "vertical",
                        "py-2 lg:p-4": layout === "horizontal",
                        // "rounded-lg": layout === "horizontal",
                        "bg-white": layout === "vertical",
                      },
                      "rounded-md md:rounded-lg",
                      "text-white md:text-gray-300",
                    ])}
                    key={index}
                    onClick={() => {
                      if (block?.key) {
                        block?.onClick?.();
                      }
                    }}
                    onDragOver={(e) => {
                      e?.preventDefault?.();
                    }}
                    onDrop={async (e) => {
                      if (!block.key) return false;

                      const data = e?.dataTransfer.getData("transaction");
                      if (data) {
                        const parsedData = JSON.parse(data);

                        if (parsedData.contentType === "transaction") {
                          const { id, type } = parsedData;

                          if (type === block.key) {
                            toast.info("Ya es del mismo tipo de transacción");
                          } else {
                            try {
                              const result: any = await updateTransaction({
                                transactionId: id,
                                params: {
                                  type: block.key,
                                },
                              });

                              if (result.success) {
                                showNotification({
                                  message:
                                    "Tipo de transacción actualizado correctamente",
                                  kind: "success",
                                });

                                onUpdateNeeded();
                              } else {
                                toast.error(
                                  "Error cambiando el tipo de transacción"
                                );
                              }
                            } catch (e) {
                              toast.error(
                                "Error cambiando el tipo de transacción"
                              );
                            }
                          }
                        }
                      }
                    }}
                  >
                    {block.icon}
                    <div className={clsx(["flex", "flex-col", "items-start"])}>
                      <div
                        className={clsx(
                          "text-sm",
                          "md:text-base",
                          "text-center",
                          "md:text-left",
                          "w-full",
                          "md:w-auto",
                          {
                            "font-light": block?.key !== "back",
                          },
                          "text-white md:text-gray-900"
                        )}
                      >
                        {block.text}
                      </div>
                      <div
                        className={clsx([
                          "text-sm",
                          "md:text-base",
                          "font-medium",
                          "text-white md:text-gray-900",
                          "w-full",
                          "text-center",
                          "md:text-left",
                          "md:w-auto",
                        ])}
                      >
                        {isLoading ? (
                          LOADING_TEXT
                        ) : ["back"].includes(block.key) ? (
                          block.value
                        ) : (
                          <>
                            {accountCurrency.symbol}
                            {block.key === INCOME_TYPE && (
                              <CountUp
                                end={accountCurrency.income}
                                decimalPlaces={2}
                                // duration={5}
                                preserveValue
                              />
                            )}

                            {block.key === EXPENSE_TYPE && (
                              <CountUp
                                end={accountCurrency.expenses}
                                decimalPlaces={2}
                                // duration={5}
                                preserveValue
                              />
                            )}

                            {!block.key && (
                              <CountUp
                                end={
                                  Number(accountCurrency.income) -
                                  Number(accountCurrency.expenses)
                                }
                                decimalPlaces={2}
                                // duration={5}
                                preserveValue
                              />
                            )}
                          </>
                          // <CountUp
                          //   end={block.value}
                          //   decimalPlaces={2}
                          //   // duration={5}
                          //   preserveValue
                          // />
                          // formatNumber(block.value)
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {selected && (
                  <div
                    className={clsx([
                      "flex",
                      "items-center",
                      "justify-end",
                      "p-3",
                    ])}
                  >
                    <Button
                      icon={<MdArrowBack size={ICON_SIZES.SM} />}
                      onClick={() => onTotalClick()}
                      tooltip="Ver todas las transacciones"
                      text={t("shared.view_all")}
                      disabled={isSearchingTransactions}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default TransactionsSummary;
