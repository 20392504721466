import clsx from "clsx";
import { useTranslation } from "react-i18next";
import {
  MdAccountBox,
  MdAppSettingsAlt,
  MdCategory,
  MdCompareArrows,
  MdCreditCard,
  MdOutlineCreditCard,
  MdPassword,
  MdPersonSearch,
} from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";

interface ISettingsBlocks {
  layout?: "horizontal" | "vertical";
  columns?: number;
}

const SettingsBlocks: React.FC<ISettingsBlocks> = ({
  layout = "horizontal",
  columns = 1,
}) => {
  const { t } = useTranslation();

  const location = useLocation();

  const BLOCKS = [
    {
      text: t("settings.accounts_title"),
      description: t("settings.accounts_description"),
      icon: (
        <MdOutlineCreditCard
          size={layout === "vertical" ? 32 : 48}
          className={clsx([
            "group-hover:text-red-500",
            {
              "text-red-500": location.pathname === "/settings/accounts",
              "text-blue-500": location.pathname !== "/settings/accounts",
            },
          ])}
        />
      ),
      link: "/settings/accounts",
      active: location.pathname === "/settings/accounts",
    },
    {
      text: "Colaboración",
      description: "Intercambia transacciones con otros usuarios",
      icon: (
        <MdPersonSearch
          size={layout === "vertical" ? 32 : 48}
          className={clsx([
            "group-hover:text-red-500",
            {
              "text-red-500": location.pathname === "/settings/network",
              "text-blue-900": location.pathname !== "/settings/network",
            },
          ])}
        />
      ),
      link: "/settings/network",
      active: location.pathname === "/settings/network",
    },
    {
      text: t("settings.categories_title"),
      description: t("settings.categories_description"),
      icon: (
        <MdCategory
          size={layout === "vertical" ? 32 : 48}
          className={clsx([
            "group-hover:text-red-500",
            {
              "text-red-500": location.pathname === "/settings/categories",
              "text-yellow-500": location.pathname !== "/settings/categories",
            },
          ])}
        />
      ),
      link: "/settings/categories",
      active: location.pathname === "/settings/categories",
    },
    {
      text: t("settings.payment_methods_title"),
      description: t("settings.payment_methods_description"),
      icon: (
        <MdCreditCard
          size={layout === "vertical" ? 32 : 48}
          className={clsx([
            "group-hover:text-red-500",
            {
              "text-red-500": location.pathname === "/settings/payment-methods",
              "text-orange-500":
                location.pathname !== "/settings/payment-methods",
            },
          ])}
        />
      ),
      link: "/settings/payment-methods",
      active: location.pathname === "/settings/payment-methods",
    },

    {
      text: t("settings.transactions_title"),
      description: t("settings.transactions_description"),
      icon: (
        <MdCompareArrows
          size={layout === "vertical" ? 32 : 48}
          className={clsx([
            "group-hover:text-red-500",
            {
              "text-red-500": location.pathname === "/settings/transactions",
              "text-lime-500": location.pathname !== "/settings/transactions",
            },
          ])}
        />
      ),
      link: "/settings/transactions",
      active: location.pathname === "/settings/transactions",
    },

    {
      text: t("settings.profile_title"),
      description: t("settings.profile_description"),
      icon: (
        <MdAccountBox
          size={layout === "vertical" ? 32 : 48}
          className={clsx([
            "group-hover:text-red-500",
            {
              "text-red-500": location.pathname === "/settings/profile",
              "text-green-500": location.pathname !== "/settings/profile",
            },
          ])}
        />
      ),
      link: "/settings/profile",
      hide: location.pathname === "/settings/profile",
      active: location.pathname === "/settings/profile",
    },

    {
      text: t("settings.password_title"),
      description: t("settings.password_description"),
      icon: (
        <MdPassword
          size={layout === "vertical" ? 32 : 48}
          className={clsx([
            "group-hover:text-red-500",
            {
              "text-red-500": location.pathname === "/settings/password",
              "text-purple-500": location.pathname !== "/settings/password",
            },
          ])}
        />
      ),
      link: "/settings/password",
      hide: location.pathname === "/settings/password",
      active: location.pathname === "/settings/password",
    },

    {
      text: t("settings.app_title"),
      description: t("settings.app_description"),
      icon: (
        <MdAppSettingsAlt
          size={layout === "vertical" ? 32 : 48}
          className={clsx([
            "group-hover:text-red-500",
            {
              "text-red-500": location.pathname === "/settings/app",
              "text-pink-400": location.pathname !== "/settings/app",
            },
          ])}
        />
      ),
      link: "/settings/app",
      hide: location.pathname === "/settings/app",
      active: location.pathname === "/settings/app",
    },

    // {
    //   text: t("settings.currency_exchange_title"),
    //   description: t("settings.currency_exchange_description"),
    //   icon: (
    //     <MdCurrencyExchange
    //       size={layout === "vertical" ? 32 :48}
    //       className={clsx([
    //         "group-hover:text-red-500",
    //         {
    //           "text-red-500": location.pathname === "/settings/exchange-rate",
    //         },
    //       ])}
    //     />
    //   ),
    //   link: "/settings/language",
    // },
    // {
    //   text: "Usuarios de confianza",
    //   description: "Comparte con otros usuarios transacciones y acceso a cuentas",
    //   icon: (
    //     <MdSupervisorAccount
    //       size={layout === "vertical" ? 32 :48}
    //       className="group-hover:text-red-500"
    //     />
    //   ),
    //   link: "/settings/share",
    // },
    // {
    //   text: "Branding",
    //   description: "Añade tu marca",
    //   icon: (
    //     <MdOutlineBrandingWatermark
    //       size={layout === "vertical" ? 32 :48}
    //       className="group-hover:text-red-500"
    //     />
    //   ),
    //   link: "/settings/branding",
    // },
    // {
    //   text: "Respaldos",
    //   description: "Descarga una copia de tus datos",
    //   icon: (
    //     <MdBackup size={layout === "vertical" ? 32 :48} className="group-hover:text-red-500" />
    //   ),
    //   link: "/settings/backup",
    // },
  ];

  return (
    <div
      className={clsx([
        "flex",
        "flex-col",
        "gap-4",
        {
          "mt-4": layout === "vertical",
        },
      ])}
    >
      <SectionTitle title={t("settings.config_options")} />
      <ul
        className={clsx([
          "grid",
          {
            "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-5":
              layout === "horizontal",
            [`grid-cols-${columns}`]: layout === "vertical",
          },
          "gap-4 md:gap-2",
        ])}
      >
        {BLOCKS.map?.((block: any, key) => {
          return (
            <Link
              key={key}
              className={clsx([
                "flex",
                {
                  "flex-row md:flex-col": layout === "horizontal",
                  "flex-col": layout === "vertical",
                  "md:py-6": layout === "horizontal",
                },
                "px-0 py-0 md:p-4",
                "gap-1 md:gap-4",
                "rounded-md md:rounded-lg",
                "group",
                {
                  [[
                    "relative",
                    "before:animate-ping",
                    "before:-z-10",
                    "before:content-['']",
                    "before:absolute",
                    "before:w-[20px]",
                    "before:h-[20px]",
                    "before:rounded-full",
                    "before:bg-red-400",
                  ].join(" ")]: block?.active,
                },
              ])}
              to={block?.link || ""}
            >
              <div className={clsx(["flex", "flex-row", "items-center"])}>
                {block.icon}
              </div>
              <div className={clsx(["flex", "flex-col", "justify-center"])}>
                <div className={clsx("text-sm md:text-base", "font-medium")}>
                  {block.text}
                </div>
                <div className={clsx(["text-xs md:text-sm"])}>
                  {block.description}
                </div>
              </div>
            </Link>
          );
        })}
      </ul>
    </div>
  );
};

export default SettingsBlocks;
