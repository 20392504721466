import React from "react";

import {
  MdAccountBox,
  MdArrowBack,
  MdAttachMoney,
  MdCloudDone,
  MdCompareArrows,
  MdOutlineShoppingCart,
  MdViewSidebar,
} from "react-icons/md";
import PageTitle from "../../components/PageTitle/PageTitle";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useNavigate } from "react-router-dom";
import userPreferences from "../../store/userPreferences";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { BUTTON_STYLES, ICON_SIZES } from "../../helpers/constants";

import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { CONFIRM_DIALOG_ICON_SIZE } from "../../constants/icons";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import TransactionsSettings from "../../components/Modals/TransactionsSettings";

const TransactionsSettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [isUpdatingProfile, setIsUpdatingProfile] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const { t } = useTranslation();

  const { rightPanelIsVisible, setRightPanelIsVisible } = userPreferences();

  return (
    <>
      <TwoSidebars
        right={
          <>
            {<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}

            {/* <SettingsBlocks layout="vertical" /> */}
            {/* <TasksListPanel />
            <CustomLinks /> */}
          </>
        }
      >
        <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
          <PageTitle
            title={t("settings.title")}
            subtitle={t("shared.transactions")}
            titleLink="/settings"
            isLoading={false}
            rightSide={
              <>
                <ButtonGroup
                  align="right"
                  gap={1}
                  buttons={[
                    {
                      icon: <MdArrowBack size={ICON_SIZES.SM} />,
                      classes: BUTTON_STYLES.HEADING,

                      tooltip: "Atras",
                      onClick: () => {
                        navigate("/settings");
                      },
                      hide: !isMobile,
                    },
                    {
                      icon: <MdOutlineShoppingCart size={ICON_SIZES.SM} />,
                      tooltip: "Añadir gasto",
                      onClick: () => {
                        navigate("/transactions/expense");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdAttachMoney size={ICON_SIZES.SM} />,
                      tooltip: "Añadir ingreso",
                      hide: isMobile,
                      onClick: () => {
                        navigate("/transactions/income");
                      },
                    },
                    {
                      icon: <MdCompareArrows size={ICON_SIZES.SM} />,
                      tooltip: "Transacciones",
                      onClick: () => {
                        navigate("/transactions");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdViewSidebar size={ICON_SIZES.SM} />,
                      tooltip: "Panel lateral",
                      kind: rightPanelIsVisible ? "primary" : "secondary",
                      hide: isMobile,
                      onClick: () => {
                        setRightPanelIsVisible(!rightPanelIsVisible);
                      },
                    },
                  ]}
                />
              </>
            }
          />
          <div className={clsx(["flex", "gap-4", "flex-col"])}>
            <SectionTitle title={t("Campos adicionales")} />
            <TransactionsSettings />
            <div
              className={clsx([
                "w-full",
                "flex",
                "justify-end",
                "bg-inherit",
                "md:py-3",
              ])}
            >
              <ButtonGroup
                buttons={[
                  {
                    text: t("shared.update"),
                    onClick: () => {
                      setShowConfirmDialog(true);
                    },
                    icon: <MdCloudDone size={32} />,
                    kind: "update",
                    isLoading: isUpdatingProfile,
                    disabled: isUpdatingProfile,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </TwoSidebars>
      {showConfirmDialog && (
        <ConfirmDialog
          icon={<MdAccountBox size={CONFIRM_DIALOG_ICON_SIZE} />}
          title={t("dialogs.profile.update_dialog_title")}
          description={t("dialogs.profile.update_dialog_question")}
          onClose={() => {
            setShowConfirmDialog(false);
          }}
          confirmKind="save"
          onConfirm={() => {}}
          isLoading={isUpdatingProfile}
        />
      )}
    </>
  );
};

export default TransactionsSettingsPage;
