import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import useAccountsStore from "../../store/accountsStore";
import {
  formatNumber,
  getCurrencyDataByCode,
  getPaymentMethodById,
  getRandomColor,
  getTotalByPaymentMethod,
  getTotalByPayTo,
  getTotalByTransactionType,
  getUniqueAccountsFromTransactions,
  getUniquePaidToFromTransactions,
  getUniquePaymentMethodsFromTransactions,
  rgbaObjectToString,
} from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import {
  CHART_COLORS,
  CHART_TRANSPARENCY_BG_COLOR,
  EXPENSE_TYPE,
  INCOME_TYPE,
} from "../../helpers/constants";
import clsx from "clsx";
import transactionsStore from "../../store/transactionsStore";
import useMainFilter from "../../hooks/useMainFilter";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

interface ITransactionsByClientProviderChart {
  transactions: Array<any>;
  showIncome?: boolean;
  showExpenses?: boolean;
  showBalance?: boolean;
}

const TransactionsByClientProviderChart: React.FC<
  ITransactionsByClientProviderChart
> = ({
  transactions,
  showIncome = true,
  showExpenses = true,
  showBalance = true,
}) => {
  const { t } = useTranslation();
  const currencySymbol = "";
  const { mainFilter } = useMainFilter();

  const availablePaymentMethods = getUniquePaidToFromTransactions({
    transactions,
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    barSize: 100,
    // aspectRatio
    borderWidth: 1,
    borderRadius: 1,
    pointRadius: 15,
    // pointStyle: "triangle",
    // barThickness: 40,
    // minBarLength: 0,
    pointHoverRadius: 30,
    elements: {
      line: {
        tension: 0.5,
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: rgbaObjectToString(CHART_COLORS.TOOLTIP.bg),
        usePointStyle: false,
        padding: 15,
        boxPadding: 4,
        callbacks: {
          label: (context: any) => {
            const label = context?.dataset?.label || "";
            const value = Number(context?.parsed?.y) || 0;

            const formattedTooltip = formatNumber(Math.abs(Number(value)));

            return `${label}: ${currencySymbol}${formattedTooltip}`;
          },
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      // x: {
      //   ticks: {
      //     autoSkip: true,
      //     maxRotation: 0,
      //     minRotation: 0,
      //   },
      // },
      // y: {
      //   ticks: {
      //     color: "white",
      //   },
      //   grid: {
      //     color: "#444",
      //   },
      // },
      // x: {
      //   ticks: {
      //     color: "white",
      //   },
      //   grid: {
      //     color: "#444",
      //   },
      // },
    },
    animations: {
      tension: {
        duration: 2000,
        easing: "linear" as "linear",
        from: 1,
        to: 0.5,
        loop: true,
      },
    },
  };

  let labels = [];

  if (mainFilter?.type !== EXPENSE_TYPE) labels.push(t("summary.incomes"));
  if (mainFilter?.type !== INCOME_TYPE) labels.push(t("summary.expenses"));

  const datasets =
    [EXPENSE_TYPE, INCOME_TYPE]?.map((transactionType: any) => {
      return {
        label:
          transactionType === INCOME_TYPE
            ? t("shared.income")
            : t("shared.expenses"),
        data: availablePaymentMethods?.map((cat: any) => {
          if (transactionType === INCOME_TYPE) {
            const income = getTotalByPayTo({
              payTo: cat,
              type: transactionType,
              transactions,
            });

            return income || 0;
          } else if (transactionType === EXPENSE_TYPE) {
            const expense = getTotalByPayTo({
              payTo: cat,
              type: transactionType,
              transactions,
            });

            return expense ? -expense : 0;
          }

          return 0;
        }),
        backgroundColor:
          transactionType === INCOME_TYPE
            ? rgbaObjectToString({
                ...CHART_COLORS.INCOME.bg,
                a: CHART_TRANSPARENCY_BG_COLOR,
              })
            : rgbaObjectToString({
                ...CHART_COLORS.EXPENSE.bg,
                a: CHART_TRANSPARENCY_BG_COLOR,
              }),
        borderColor:
          transactionType === INCOME_TYPE
            ? rgbaObjectToString({ ...CHART_COLORS.INCOME.bg })
            : rgbaObjectToString({ ...CHART_COLORS.EXPENSE.bg }),
      };
    }) || [];

  const chartData = {
    labels: availablePaymentMethods,
    datasets,
  };

  return (
    <div
      className={clsx([
        "w-full",
        "flex",
        // "shadow-sm",
        "p-2",
        // "rounded-md",
        // "border-0",
        "border-gray-100",
      ])}
    >
      <Line
        className="w-full"
        options={options}
        data={chartData}
        height={300}
      />
    </div>
  );
};

export default TransactionsByClientProviderChart;
