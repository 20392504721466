import { create } from "zustand";

const invoicesStore = (set: any, get: any) => ({
  errorSearchingTransactions: false,
  isSearchingTransactions: false,
  isLoadingPayToList: false,
  errorLoadingPayToList: false,

  getAllInvoices: async ({
    onResolved = () => {},
    onError = () => {},
  }: {
    onResolved: (e: any) => void;
    onError: (e: any) => void;
  }) => {
    await get().searchInvoices({
      params: {},
      onResolved: (data: any) => {
        onResolved(data.data);
      },
      onError: (e: any) => {
        onError(e);
      },
    });
  },
});

export default create(invoicesStore);
