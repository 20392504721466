import React from "react";
import clsx from "clsx";
import TransactionsByClientProviderChart from "../Charts/TransactionsByClientProviderChart";
import ClientProviderSummaryTable from "../Tables/ClientProviderSummaryTable";

interface IReportByClientProvider {
  transactions: Array<any>;
  currency: string;
}

const ReportByClientProvider: React.FC<IReportByClientProvider> = ({
  transactions,
  currency,
}) => {
  return (
    <div className={clsx(["flex", "w-full", "flex-col"])}>
      <TransactionsByClientProviderChart transactions={transactions} />
      <ClientProviderSummaryTable
        currency={currency}
        transactions={transactions}
      />
    </div>
  );
};

export default ReportByClientProvider;
