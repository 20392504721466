import clsx from "clsx";
import TransactionsByPaymentMethodChart from "../Charts/TransactionsByPaymentMethodChart";
import PaymentMethodsSummaryTable from "../Tables/PaymentMethodsSummaryTable";

interface IReportByPaymentMethods {
  transactions: Array<any>;
  currency: string;
}

const ReportByPaymentMethods: React.FC<IReportByPaymentMethods> = ({
  transactions,
  currency,
}) => {
  return (
    <div className={clsx(["flex", "w-full", "flex-col"])}>
      <div>
        <TransactionsByPaymentMethodChart
          transactions={transactions}
          currency={currency}
        />
        <PaymentMethodsSummaryTable
          transactions={transactions}
          currency={currency}
        />
      </div>
    </div>
  );
};

export default ReportByPaymentMethods;
