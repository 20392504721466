import _ from "lodash";
import { isDarkMode } from "./utils";
import i18n from "i18next";
import clsx from "clsx";

export const API_SERVER_IP = process.env.REACT_APP_API_SERVER_IP;
export const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
export const FILE_STORAGE_SERVER_URL =
  "https://expenser-bucket.s3.amazonaws.com";

export const API_SERVER_FULL_URL =
  `${API_SERVER_IP}` + (API_SERVER_PORT ? `:${API_SERVER_PORT}` : "");

export const PAYMENT_METHODS = () =>
  _.orderBy(
    [
      {
        name: i18n.t("payment_methods.card"),
        id: "CARD",
        settings: {
          bg: { r: 28, g: 216, b: 236, a: 1 },
          color: { r: 255, g: 255, b: 255, a: 1 },
        },
      },
      {
        name: i18n.t("payment_methods.transfer"),
        id: "TRANSFER",
        settings: {
          bg: { r: 247, g: 90, b: 158, a: 1 },
          color: { r: 255, g: 255, b: 255, a: 1 },
        },
      },
      {
        name: i18n.t("payment_methods.cash"),
        id: "CASH",
        settings: {
          bg: { r: 150, g: 209, b: 17, a: 1 },
          color: { r: 255, g: 255, b: 255, a: 1 },
        },
      },
      {
        name: i18n.t("payment_methods.wallet"),
        id: "WALLET",
        settings: {
          bg: { r: 182, g: 135, b: 53, a: 1 },
          color: { r: 255, g: 255, b: 255, a: 1 },
        },
      },
      {
        name: i18n.t("payment_methods.check"),
        id: "CHECK",
        settings: {
          bg: { r: 74, g: 37, b: 198, a: 1 },
          color: { r: 255, g: 255, b: 255, a: 1 },
        },
      },
      {
        name: i18n.t("payment_methods.phone"),
        id: "PHONE",
        settings: {
          bg: { r: 184, g: 16, b: 134, a: 1 },
          color: { r: 255, g: 255, b: 255, a: 1 },
        },
      },
      {
        name: i18n.t("payment_methods.digital"),
        id: "DIGITAL",
        settings: {
          bg: { r: 0, g: 0, b: 0, a: 1 },
          color: { r: 255, g: 255, b: 255, a: 1 },
        },
      },
      {
        name: i18n.t("payment_methods.prepaid_card"),
        id: "PREPAID_CARD",
        settings: {
          bg: { r: 0, g: 0, b: 0, a: 1 },
          color: { r: 255, g: 255, b: 255, a: 1 },
        },
      },
      {
        name: i18n.t("payment_methods.contactless"),
        id: "CONTACTLESS",
        settings: {
          bg: { r: 3, g: 99, b: 220, a: 1 },
          color: { r: 255, g: 255, b: 255, a: 1 },
        },
      },
    ],
    ["name"],
    ["asc"]
  );

export const TRANSACTION_STATUSES = () => [
  {
    id: "PENDING",
    name: i18n.t("transaction_status.pending"),
    className: clsx(["bg-red-500", "text-red-50"]),
    settings: {
      color: {
        r: 250,
        g: 15,
        b: 54,
        a: 1,
      },
      bg: {
        r: 250,
        g: 15,
        b: 54,
        a: 1,
      },
    },
  },
  {
    id: "NULL",
    name: i18n.t("transaction_status.null"),
    className: clsx(["bg-gray-100", "text-gray-500"]),
    settings: {
      color: {
        r: 250,
        g: 15,
        b: 54,
        a: 1,
      },
      bg: {
        r: 75,
        g: 85,
        b: 99,
        a: 1,
      },
    },
  },
  {
    id: "PAID",
    name: i18n.t("transaction_status.paid"),
    className: clsx(["bg-green-500", "text-green-50"]),
    settings: {
      color: {
        r: 74,
        g: 222,
        b: 128,
        a: 1,
      },
      bg: {
        r: 41,
        g: 224,
        b: 15,
        a: 1,
      },
    },
  },
];

export const CHART_COLORS = {
  TOOLTIP: {
    bg: { r: 0, g: 0, b: 0, a: 0.8 },
  },
  EXPENSE: {
    color: {
      r: 250,
      g: 15,
      b: 54,
      a: 1,
    },
    bg: {
      r: 250,
      g: 15,
      b: 54,
      a: 1,
    },
  },
  INCOME: {
    color: {
      r: 74,
      g: 222,
      b: 128,
      a: 1,
    },
    bg: {
      r: 41,
      g: 224,
      b: 15,
      a: 1,
    },
  },
  BALANCE: {
    color: {
      r: 192,
      g: 192,
      b: 192,
      a: 1,
    },
    bg: {
      r: 0,
      g: 0,
      b: 0,
      a: 1,
    },
  },
};

export const APP_TOKEN_KEY = "token";
export const APP_USER_ID_KEY = "user";
export const APP_LOCAL_STORAGE_ACCOUNTS = "accounts";
export const APP_LOCAL_STORAGE_USER_DATA = "user-data";

// notes endpoints
export const API_ENDPOINT_ALL_NOTES = "/tasks";
export const API_ENDPOINT_ADD_NOTE = "/task";
export const API_ENDPOINT_DELETE_TASK = "/task";

// links endpoints
export const API_ENDPOINT_ALL_LINKS = "/links";
export const API_ENDPOINT_ADD_LINK = "/link";
export const API_ENDPOINT_DELETE_LINK = "/link";

// transactions endpoints
export const API_ENDPOINT_ALL_INVOICES = "/invoices";
export const API_ENDPOINT_ADD_INVOICE = "/invoice";
export const API_ENDPOINT_UPDATE_INVOICE = "/invoice";
export const API_ENDPOINT_DELETE_INVOICE = "/invoice";
export const API_ENDPOINT_GET_INVOICE = "/invoice";
export const API_ENDPOINT_CLONE_TRANSACTION = "/clone/transaction";

// auth endpoints
export const API_ENDPOINT_LOGIN = "/login";
export const API_ENDPOINT_REGISTER = "/register";

// profile
export const API_ENDPOINT_UPDATE_PROFILE = "/user";

// account endpoints
export const API_ENDPOINT_ALL_ACCOUNTS = "/accounts";
export const API_ENDPOINT_ADD_ACCOUNT = "/account";
export const API_ENDPOINT_UPDATE_ACCOUNT = "/account";
export const API_ENDPOINT_DELETE_ACCOUNT = "/account";
export const API_ENDPOINT_GET_ACCOUNT = "/account";
export const API_ENDPOINT_UPLOAD_ATTACHMENT = "/upload";

// categories endpoints
export const API_ENDPOINT_ALL_CATEGORIES = "/categories";
export const API_ENDPOINT_ADD_CATEGORY = "/category";
export const API_ENDPOINT_GET_CATEGORY = "/category";

export const API_ENDPOINT_SEARCH_NETWORK = "/search-network";
export const API_ENDPOINT_SEND_INVITE = "/invite";
export const API_ENDPOINT_GET_INVITES = "/invites";

export const API_ENDPOINT_PARSE_XML = "/parser/xml";

export const PLANS = {
  FREE: {
    name: "Gratuito",
    permissions: [],
  },
  HOME: {
    name: "Hogar",
    permissions: [],
  },
  SMALLBUSINESS: {
    name: "Empresa",
    permissions: [],
  },
};

export const FILE_TYPE_PDF = "PDF";
export const FILE_TYPE_XML = "XML";
export const FILE_TYPE_IMG = "IMG";

export const BLUR_INCOME = true;
export const TRANSACTIONS_TABLE_VIEW = 1;
export const TRANSACTIONS_CARDS_VIEW = 2;

export const INCOME_TYPE = "INCOME";
export const EXPENSE_TYPE = "EXPENSE";

export const PAGINATION_ROWS_PER_PAGE_OPTIONS = [
  10, 25, 50, 100, 150, 200, 250,
];
export const [PAGINATION_MIN] = PAGINATION_ROWS_PER_PAGE_OPTIONS;

export const CHART_TRANSPARENCY_BG_COLOR = 0.5;

export const LATEST_TRANSACTIONS_COUNT = 10;

export const MOBILE_BREAKPOINT = 767;
export const TABLET_BREAKPOINT = 1023;
export const DESKTOP_BREAKPOINT = 1279;

export const BUTTON_STYLES = {
  HEADING: clsx([
    "!border-0",
    "!bg-gray-900",
    "!text-white",
    "hover:!bg-gray-800 hover:!text-gray-400 hover:!border-gray-500 ",
  ]),
};

export const TRANSACTIONS_EXPORT_FORMATS = [
  {
    id: "xlsx",
    name: "XLSX",
    description: "XLSX (Excel Spreadsheet)",
  },
  {
    id: "json",
    name: "JSON",
    description: "JSON (JavaScript Object Notation)",
  },
  { id: "csv", name: "CSV", description: "CSV (Comma-separated Values)" },
  { id: "pdf", name: "PDF", description: "PDF (Portable document format)" },
];

export const [
  TRANSACIONS_EXPORT_EXCEL_FORMAT,
  TRANSACIONS_EXPORT_JSON_FORMAT,
  TRANSACIONS_EXPORT_CSV_FORMAT,
  TRANSACIONS_EXPORT_PDF_FORMAT,
] = TRANSACTIONS_EXPORT_FORMATS;

export const INVOICE_NOTES = [
  `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla felis dui,`,
  `luctus ultrices ante quis, dignissim sodales tellus.Donec porttitor`,
  `dolor non nibh interdum venenatis.`,
].join("\n");

export const INVITE_STATUS_SENT = "SENT";
export const API_ENDPOINT_DELETE_INVITE = "/invite";
export const API_ENDPOINT_ACCEPT_INVITE = "/accept-invite";

export const API_ENDPOINT_GET_USERS = "/users";

export const CATEGORIES_DEFAULT_TEXT_COLOR = { r: 255, g: 255, b: 255, a: 1 };
export const CATEGORIES_DEFAULT_BG_COLOR = { r: 0, g: 0, b: 0, a: 1 };
export const CATEGORIES_DEFAULT_ICON = "MdCategory";

export const TOAST_TEXT_COLOR = { r: 255, g: 255, b: 255, a: 1 };
export const TOAST_BG_COLOR = { r: 0, g: 0, b: 0, a: 0.8 };

export const MOBILE_BREAKPOINT_MEDIA_QUERY = "(max-width: 767px)";

export const ICON_SIZES = {
  XS: 16,
  SM: 24,
  MD: 32,
  LG: 48,
  XL: 64,
};
