import apiClient from "../helpers/api-client";
import { API_ENDPOINT_GET_INVITES } from "../helpers/constants";

export const getAllInvites = async () => {
  try {
    return await apiClient.get(API_ENDPOINT_GET_INVITES);
  } catch (_e) {
    throw _e;
  }
};
