import clsx from "clsx";
import CategoriesSummaryTable from "../Tables/CategoriesSummaryTable";
import TransactionsByCategoryChart from "../Charts/TransactionsByCategoryChart";

interface IReportByCategories {
  transactions: Array<any>;
  currency: string;
}

const ReportByCategories: React.FC<IReportByCategories> = ({
  transactions,
  currency,
}) => {
  return (
    <div className={clsx(["flex", "w-full", "flex-col"])}>
      <TransactionsByCategoryChart
        transactions={transactions}
        currency={currency}
      />
      <CategoriesSummaryTable currency={currency} transactions={transactions} />
    </div>
  );
};

export default ReportByCategories;
