import axios from "axios";
import {
  API_SERVER_FULL_URL,
  APP_TOKEN_KEY,
  APP_USER_ID_KEY,
} from "./constants";

const apiClient = () => {
  const defaultOptions = {
    baseURL: API_SERVER_FULL_URL,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage?.getItem?.(APP_TOKEN_KEY) || "";
    const userId = localStorage?.getItem?.(APP_USER_ID_KEY) || "";

    config.headers.Authorization = token ? `Bearer ${token}` : "";
    config.headers["Expenser-User-Id"] = userId || "";

    return config;
  });

  // redirect to login if user does not have access
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        window.location.href = "/logout";
      }
    }
  );

  return instance;
};

export default apiClient();
