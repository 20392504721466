import clsx from "clsx";
import { ReactElement, useEffect, useRef } from "react";

interface IModalBody {
  children: ReactElement;
  className?: string;
  focus?: boolean;
}

const ModalBody: React.FC<IModalBody> = ({
  children,
  className = "",
  focus = true,
}) => {
  const bodyRef = useRef(null);

  useEffect(() => {
    if (focus && bodyRef?.current) {
      (bodyRef.current as HTMLElement)?.focus?.();
    }
  }, [bodyRef, focus]);

  return (
    <div
      tabIndex={0}
      ref={bodyRef}
      className={clsx([
        "h-full",
        "max-h-[60vh]",
        "md:max-h-[55vh]",
        "lg:max-h-[60vh]",
        "xl:max-h-[63vh]",
        "overflow-auto",
        "focus:border-transparent",
        "px-4",
        "md:px-6",
        className,
      ])}
    >
      {children}
    </div>
  );
};

export default ModalBody;
