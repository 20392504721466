import { ReactElement } from "react";
import { ILinkWithIcon } from "../LinkWithIcon/LinkWithIcon";
import { MdArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import clsx from "clsx";

interface IPageTitle {
  title: string;
  subtitle?: string | ReactElement;
  link?: ILinkWithIcon;
  rightSide?: ReactElement;
  titleLink?: any;
  isLoading?: boolean;
  titleLinkClick?: () => void;
}

const PageTitle: React.FC<IPageTitle> = ({
  title,
  subtitle,
  link = null,
  rightSide = null,
  titleLink = null,
  isLoading = false,
  titleLinkClick = () => {},
}) => {
  return (
    <div
      className={clsx([
        "flex",
        "flex-col",
        "gap-4",
        "transition-all",
        "w-full",
        "py-2 md:py-3",
        "bg-[#212f3c]",
        "md:bg-transparent md:bg-gradient-to-b md:from-white md:via-white md:to-transparent",
        "sticky",
        "top-[40px]",
        "md:-top-[18px]",
        "z-30",
        "-mx-4 md:mx-0 w-[calc(100%+32px)] min-w-[calc(100%+32px)] md:min-w-full md:w-auto px-4 md:px-0",
        "shadow-md md:shadow-none mb-4 md:mb-0",
      ])}
    >
      <div
        className={clsx([
          "flex",
          "w-full",
          "flex-row",
          "md:flex-row",
          "items-center",
          "gap-2",
          "md:gap-2",
          "min-h-[40px] md:min-h-[46px]",
          { "md:justify-between": rightSide },
        ])}
      >
        <div
          className={clsx([
            "flex",
            "flex-col",
            "w-full",
            "gap-0",
            "md:gap-1",
            "md:flex-row",
            "md:gap-2",
            "items-start",
            "md:items-center",
            "md:text-lg",
            "text-sm",
          ])}
        >
          {titleLink ? (
            <Link
              className={clsx([
                "text-white",
                "md:text-gray-800",
                "border-b-2",
                "border-transparent",
                "hover:border-red-100",
                "leading-none",
                "md:leading-normal",
              ])}
              to={titleLink}
              onClick={() => titleLinkClick()}
            >
              {title}
            </Link>
          ) : (
            <h1
              className={clsx([
                "text-white",
                "md:text-gray-800",
                "border-b-2",
                "border-transparent",
                "leading-none",
                "md:leading-normal",
              ])}
            >
              {title}
            </h1>
          )}
          {subtitle && (
            <div
              className={clsx([
                "flex",
                "flex-row",
                "md:flex-row",
                "items-center",
                "gap-2",
                "items-center",
                "text-xl",
                "text-red-500",
                "font-bold",
                "md:text-red-500",
                "w-full",
                "md:w-auto",
              ])}
            >
              <span className={clsx(["hidden", "md:inline-block"])}>
                <MdArrowRight size={30} />
              </span>
              <h2
                className={clsx([
                  "flex",
                  "truncate",
                  "max-w-[90%]",
                  "font-normal",
                  "md:font-light",
                  "md:leading-normal",
                  "text-sm",
                  "md:text-lg",
                ])}
              >
                {subtitle}
              </h2>
            </div>
          )}
        </div>
        {rightSide}
      </div>
    </div>
  );
};

export default PageTitle;
