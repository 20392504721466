import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import _ from "lodash";
import clsx from "clsx";
import CountUp from "react-countup";
import { useEffect, useState } from "react";
import { MdCategory, MdDeselect, MdSettings } from "react-icons/md";
import SelectionTotals from "../../components/SelectionTotals/SelectionTotals";
import categoriesStore from "../../store/categoriesStore";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useNavigate } from "react-router-dom";
import { ALL_USER_ICONS } from "../../helpers/user-icons";
import { t } from "i18next";
import { getCurrencyDataByCode, rgbaObjectToString } from "../../helpers/utils";
import useAccountsStore from "../../store/accountsStore";
import useCategories from "../../hooks/useCategories";
import {
  CATEGORIES_DEFAULT_BG_COLOR,
  CATEGORIES_DEFAULT_ICON,
  CATEGORIES_DEFAULT_TEXT_COLOR,
  ICON_SIZES,
} from "../../helpers/constants";

ChartJS.register(ArcElement, Tooltip, Legend);

interface ICategoryIcon {
  name: string;
}
export const CategoryIcon: React.FC<ICategoryIcon> = ({ name }) => {
  const Icon = ALL_USER_ICONS.find(
    ({ name: iconName }) => name === iconName
  )?.icon;

  if (Icon) {
    return (
      <>
        <Icon size={ICON_SIZES.SM} className="hidden md:flex" />
        <Icon size={ICON_SIZES.SM} className="md:hidden" />
      </>
    );
  }

  return (
    <>
      <MdCategory size={ICON_SIZES.SM} className="hidden md:flex" />
      <MdCategory size={ICON_SIZES.SM} className="md:hidden" />
    </>
  );
};

interface ITransactionsByCategory {
  invoices: Array<any>;
}

const TransactionsByCategory: React.FC<ITransactionsByCategory> = ({
  invoices,
}) => {
  const [selectedCategories, setSelectedCategories] = useState<Array<any>>([]);
  const { getCategories } = categoriesStore();
  const { getAccountById } = useAccountsStore();
  const { allCategoryList, getCategoryByName } = useCategories();

  const handleSelection = ({ category }: { category: string }) => {
    const isSelected = selectedCategories?.find(
      (selected) => selected === category
    );

    if (isSelected) {
      setSelectedCategories(
        Array.from(
          new Set(
            selectedCategories?.filter?.(
              (selected: string) => selected !== category
            )
          )
        )
      );
    } else {
      setSelectedCategories(
        Array.from(new Set([...selectedCategories, category]))
      );
    }
  };

  const leData = invoices?.reduce?.((acc, current) => {
    const accountCurrency = getAccountById(current.account).currency;

    if (!acc[accountCurrency]) {
      acc[accountCurrency] = {};
    }

    const category = current?.category?.trim?.() || "Sin categoria";
    if (!acc[accountCurrency][category]) {
      acc[accountCurrency][category] = 0;
    }

    acc[accountCurrency][category] =
      Number(acc[accountCurrency][category] || 0) + Number(current.subTotal);
    // }

    return acc;
  }, {});

  const uniqueDetail: any = Object.values(leData)[0];
  const leCurrencyData = getCurrencyDataByCode({
    code: Object.keys(leData)[0],
  });

  const formattedData: any = () => {
    const labels: any = [];
    const data: any = [];
    const backgrounds = [];

    for (let label in uniqueDetail) {
      if (!labels.includes(label)) {
        labels.push(label);
        data.push(uniqueDetail[label] || 0);
        backgrounds.push(
          rgbaObjectToString(
            getCategoryByName(label)?.settings?.bg ||
              CATEGORIES_DEFAULT_BG_COLOR
          )
        );
      }
    }

    return {
      labels,
      data,
      backgrounds,
    };
  };

  const selectedCategoriesSummary = () => {
    let total = 0;

    selectedCategories?.forEach?.((cat) => {
      const index = labels?.findIndex?.(
        (categoryName: string) => cat === categoryName
      );

      if (index !== -1) {
        total += parseFloat(data?.[index]);
      }
    });

    return {
      subTotal: total,
      taxes: 0,
      discounts: 0,
    };
  };

  const { data = [], labels = [] } = formattedData();
  const navigate = useNavigate();

  if (!data?.length && !labels?.length) {
    return (
      <div className="flex items-center justify-center py-10 text-sm md:text-base text-gray-400">
        Hoy no se han realizado movimientos
      </div>
    );
  }

  return (
    <div
      className={clsx([
        "flex",
        "flex-col",
        "w-full",
        "gap-12",
        // "bg-gray-50",
        // "p-2",
        "rounded-md md:rounded-md",
      ])}
    >
      {selectedCategories?.length > 0 && (
        <SelectionTotals
          {...selectedCategoriesSummary()}
          selectionCount={selectedCategories.length}
        />
      )}
      <div
        className={clsx([
          "flex",
          "w-full",
          "flex-col",
          "gap-4",
          "md:gap-6",
          // "max-h-[245px]",
          // "md:max-h-none",
          "overflow-auto",
        ])}
      >
        <ul
          className={clsx([
            "grid",
            "gap-1",
            "md:gap-1",
            "grid-cols-3",
            "md:grid-cols-3",
            "lg:grid-cols-4",
            // "max-h-[300px]",
            // "overflow-auto",
          ])}
        >
          {labels?.map((category: any, key: number) => (
            <li
              key={key}
              className={clsx([
                "text-sm",
                "px-1 md:px-0 py-1 md:py-1",
                "hover:opacity-80",
                "rounded-md md:rounded-md",
              ])}
              onClick={() => handleSelection({ category })}
              style={{
                background: rgbaObjectToString(
                  getCategoryByName(category)?.settings?.bg ||
                    CATEGORIES_DEFAULT_BG_COLOR
                ),
                color: rgbaObjectToString(
                  getCategoryByName(category)?.settings?.color ||
                    CATEGORIES_DEFAULT_TEXT_COLOR
                ),
              }}
            >
              <div
                className={clsx([
                  "flex",
                  "flex-col",
                  "md:flex-row",
                  "gap-0",
                  "md:gap-2",
                  "items-center",
                ])}
              >
                <div
                  className={clsx([
                    "flex",
                    "w-[40px]",
                    "h-[40px]",
                    "md:w-[54px]",
                    "md:h-[54px]",
                    "flex-shrink-0",
                    "flex-grow-0",
                    "rounded-md md:rounded-md",
                    "items-center",
                    "justify-center",
                    "transition-all",
                    "cursor-pointer",
                    {
                      "opacity-20":
                        selectedCategories?.length &&
                        !selectedCategories.includes(category),
                      "opacity-100": !selectedCategories.length,
                      "scale-110":
                        selectedCategories?.length &&
                        selectedCategories.includes(category),
                    },
                  ])}
                >
                  <CategoryIcon
                    name={rgbaObjectToString(
                      getCategoryByName(category)?.settings?.icon ||
                        CATEGORIES_DEFAULT_ICON
                    )}
                  />
                </div>
                <div
                  className={clsx([
                    "flex",
                    "flex-col-reverse",
                    "items-center",
                    "md:items-start",
                    "cursor-pointer",
                  ])}
                >
                  <div
                    className={clsx([
                      "flex",
                      "font-medium",
                      "text-xs",
                      "md:text-lg",
                    ])}
                  >
                    {leCurrencyData?.symbol}
                    <CountUp
                      // enableScrollSpy
                      end={data?.[key] || 0}
                      decimalPlaces={2}
                      // duration={5}
                      preserveValue
                    />
                  </div>
                  {/* <Badge text={formatNumber(data?.[key] || 0).toString()} /> */}
                  <div
                    className={clsx([
                      "flex",
                      "w-full",
                      "truncate",
                      "text-xs",
                      "px-2",
                      "text-center",
                    ])}
                  >
                    {category}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {selectedCategories?.length > 0 && (
          <ButtonGroup
            gap={1}
            buttons={[
              {
                icon: <MdDeselect size={28} />,
                tooltip: t("shared.deselect"),
                text: t("shared.deselect"),
                hide: !selectedCategories?.length,
                onClick: () => {
                  setSelectedCategories([]);
                },
              },
              // {
              //   icon: <MdSettings size={28} />,
              //   tooltip: "Personalizar",
              //   onClick: () => {
              //     navigate("/settings/categories");
              //   },
              // },
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default TransactionsByCategory;
