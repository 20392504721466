import React from "react";
import DataTable from "react-data-table-component";
import {
  EXPENSE_TYPE,
  ICON_SIZES,
  INCOME_TYPE,
  PAGINATION_MIN,
  PAGINATION_ROWS_PER_PAGE_OPTIONS,
} from "../../helpers/constants";
import {
  MdArrowDownward,
  MdCategory,
  MdFilterAlt,
  MdFilterAltOff,
  MdOutlineFolder,
} from "react-icons/md";
import { useTranslation } from "react-i18next";
import CellWithBackground from "../../pages/Transactions/BackgroundCell";
import useAccountsStore from "../../store/accountsStore";
import useCategoriesStore from "../../store/categoriesStore";

import TransactionTypeCell from "../../pages/Transactions/TransactionTypeCell";
import { ALL_USER_ICONS } from "../../helpers/user-icons";
import clsx from "clsx";
import {
  formatNumber,
  getCurrencyDataByCode,
  getPaymentMethodById,
  getTotalByPaymentMethod,
  getTotalByPayTo,
  getUniquePaidToFromTransactions,
  getUniquePaymentMethodsFromTransactions,
} from "../../helpers/utils";
import { getColorFromAccountSettings } from "../../helpers/accounts";
import transactionsStore from "../../store/transactionsStore";
import ButtonGroupCell from "../../pages/Transactions/ButtonGroupCell";
import { useNavigate } from "react-router-dom";
import userPreferences from "../../store/userPreferences";
import useMainFilter from "../../hooks/useMainFilter";

interface IClientProviderSummaryTable {
  transactions: Array<any>;
  currency: string;
}

const ClientProviderSummaryTable: React.FC<IClientProviderSummaryTable> = ({
  transactions,
  currency,
}) => {
  const { tableResultsPerPage, setTableResultsPerPage } = userPreferences();
  const { mainFilter, setMainFilter } = useMainFilter();
  const currencySymbol =
    getCurrencyDataByCode({
      code: currency,
    })?.symbol || "";

  const navigate = useNavigate();

  const availablePaymentMethods = getUniquePaidToFromTransactions({
    transactions,
  });

  const formattedData = () => {
    return availablePaymentMethods?.map((paymentMethod: any) => {
      return {
        name: paymentMethod,
        income: getTotalByPayTo({
          type: INCOME_TYPE,
          transactions,
          payTo: paymentMethod,
        }),
        expense: getTotalByPayTo({
          type: EXPENSE_TYPE,
          transactions,
          payTo: paymentMethod,
        }),
        ...getPaymentMethodById(paymentMethod),
      };
    });
  };

  // console.log({ formattedData: formattedData(), leData });

  const { t } = useTranslation();
  const COLUMNS = [
    {
      cell: (row: any) => {
        return (
          <CellWithBackground
            background={"#34d399"}
            color={"#fff"}
            value={row.name}
          />
        );
      },
      selector: (row: any) => row.name,
      name: t("transactions_table.origin_destination"),
      sortable: true,
    },
    {
      name: t("shared.income"),
      selector: (row: any) => row.income,
      sortable: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={INCOME_TYPE}>
            {formatNumber(row.income)}
          </TransactionTypeCell>
        );
      },
      omit: mainFilter?.type === EXPENSE_TYPE,
      right: true,
    },
    {
      name: t("shared.expenses"),
      selector: (row: any) => row.expense,
      sortable: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={EXPENSE_TYPE}>
            {formatNumber(row.expense)}
          </TransactionTypeCell>
        );
      },
      right: true,
      omit: mainFilter?.type === INCOME_TYPE,
    },
    {
      cell: (row: any) => {
        return (
          <div
            className={clsx(["flex", "w-full", "justify-end", "items-center"])}
          >
            <ButtonGroupCell
              buttons={[
                // {
                //   icon: <MdAttachment size={ICON_SIZES.SM} />,
                //   onClick: () => {},
                //   hide: !row?.attachments?.length,
                // },
                {
                  onClick: (e: any) => {
                    // if (e?.metaKey || e?.altKey) {
                    //   window.open(
                    //     `/transactions/${mainFilter?.account || ""}`,
                    //     "_blank"
                    //   );
                    // } else {
                    //   navigate(`/transactions/${mainFilter?.account || ""}`);
                    // }

                    if (
                      mainFilter?.payTo?.length &&
                      mainFilter?.payTo?.includes(row?.name as never)
                    ) {
                      setMainFilter({
                        payTo:
                          mainFilter?.payTo?.filter?.(
                            (cat) => cat !== row.name
                          ) || [],
                      });
                    } else {
                      setMainFilter({
                        payTo: [row?.name],
                      });
                      navigate(`/transactions/${mainFilter?.account || ""}`);
                    }
                  },
                  icon:
                    mainFilter?.payTo?.length &&
                    mainFilter?.payTo?.includes(row?.name as never) ? (
                      <MdFilterAltOff size={ICON_SIZES.SM} />
                    ) : (
                      <MdFilterAlt size={ICON_SIZES.SM} />
                    ),
                },
              ]}
            />
          </div>
        );
      },
      selector: (row: any) => row,
      name: "",
      sortable: false,
      right: true,
    },
  ];
  return (
    <DataTable
      columns={COLUMNS}
      data={formattedData()}
      pagination={availablePaymentMethods?.length > PAGINATION_MIN}
      highlightOnHover={false}
      persistTableHead
      sortIcon={<MdArrowDownward />}
      selectableRowsVisibleOnly
      paginationRowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
      paginationPerPage={tableResultsPerPage}
      customStyles={{
        cells: {
          style: {
            marginRight: "10px",
          },
        },
        headCells: {
          style: {
            marginRight: "10px",
          },
        },
      }}
      paginationComponentOptions={{
        selectAllRowsItem: true,
        selectAllRowsItemText: t("pagination.all"),
        rowsPerPageText: t("pagination.rows_per_page"),
        rangeSeparatorText: t("pagination.separator"),
      }}
      onChangeRowsPerPage={(rows) => {
        setTableResultsPerPage(rows);
      }}
      noDataComponent={
        <div
          className={clsx([
            "px-2",
            "py-4",
            "flex",
            "items-center",
            "justify-center",
            "text-sm",
            "md:text-base",
          ])}
        >
          {t("empty_states.no_records")}
        </div>
      }
      // striped
    />
  );
};

export default ClientProviderSummaryTable;
