import clsx from "clsx";
import TransactionsByTypeChart from "../Charts/TransactionsByTypeChart";
import AccountsSummaryTable from "../InvoicesChart/AccountsSummaryTable";
import TransactionsSummary from "../../pages/Transactions/TransactionsSummary";
import { useMediaQuery } from "react-responsive";
import SummaryBlocks from "../SummaryBlocks/SummaryBlocks";

interface ITransactionsByTypeReport {
  transactions: Array<any>;
  currency: string;
}

const TransactionsByTypeReport: React.FC<ITransactionsByTypeReport> = ({
  transactions,
  currency,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <div className={clsx(["flex", "w-full", "flex-col", "gap-4"])}>
      <TransactionsByTypeChart
        transactions={transactions}
        currency={currency}
      />
      <AccountsSummaryTable transactions={transactions} currency={currency} />
    </div>
  );
};

export default TransactionsByTypeReport;
