import { ReactElement, ReactNode } from "react";
import { MdClose } from "react-icons/md";
import clsx from "clsx";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { BUTTON_STYLES, ICON_SIZES } from "../../helpers/constants";

interface IModalHeader {
  title?: string;
  onClose: () => void;
  children?: ReactNode;
  subtitle?: string;
  icon?: ReactElement;
  isClosable?: boolean;
}

const ModalHeader: React.FC<IModalHeader> = ({
  title = "",
  onClose,
  children,
  subtitle = "",
  icon = "",
  isClosable = true,
}) => {
  const hasTitle = title || subtitle;
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return hasTitle ? (
    <div
      className={clsx([
        "flex",
        "flex-row",
        "items-center",
        {
          "justify-between": hasTitle,
          "justify-end": !hasTitle,
        },
        "relative mb-4",
        "sm:mb-10",
        "after:content-['']",
        "after:border-b-2",
        "sm:after:border-b-[2px]",
        "after:w-1/2",
        "after:border-b-gray-500",
        "after:absolute",
        "after:bottom-0",
        "after:left-0",
        "after:p-6",
        "after:h-[2px]",
        "gap-6",
        "white",
        "px-4 pt-4",
        "md:p-6",
      ])}
    >
      {hasTitle && (
        <div className={clsx(["flex", "gap-1", "sm:gap-2", "items-center"])}>
          {/* {icon && (
            <div className="dark:text-gray-900 text-primary">{icon}</div>
          )} */}
          <h2 className={clsx(["text-sm", "md:text-base", "font-medium"])}>
            {title}
          </h2>
        </div>
      )}
      <Button
        onClick={() => {
          if (isClosable) {
            onClose();
          }
        }}
        icon={<MdClose size={ICON_SIZES.SM} />}
        tooltip={t("shared.close")}
        kind="normal"
        classes={clsx([
          {
            "pointer-events-none": !isClosable,
            invisible: !isClosable,
          },
        ])}
        // classes={clsx(["justify-center", "!p-0", BUTTON_STYLES.HEADING])}
      />
    </div>
  ) : (
    <div className={clsx(["absolute", "top-10", "right-10"])}>
      <Button
        kind="normal"
        onClick={() => {
          if (isClosable) {
            onClose();
          }
        }}
        icon={<MdClose size={42} />}
        classes={clsx([
          {
            "pointer-events-none": !isClosable,
            invisible: !isClosable,
          },
        ])}
      />
    </div>
  );
};

export default ModalHeader;
