import { useTranslation } from "react-i18next";
import Modal from "../Modal/Modal";
import ReactSelect, { components } from "react-select";
import {
  MdCheckCircle,
  MdClose,
  MdFilterAltOff,
  MdRadioButtonUnchecked,
  MdSearch,
} from "react-icons/md";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import TextInput from "../TextInput/TextInput";

import moment from "moment";
import {
  ICON_SIZES,
  PAYMENT_METHODS,
  TRANSACTION_STATUSES,
} from "../../helpers/constants";
import {
  getCurrencyDataByCode,
  getPaymentMethodById,
  transformToSelectOptions,
} from "../../helpers/utils";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import { transactionStatus } from "../../helpers/transactions";
import accountsStore from "../../store/accountsStore";
import { useAutocomplete } from "../../hooks/useTransactions";
import useMainFilter from "../../hooks/useMainFilter";
import useCategories from "../../hooks/useCategories";
import useCurrentUser from "../../hooks/useCurrentUser";
import _ from "lodash";

const TRANSACTIONS_SEARCH_FORM = "transactions-search-form";

interface ITransactionsSearchModal {
  onClose: () => void;
  disableFields?: boolean;
  onSubmit: (values: any) => void;
}

const TransactionsSearchModal: React.FC<ITransactionsSearchModal> = ({
  onClose,
  disableFields = false,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { allPayToList, allTagList } = useAutocomplete();
  const { allCurrenciesUsed } = accountsStore();
  const { mainFilter } = useMainFilter();

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [selectedTags, setSelectedTags] = useState<Array<any>>(
    mainFilter?.tags?.map?.((tag) => ({ value: tag, label: tag })) || []
  );
  const [selectedCategories, setSelectedCategories] = useState<Array<any>>(
    mainFilter?.category?.map?.((cat) => ({ value: cat, label: cat })) || []
  );
  const [selectedClientProviders, setSelectedClientProviders] = useState<
    Array<any>
  >(
    mainFilter?.payTo?.map?.((payTo) => ({ value: payTo, label: payTo })) || []
  );
  const [selectedStatuses, setSelectedStatuses] = useState<Array<any>>(
    mainFilter?.status?.map?.((status) => ({
      value: status,
      label: transactionStatus.getNameById(status),
    })) || []
  );
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<
    Array<any>
  >(
    mainFilter?.paymentMethod?.map?.((paymentMethod) => ({
      value: paymentMethod,
      label: getPaymentMethodById(paymentMethod)?.name,
    })) || []
  );

  const { allCategoryList } = useCategories();

  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [account, setAccount] = useState<string>("");
  const [payTo, setPayTo] = useState<Array<string>>([]);
  const [status, setStatus] = useState<Array<string>>([]);
  const [paymentMethod, setPaymentMethod] = useState<Array<string>>([]);
  const [tags, setTags] = useState<Array<string>>([]);
  const [category, setCategory] = useState<Array<string>>([]);
  const [currency, setCurrency] = useState<Array<string>>([]);

  const showClearFilters = () => {
    return (
      dateFrom ||
      dateTo ||
      category?.length ||
      payTo?.length ||
      status?.length ||
      paymentMethod?.length ||
      tags?.length
    );
  };
  const ValueContainer = ({ children, getValue, ...props }: any) => {
    var length = getValue().length;

    return (
      <components.ValueContainer {...props}>
        {!props.selectProps.inputValue &&
          `${length} Item${length !== 1 ? "s" : ""} selected`}
        {React.Children.map(children, (child) => {
          return child.type === components.Input ? child : null;
        })}
      </components.ValueContainer>
    );
  };

  const dateOptions = () => {
    const today = moment();

    return [
      {
        name: t("transactions_search.today"),
        dateFrom: today.format("yyyy-MM-DD"),
        dateTo: today.format("yyyy-MM-DD"),
        active:
          dateFrom === today.format("yyyy-MM-DD") &&
          dateTo === today.format("yyyy-MM-DD"),
      },
      {
        name: t("transactions_search.yesterday"),
        dateFrom: moment(today).subtract(1, "day").format("yyyy-MM-DD"),
        dateTo: moment(today).subtract(1, "day").format("yyyy-MM-DD"),
        active:
          dateFrom === moment(today).subtract(1, "day").format("yyyy-MM-DD") &&
          dateTo === moment(today).subtract(1, "day").format("yyyy-MM-DD"),
      },
      {
        name: t("transactions_search.last_7_days"),
        dateFrom: moment(today).subtract(1, "week").format("yyyy-MM-DD"),
        dateTo: moment(today).format("yyyy-MM-DD"),
        active:
          dateFrom === moment(today).subtract(1, "week").format("yyyy-MM-DD") &&
          dateTo === moment(today).format("yyyy-MM-DD"),
      },
      {
        name: t("transactions_search.last_15_days"),
        dateFrom: moment(today).subtract(2, "week").format("yyyy-MM-DD"),
        dateTo: moment(today).format("yyyy-MM-DD"),
        active:
          dateFrom === moment(today).subtract(2, "week").format("yyyy-MM-DD") &&
          dateTo === moment(today).format("yyyy-MM-DD"),
      },
      {
        name: t("transactions_search.last_22_days"),
        dateFrom: moment(today).subtract(3, "week").format("yyyy-MM-DD"),
        dateTo: moment(today).format("yyyy-MM-DD"),
        active:
          dateFrom === moment(today).subtract(3, "week").format("yyyy-MM-DD") &&
          dateTo === moment(today).format("yyyy-MM-DD"),
      },

      {
        name: t("transactions_search.last_30_days"),
        dateFrom: moment(today).subtract(4, "week").format("yyyy-MM-DD"),
        dateTo: moment(today).format("yyyy-MM-DD"),
        active:
          dateFrom === moment(today).subtract(4, "week").format("yyyy-MM-DD") &&
          dateTo === moment(today).format("yyyy-MM-DD"),
      },

      {
        name: t("transactions_search.current_month"),
        dateFrom: moment(today).startOf("month").format("yyyy-MM-DD"),
        dateTo: moment(today).endOf("month").format("yyyy-MM-DD"),
        active:
          dateFrom === moment(today).startOf("month").format("yyyy-MM-DD") &&
          dateTo === moment(today).endOf("month").format("yyyy-MM-DD"),
      },
      {
        name: t("transactions_search.last_month"),
        dateFrom: moment(today)
          .subtract(1, "month")
          .startOf("month")
          .format("yyyy-MM-DD"),
        dateTo: moment(today)
          .subtract(1, "month")
          .endOf("month")
          .format("yyyy-MM-DD"),
        active:
          dateFrom ===
            moment(today)
              .subtract(1, "month")
              .startOf("month")
              .format("yyyy-MM-DD") &&
          dateTo ===
            moment(today)
              .subtract(1, "month")
              .endOf("month")
              .format("yyyy-MM-DD"),
      },
      // {
      //   name: t("transactions_search.reset_dates"),
      //   dateFrom: "",
      //   dateTo: "",
      // },
    ];
  };

  useEffect(() => {
    setDateFrom(mainFilter.dateFrom);
    setDateTo(mainFilter.dateTo);
    setType(mainFilter.type);
    setStatus(mainFilter.status);
    setPaymentMethod(mainFilter.paymentMethod);
    setPayTo(mainFilter.payTo);
    setAccount(mainFilter.account);
    setTags(mainFilter.tags);
    setCategory(mainFilter.category);
  }, [mainFilter]);

  const { enabledPaymentMethods } = useCurrentUser();

  const paymentMethods = () =>
    PAYMENT_METHODS()?.filter?.(({ id }) => enabledPaymentMethods.includes(id));

  useEffect(() => {
    const simpleCatsArray = selectedCategories?.map?.((cat) => cat.value) || [];
    setCategory(simpleCatsArray);
  }, [selectedCategories]);

  useEffect(() => {
    const simpleClientProvidersArray =
      selectedClientProviders?.map?.(
        (clientProvider) => clientProvider.value
      ) || [];
    setPayTo(simpleClientProvidersArray);
  }, [selectedClientProviders]);

  useEffect(() => {
    const simpleStatusesArray =
      selectedStatuses?.map?.((status) => status.value) || [];
    setStatus(simpleStatusesArray);
  }, [selectedStatuses]);

  useEffect(() => {
    const simplePaymentMethodsArray =
      selectedPaymentMethods?.map?.((paymentMethod) => paymentMethod.value) ||
      [];
    setPaymentMethod(simplePaymentMethodsArray);
  }, [selectedPaymentMethods]);

  useEffect(() => {
    const simpleTagsArray = selectedTags?.map?.((tag) => tag.value) || [];
    setTags(simpleTagsArray);
  }, [selectedTags]);

  const allCurrenciesUsedByUser: any = _.sortBy(
    allCurrenciesUsed?.map?.((currency: string) => ({
      value: currency as string,
      label: getCurrencyDataByCode({ code: currency })?.name as string,
    })) || [],
    ["label"]
  );

  return (
    <Modal width="w-full md:w-auto md:min-w-[65vw]" onEscape={() => onClose()}>
      <Modal.Header
        onClose={() => onClose()}
        title={t("transactions_search.title")}
        icon={<MdSearch size={28} />}
      />
      <Modal.Body className={clsx(["!max-h-[60vh]", "md:h-auto"])}>
        <div className={clsx(["flex", "flex-col", "gap-4", "pb-2"])}>
          <form
            id={TRANSACTIONS_SEARCH_FORM}
            className={clsx(["flex", "flex-col", "gap-4"])}
            onSubmit={(e) => {
              e?.preventDefault();
              onSubmit({
                dateFrom,
                dateTo,
                type,
                paymentMethod,
                account,
                payTo,
                status,
                tags,
                category,
              });
            }}
          >
            <div
              className={clsx(
                "flex",
                "flex-col",
                "lg:flex-row",
                "gap-2",
                "md:gap-2",
                "py-4"
              )}
            >
              <div className={clsx(["md:pr-5", "flex", "flex-col", "gap-2"])}>
                <div
                  className={clsx([
                    "text-sm",
                    "md:text-base",
                    "text-gray-700",
                    "font-medium",
                  ])}
                >
                  {t("transactions_search.predefined_dates")}
                </div>
                <div className={clsx(["flex", "pb-1", "flex-col"])}>
                  <ul
                    className={clsx([
                      "md:pr-5",
                      "border-r-0",
                      "md:border-r-8",
                      "border-red-400",
                      "md:h-full",
                      "lg:min-w-[190px]",
                      "grid",
                      "grid-cols-2",
                      "lg:block",
                      "gap-1",
                      "md:gap-0",
                    ])}
                  >
                    {dateOptions().map((option, index) => {
                      return (
                        <li
                          key={index}
                          className={clsx([
                            "whitespace-nowrap",
                            "py-2",
                            "md:py-1",
                            "cursor-pointer",
                            {
                              "hover:text-gray-800 md:hover:text-red-500":
                                !option.active,
                              "font-medium text-white md:text-red-500 !bg-gray-800 md:!bg-white":
                                option.active,
                            },
                            "flex",
                            "flex-row",
                            "gap-1",
                            "items-center",
                            "bg-gray-300",
                            "md:bg-transparent",
                            "rounded-md",
                            "md:rounded-none",
                            "px-2",
                            "md:px-0",
                            "text-sm",
                            "md:text-base",
                          ])}
                          onClick={() => {
                            const { dateFrom, dateTo, active } = option;

                            if (!active) {
                              setDateFrom(dateFrom);
                              setDateTo(dateTo);
                            } else {
                              setDateFrom("");
                              setDateTo("");
                            }
                          }}
                        >
                          {option.active ? (
                            <MdCheckCircle
                              className={clsx([
                                "md:flex",
                                "md:text-red-400",
                                "text-green-400",
                              ])}
                              size={16}
                            />
                          ) : (
                            <>
                              {option?.dateFrom && option?.dateTo && (
                                <MdRadioButtonUnchecked
                                  className={clsx(["md:flex", "text-gray-800"])}
                                  size={16}
                                />
                              )}
                            </>
                          )}
                          {option.name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div
                className={clsx([
                  "flex",
                  "items-center",
                  "text-gray-400",
                  "justify-center",
                  "md:pr-10",
                ])}
              ></div>

              <div className={clsx(["flex", "flex-col", "gap-4", "w-full"])}>
                {/* {isMobile && (
                <Select
                  options={availableAccounts}
                  label="Cuenta"
                  onChange={(e) => setAccount(e?.target?.value)}
                  value={account}
                  disabled={disableFields}
                  emptyOption={{
                    label: "-- Incluir todas --",
                    value: "",
                    selected: true,
                  }}
                  form={TRANSACTIONS_SEARCH_FORM}
                />
              )} */}
                <div className={clsx(["grid", "grid-cols-2", "gap-2"])}>
                  <TextInput
                    value={dateFrom}
                    onChange={(e: any) => setDateFrom(e?.target?.value)}
                    type="date"
                    label={t("transactions_search.from")}
                    disabled={disableFields}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                  <TextInput
                    value={dateTo}
                    onChange={(e: any) => setDateTo(e?.target?.value)}
                    type="date"
                    label={t("transactions_search.to")}
                    disabled={disableFields}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                </div>

                <div className={clsx(["flex", "w-full", "flex-col", "gap-1"])}>
                  <label
                    htmlFor={""}
                    className={clsx([
                      "text-sm",
                      "md:text-base",
                      "text-gray-700",
                      "font-medium",
                    ])}
                  >
                    {t("shared.category")}
                  </label>
                  <ReactSelect
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: 14,
                      }),
                      control: (base) => ({
                        ...base,
                        minHeight: "initial",
                        fontSize: 14,
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        padding: "4px",
                      }),
                    }}
                    isSearchable={false}
                    hideSelectedOptions
                    closeMenuOnSelect={false}
                    isClearable={false}
                    menuPlacement="auto"
                    isMulti
                    className="w-full"
                    options={_.sortBy(
                      transformToSelectOptions({
                        // data: allCategoryList?.data?.data || [],
                        data: allCategoryList?.data?.data || [],
                        labelField: "category",
                        valueField: "category",
                      }),
                      "label"
                    )}
                    // disabled={disableFields}
                    // label={t("shared.category")}
                    placeholder={"Todas las categorías"}
                    onChange={(e: any) => {
                      setSelectedCategories(e);
                      // console.log(e);
                    }}
                    value={selectedCategories}
                    // emptyOption={{
                    //   label: `-- ${t("shared.select_include_all")} --`,
                    //   value: "",
                    //   selected: true,
                    // }}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                </div>

                <div className={clsx(["flex", "w-full", "flex-col", "gap-1"])}>
                  <label
                    htmlFor={""}
                    className={clsx([
                      "text-sm",
                      "md:text-base",
                      "text-gray-700",
                      "font-medium",
                    ])}
                  >
                    {t("transactions_search.origin_destination")}
                  </label>
                  <ReactSelect
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: 14,
                      }),
                      control: (base) => ({
                        ...base,
                        minHeight: "initial",
                        fontSize: 14,
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        padding: "4px",
                      }),
                    }}
                    isSearchable={false}
                    hideSelectedOptions
                    closeMenuOnSelect={false}
                    isClearable={false}
                    isMulti
                    menuPlacement="auto"
                    className="w-full"
                    options={allPayToList}
                    // disabled={disableFields}
                    // label={t("shared.category")}
                    placeholder={"Todos los orígenes y destinos"}
                    onChange={(e: any) => {
                      setSelectedClientProviders(e);
                      // console.log(e);
                    }}
                    value={selectedClientProviders}
                    // emptyOption={{
                    //   label: `-- ${t("shared.select_include_all")} --`,
                    //   value: "",
                    //   selected: true,
                    // }}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                </div>

                <div className={clsx(["flex", "w-full", "flex-col", "gap-1"])}>
                  <label
                    htmlFor={""}
                    className={clsx([
                      "text-sm",
                      "md:text-base",
                      "text-gray-700",
                      "font-medium",
                    ])}
                  >
                    {t("transactions_search.status")}
                  </label>
                  <ReactSelect
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: 14,
                      }),
                      control: (base) => ({
                        ...base,
                        minHeight: "initial",
                        fontSize: 14,
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        padding: "4px",
                      }),
                    }}
                    isSearchable={false}
                    hideSelectedOptions
                    closeMenuOnSelect={false}
                    isClearable={false}
                    isMulti
                    menuPlacement="auto"
                    className="w-full"
                    options={transformToSelectOptions({
                      data: TRANSACTION_STATUSES(),
                      labelField: "name",
                      valueField: "id",
                    })}
                    // disabled={disableFields}
                    // label={t("shared.category")}
                    placeholder={"Todos los estados"}
                    onChange={(e: any) => {
                      setSelectedStatuses(e);
                      // console.log(e);
                    }}
                    value={selectedStatuses}
                    // emptyOption={{
                    //   label: `-- ${t("shared.select_include_all")} --`,
                    //   value: "",
                    //   selected: true,
                    // }}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                </div>
                <div className={clsx(["flex", "w-full", "flex-col", "gap-1"])}>
                  <label
                    htmlFor={""}
                    className={clsx([
                      "text-sm",
                      "md:text-base",
                      "text-gray-700",
                      "font-medium",
                    ])}
                  >
                    {t("transactions_search.payment_method")}
                  </label>
                  <ReactSelect
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: 14,
                      }),
                      control: (base) => ({
                        ...base,
                        minHeight: "initial",
                        fontSize: 14,
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        padding: "4px",
                      }),
                    }}
                    isSearchable={false}
                    hideSelectedOptions
                    closeMenuOnSelect={false}
                    isClearable={false}
                    isMulti
                    menuPlacement="top"
                    className="w-full"
                    options={transformToSelectOptions({
                      data: paymentMethods(),
                      labelField: "name",
                      valueField: "id",
                    })}
                    placeholder={"Todos los métodos de pago"}
                    onChange={(e: any) => {
                      setSelectedPaymentMethods(e);
                    }}
                    value={selectedPaymentMethods}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                </div>
                <div className={clsx(["flex", "w-full", "flex-col", "gap-1"])}>
                  <label
                    htmlFor={""}
                    className={clsx([
                      "text-sm",
                      "md:text-base",
                      "text-gray-700",
                      "font-medium",
                    ])}
                  >
                    {t("tags.title")}
                  </label>
                  <ReactSelect
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: 14,
                      }),
                      control: (base) => ({
                        ...base,
                        minHeight: "initial",
                        fontSize: 14,
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        padding: "4px",
                      }),
                    }}
                    isSearchable={false}
                    hideSelectedOptions
                    closeMenuOnSelect={false}
                    isClearable={false}
                    isMulti
                    menuPlacement="top"
                    className="w-full"
                    options={allTagList?.map?.((tag) => ({
                      value: tag,
                      label: tag,
                    }))}
                    // disabled={disableFields}
                    // label={t("shared.category")}
                    placeholder={"Todas las etiquetas"}
                    onChange={(e: any) => {
                      setSelectedTags(e);
                      // console.log(e);
                    }}
                    value={selectedTags}
                    // emptyOption={{
                    //   label: `-- ${t("shared.select_include_all")} --`,
                    //   value: "",
                    //   selected: true,
                    // }}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                </div>
                <div className={clsx(["flex", "w-full", "flex-col", "gap-1"])}>
                  <label
                    htmlFor={""}
                    className={clsx([
                      "text-sm",
                      "md:text-base",
                      "text-gray-700",
                      "font-medium",
                    ])}
                  >
                    Moneda
                  </label>
                  <ReactSelect
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: 14,
                      }),
                      control: (base) => ({
                        ...base,
                        minHeight: "initial",
                        fontSize: 14,
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        padding: "4px",
                      }),
                    }}
                    isSearchable={false}
                    hideSelectedOptions
                    closeMenuOnSelect={false}
                    isClearable={false}
                    isMulti
                    menuPlacement="top"
                    className="w-full"
                    options={allCurrenciesUsedByUser}
                    placeholder="Todas las monedas"
                    onChange={(e: any) => {
                      setCurrency(e);
                    }}
                    value={currency}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                </div>
                {/* 
                <div className={clsx(["grid", "grid-cols-2", "gap-2", "hidden"])}>
                  <Select
                    disabled={disableFields}
                    options={transformToSelectOptions({
                      data: TRANSACTION_STATUSES(),
                      labelField: "name",
                      valueField: "id",
                    })}
                    label={t("transactions_search.status")}
                    onChange={(e: any) => setStatus(e?.target?.value)}
                    value={status}
                    emptyOption={{
                      label: `-- ${t("shared.select_include_all")} --`,
                      value: "",
                      selected: true,
                    }}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                  <Select
                    disabled={disableFields}
                    options={transformToSelectOptions({
                      data: paymentMethods(),
                      labelField: "name",
                      valueField: "id",
                    })}
                    label={t("transactions_search.payment_method")}
                    onChange={(e) => setPaymentMethod(e?.target?.value)}
                    value={paymentMethod}
                    emptyOption={{
                      label: `-- ${t("shared.select_include_all")} --`,
                      value: "",
                      selected: true,
                    }}
                    form={TRANSACTIONS_SEARCH_FORM}
                  />
                </div> */}
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer
        actions={[
          {
            text: t("shared.close"),
            icon: <MdClose size={32} />,
            onClick: () => onClose(),
            kind: "normal",
          },
        ]}
        leftContent={
          <>
            <ButtonGroup
              gap={1}
              align="left"
              buttons={[
                {
                  text: t("shared.search"),
                  onClick: () => {},
                  icon: <MdSearch size={ICON_SIZES.MD} />,
                  type: "submit",
                  form: TRANSACTIONS_SEARCH_FORM,
                  // classes: isMobile ? BUTTON_STYLES.HEADING : "",
                  kind: "save",
                },
                {
                  tooltip: "Limpiar campos",
                  onClick: () => {
                    setDateFrom("");
                    setDateTo("");
                    setSelectedClientProviders([]);
                    setSelectedStatuses([]);
                    setSelectedPaymentMethods([]);
                    setSelectedTags([]);
                    setSelectedCategories([]);
                  },
                  icon: <MdFilterAltOff size={ICON_SIZES.MD} />,
                  kind: "delete",
                  form: TRANSACTIONS_SEARCH_FORM,
                  hide: !showClearFilters(),
                  // classes: BUTTON_STYLES.HEADING,
                },
              ]}
            />
          </>
        }
      ></Modal.Footer>
    </Modal>
  );
};

export default TransactionsSearchModal;
