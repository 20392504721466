import clsx from "clsx";
import Button from "../Button/Button";
import ButtonWithMenu from "../ButtonWithMenu/ButtonWithMenu";

interface IButtonGroup {
  buttons?: Array<any>;
  layout?: "vertical" | "horizontal";
  align?: "center" | "left" | "right";
  gap?: number;
  fullWidth?: boolean;
}

const ButtonGroup: React.FC<IButtonGroup> = ({
  buttons,
  layout = "horizontal",
  align = "right",
  gap = 4,
  fullWidth = true,
}) => {
  if (!buttons?.length) return null;

  return (
    <div
      className={clsx([
        "flex",
        `gap-${gap}`,
        {
          "justify-center": align === "center",
          "justify-start": align === "left",
          "justify-end": align === "right",
          "w-full": fullWidth,
          "flex-row": layout === "horizontal",
          "flex-col": layout === "vertical",
        },
      ])}
    >
      {buttons.map((options, index) =>
        options?.options ? (
          <ButtonWithMenu {...options} key={index} />
        ) : (
          <Button {...options} key={index} />
        )
      )}
    </div>
  );
};

export default ButtonGroup;
