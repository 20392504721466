import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

interface ITransactionsSettings {}

const TransactionsSettings: React.FC<ITransactionsSettings> = () => {
  const { t } = useTranslation();

  const COLUMNS = [
    {
      name: "ID",
      selector: (row: any) => row.id,
      sortable: true,
      omit: true,
    },
    {
      selector: (row: any) => row.name,
      name: "Campo",
      sortable: true,
    },
  ];

  const data = [
    {
      name: "# de documento",
      id: "documentNumber",
    },
    {
      name: "Desglozar transacciones",
      id: "allowDetailedTransactions",
    },
    {
      name: "Etiquetas",
      id: "tags",
    },
    {
      name: "Comision bancaria",
      id: "fee",
    },
    {
      name: "Impuestos",
      id: "taxes",
    },
    {
      name: "Descuento",
      id: "discount",
    },
    {
      name: "Notas",
      id: "notes",
    },
    {
      name: "# de comprobante",
      id: "voucher",
    },
  ];
  return (
    <div className={clsx(["flex", "flex-col", "gap-4"])}>
      <div className={clsx(["text-sm", "text-base"])}>
        Selecciona los campos que deseas ver en el formulario de transacciones y
        manten deseleccionados todos aquellos que no quieras ver.
      </div>
      <DataTable
        columns={COLUMNS}
        data={data}
        persistTableHead
        selectableRows
        // striped
        paginationComponentOptions={{
          selectAllRowsItem: true,
          selectAllRowsItemText: t("pagination.all"),
          rowsPerPageText: t("pagination.rows_per_page"),
          rangeSeparatorText: t("pagination.separator"),
        }}
        noDataComponent={
          <div
            className={clsx([
              "px-2",
              "py-4",
              "flex",
              "items-center",
              "justify-center",
              "text-sm",
              "md:text-base",
            ])}
          >
            {t("empty_states.no_records")}
          </div>
        }
      />
      {/* <Modal.Footer
      actions={[
        {
          text: t("shared.update"),
          onClick: () => onClose(),
          kind: "primary",
          icon: <MdCheck size={32} />,
        },
        {
          text: t("shared.close"),
          onClick: () => onClose(),
          kind: "secondary",
          icon: <MdClose size={32} />,
        },
      ]}
    ></Modal.Footer> */}
    </div>
  );
};

export default TransactionsSettings;
