import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Bar, Line } from "react-chartjs-2";
import useAccountsStore from "../../store/accountsStore";
import {
  formatNumber,
  getCurrencyDataByCode,
  getPaymentMethodById,
  getRandomColor,
  getTotalByPaymentMethod,
  getTotalByTransactionType,
  getUniqueAccountsFromTransactions,
  getUniquePaymentMethodsFromTransactions,
  rgbaObjectToString,
} from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import {
  CHART_COLORS,
  CHART_TRANSPARENCY_BG_COLOR,
  EXPENSE_TYPE,
  INCOME_TYPE,
} from "../../helpers/constants";
import { getColorFromAccountSettings } from "../../helpers/accounts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

interface ITransactionsByPaymentMethodChart {
  transactions: Array<any>;
  showIncome?: boolean;
  showExpenses?: boolean;
  showBalance?: boolean;
  currency: string;
}

const TransactionsByPaymentMethodChart: React.FC<
  ITransactionsByPaymentMethodChart
> = ({
  transactions,
  showIncome = true,
  showExpenses = true,
  showBalance = true,
  currency,
}) => {
  const { t } = useTranslation();
  const currencySymbol =
    getCurrencyDataByCode({
      code: currency,
    })?.symbol || "";
  const availablePaymentMethods = getUniquePaymentMethodsFromTransactions({
    transactions,
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // aspectRatio
    borderWidth: 1,
    borderRadius: 1,
    pointRadius: 15,
    // barThickness: 40,
    // minBarLength: 0,
    // pointStyle: "triangle",
    pointHoverRadius: 30,
    elements: {
      line: {
        tension: 0.5,
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: rgbaObjectToString(CHART_COLORS.TOOLTIP.bg),
        usePointStyle: false,
        padding: 15,
        boxPadding: 4,
        callbacks: {
          label: (context: any) => {
            const label = context?.dataset?.label || "";
            const value = Number(context?.parsed?.y) || 0;

            const formattedTooltip = formatNumber(Math.abs(Number(value)));

            return `${label}: ${currencySymbol}${formattedTooltip}`;
          },
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    animations: {
      tension: {
        duration: 2000,
        easing: "linear" as "linear",
        from: 1,
        to: 0.5,
        loop: true,
      },
    },
  };

  let labels = [];

  if (showIncome) labels.push(t("summary.incomes"));
  if (showExpenses) labels.push(t("summary.expenses"));

  const datasets =
    [EXPENSE_TYPE, INCOME_TYPE]?.map((transactionType: any) => {
      return {
        label:
          transactionType === INCOME_TYPE
            ? t("shared.income")
            : t("shared.expenses"),
        data: availablePaymentMethods?.map((cat: any) => {
          if (transactionType === INCOME_TYPE) {
            const income = getTotalByPaymentMethod({
              paymentMethod: cat,
              type: transactionType,
              transactions,
            });

            return income || 0;
          } else if (transactionType === EXPENSE_TYPE) {
            const expense = getTotalByPaymentMethod({
              paymentMethod: cat,
              type: transactionType,
              transactions,
            });

            return expense ? -expense : 0;
          }

          return 0;
        }),
        backgroundColor: availablePaymentMethods.map((paymentMethod) => {
          const data = getPaymentMethodById(paymentMethod);

          if (data?.settings) {
            return rgbaObjectToString({
              ...data.settings.bg,
              a: CHART_TRANSPARENCY_BG_COLOR,
            });
          }

          return "";
        }),
        borderColor: availablePaymentMethods.map((paymentMethod) => {
          const data = getPaymentMethodById(paymentMethod);

          if (data?.settings) {
            return rgbaObjectToString({ ...data.settings.bg });
          }

          return "";
        }),
      };
    }) || [];

  const chartData = {
    labels: availablePaymentMethods?.map(
      (paymentMethod: any) => getPaymentMethodById(paymentMethod)?.name
    ),
    datasets,
  };

  return (
    <div>
      <Line
        className="w-full"
        options={options}
        data={chartData}
        height={200}
      />
    </div>
  );
};

export default TransactionsByPaymentMethodChart;
