import Routes from "./Routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, Slide } from "react-toastify";
import "moment/locale/es";
import "flag-icons";
import "react-tooltip/dist/react-tooltip.css";
import { Toaster } from "react-hot-toast";
import UpdateAvailableBanner from "./components/UpdateAvailableBanner/UpdateAvailableBanner";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { rgbaObjectToString } from "./helpers/utils";
import { TOAST_BG_COLOR, TOAST_TEXT_COLOR } from "./helpers/constants";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: 1,
      staleTime: 5 * 60 * 1000,
    },
  },
});

// moment.locale("es");

function App() {
  if ("serviceWorker" in navigator) {
    // console.log("Si tiene service worker");
    // window.addEventListener("load", () => {
    //   navigator.serviceWorker.register("/worker.js").then((registration) => {
    //     registration.unregister();
    //   });
    // });
  }

  return (
    <>
      <Toaster
        position="top-center"
        containerStyle={{
          top: 120,
        }}
        toastOptions={{
          duration: 3000,
          style: {
            backgroundColor: rgbaObjectToString(TOAST_BG_COLOR),
            color: rgbaObjectToString(TOAST_TEXT_COLOR),
            fontSize: 14,
            fontFamily: "Roboto",
          },
        }}
      />
      <ToastContainer
        transition={Slide}
        position="top-center"
        autoClose={1000}
        pauseOnHover
        pauseOnFocusLoss={false}
        closeOnClick
        limit={3}
        theme="dark"
      />
      <QueryClientProvider client={queryClient}>
        <UpdateAvailableBanner />
        <Routes />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </>
  );
}

export default App;
