import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { MdArrowBack, MdPassword } from "react-icons/md";
import TextInput from "../../components/TextInput/TextInput";
import { useEffect, useState } from "react";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { APP_TOKEN_KEY, ICON_SIZES } from "../../helpers/constants";

import { useTranslation } from "react-i18next";
import LoginFlowLayout from "../../layouts/LoginFlow/LoginFlowLayout";

const PasswordRecoveryPage: React.FC = () => {
  const { state: routeState } = useLocation();
  const { t } = useTranslation();
  const alreadyHasToken = !!localStorage?.getItem?.(APP_TOKEN_KEY);

  const [email, setEmail] = useState<string>(routeState?.email || "");
  const [isCheckingAccess, setIsCheckingAccess] = useState<boolean>(false);

  const navigate = useNavigate();

  return (
    <LoginFlowLayout
      title={t("password_recovery_page.title")}
      description={t("password_recovery_page.description")}
    >
      <form
        className={clsx([
          "flex",
          "flex-col",
          "gap-6",
          "w-full",
          "md:w-auto",
          "md:min-w-[320px]",
          "md:max-w-[320px]",
          "md:max-w-auto",
        ])}
        onSubmit={async (e: any) => {
          e?.preventDefault?.();
        }}
      >
        <TextInput
          autoComplete="username email webauthn"
          id="email"
          disabled={isCheckingAccess}
          value={email}
          onChange={(e: any) => setEmail(e?.target?.value)}
          label={t("password_recovery_page.inputs.email.label")}
          type="email"
          placeholder={t("password_recovery_page.inputs.email.placeholder")}
          required
        />
        <div className="pt-4">
          <ButtonGroup
            gap={1}
            buttons={[
              {
                disabled: isCheckingAccess,
                type: "button",
                onClick: () => {
                  navigate("/");
                },
                icon: <MdArrowBack size={ICON_SIZES.MD} />,
                kind: "normal",
              },
              {
                disabled: isCheckingAccess,
                type: "submit",
                kind: "save",
                text: t("password_recovery_page.buttons.reset.label"),
                isLoading: isCheckingAccess,
                onClick: () => {},
                icon: <MdPassword size={ICON_SIZES.MD} />,
              },
            ]}
            align="right"
          />
        </div>
      </form>
    </LoginFlowLayout>
  );
};

export default PasswordRecoveryPage;
