import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Bar } from "react-chartjs-2";
import useAccountsStore from "../../store/accountsStore";
import {
  formatNumber,
  getCurrencyDataByCode,
  getTotalByTransactionType,
  getUniqueAccountsFromTransactions,
  rgbaObjectToString,
} from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import {
  CHART_COLORS,
  CHART_TRANSPARENCY_BG_COLOR,
  EXPENSE_TYPE,
  INCOME_TYPE,
} from "../../helpers/constants";
import transactionsStore from "../../store/transactionsStore";
import useMainFilter from "../../hooks/useMainFilter";
import { MdCategory } from "react-icons/md";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

interface ITransactionsByTypeChart {
  transactions: Array<any>;
  currency: string;
}

const TransactionsByTypeChart: React.FC<ITransactionsByTypeChart> = ({
  transactions,
  currency,
}) => {
  const currencySymbol = getCurrencyDataByCode({
    code: currency,
  })?.symbol;
  const { mainFilter } = useMainFilter();

  const iconPlugin = {
    id: "iconPlugin",
    beforeDatasetDraw(chart: any, args: any, plugin: any) {
      const { ctx, data } = chart;
      const image = data.datasets[0].image;
      console.log({ args, data });

      const xPos = chart.getDatasetMeta(0).data[0].x;
      const yPos = chart.getDatasetMeta(0).data[0].y;

      const chartImage = new Image();
      chartImage.src = image;
      ctx.drawImage(chartImage, xPos - 50, yPos + 20, 20, 20);
    },
  };

  const options = {
    indexAxis: "x" as const,
    responsive: true,
    maintainAspectRatio: false,
    pointRadius: 15,
    pointHoverRadius: 30,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    borderWidth: 1,
    borderRadius: 100,
    barPercentage: 0.9,
    plugins: {
      tooltip: {
        backgroundColor: rgbaObjectToString(CHART_COLORS.TOOLTIP.bg),
        usePointStyle: false,
        padding: 15,
        boxPadding: 4,
        callbacks: {
          label: (context: any) => {
            const label = context?.dataset?.label || "";
            const value = Number(context?.parsed?.y) || 0;

            const formattedTooltip = formatNumber(Math.abs(Number(value)));

            return `${label}: ${currencySymbol}${formattedTooltip}`;
          },

          title: (datasets: any) => {
            const [title] = datasets;
            return `${title.label} por cuenta`;
          },

          footer: (datasets: any) => {
            const sum = datasets?.reduce?.((acc: any, curr: any) => {
              return Number(acc) + Math.abs(curr?.parsed?.y);
            }, 0);
            const formattedTotal = formatNumber(sum);

            return `Total: ${currencySymbol}${formattedTotal}`;
          },
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
        formatter: (value: any) => {
          return formatNumber(value);
        },
      },
    },
    scales: {
      y: {
        grace: 5,
      },
    },
  };

  const { t } = useTranslation();
  const { getAccountById } = useAccountsStore();
  const availableAccounts = getUniqueAccountsFromTransactions({
    transactions,
  })?.map?.((accountId) => getAccountById(accountId));

  let labels = [];

  if (!mainFilter?.type) {
    labels.push(t("summary.incomes"));
    labels.push(t("summary.expenses"));
    labels.push(t("summary.balance"));
  } else {
    if (mainFilter?.type === INCOME_TYPE) {
      labels.push(t("summary.incomes"));
    } else if (mainFilter?.type === EXPENSE_TYPE) {
      labels.push(t("summary.expenses"));
    }
  }

  const datasets =
    availableAccounts?.map((account: any, index: number) => {
      return {
        label: account?.name || "Transacciones sin cuenta",
        data: (() => {
          const res = [];
          let income = 0;
          let expenses = 0;
          let balance = 0;

          income = getTotalByTransactionType({
            type: INCOME_TYPE,
            account: account?.account,
            transactions,
          });

          expenses = getTotalByTransactionType({
            type: EXPENSE_TYPE,
            account: account?.account,
            transactions,
          });

          balance = income - expenses;

          if (!mainFilter?.type) {
            res.push(income, -expenses, balance);
          } else {
            if (mainFilter?.type === INCOME_TYPE) {
              res.push(income);
            } else if (mainFilter?.type === EXPENSE_TYPE) {
              res.push(-expenses);
            }
          }

          return res;
        })(),
        backgroundColor: rgbaObjectToString({
          ...account?.settings?.bg,
          a: CHART_TRANSPARENCY_BG_COLOR,
        }),
        borderColor: rgbaObjectToString({ ...account?.settings?.bg }),
        image: "https://www.svgrepo.com/show/535115/alien.svg",
        cutout: "90%",
      };
    }) || [];

  const chartData = { labels, datasets };

  return (
    <div className="w-full">
      <Bar className="w-full" options={options} data={chartData} />
    </div>
  );
};

export default TransactionsByTypeChart;
