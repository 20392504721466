import apiClient from "../../helpers/api-client";
import {
  API_ENDPOINT_ADD_INVOICE,
  API_ENDPOINT_ALL_INVOICES,
  API_ENDPOINT_DELETE_INVOICE,
  API_ENDPOINT_UPDATE_INVOICE,
} from "../../helpers/constants";

export interface User {
  id: number;
  name: string;
}

export const searchTransactions = async ({
  fieldsToSearch,
}: {
  fieldsToSearch: any;
}) => {
  try {
    return await apiClient.get(API_ENDPOINT_ALL_INVOICES, {
      params: fieldsToSearch,
    });
  } catch (_e) {
    throw _e;
  }
};

export const getTransaction = async ({
  transactionId,
}: {
  transactionId: string;
}) => {
  const res = await searchTransactions({
    fieldsToSearch: { invoice: transactionId },
  });

  return res?.data;
};

export const updateTransactions = async ({
  transactionIds,
  fieldsToUpdate,
}: {
  transactionIds: Array<string>;
  fieldsToUpdate: any;
}) => {
  return await apiClient.put(API_ENDPOINT_UPDATE_INVOICE, {
    transactionIds: JSON.stringify(transactionIds),
    params: fieldsToUpdate,
  });
};

export const updateTransaction = async ({
  transactionId,
  fieldsToUpdate,
}: {
  transactionId: string;
  fieldsToUpdate: any;
}) => {
  return await updateTransactions({
    transactionIds: [transactionId],
    fieldsToUpdate,
  });
};

export const deleteTransactions = async ({
  transactionIds,
}: {
  transactionIds: Array<string>;
}) => {
  return await apiClient.delete(
    `${API_ENDPOINT_DELETE_INVOICE}/${JSON.stringify(transactionIds)}`
  );
};

export const deleteTransaction = async ({
  transactionId,
}: {
  transactionId: string;
}) => {
  return await deleteTransactions({ transactionIds: [transactionId] });
};

export const addTransaction = async ({
  transactionId,
  fieldsToSave,
}: {
  transactionId: string;
  fieldsToSave: any;
}) => {
  console.log({ fieldsToSave });
  return await apiClient.post(API_ENDPOINT_ADD_INVOICE, {
    invoice: transactionId,
    ...fieldsToSave,
  });
};
