import DataTable from "react-data-table-component";
import {
  CATEGORIES_DEFAULT_BG_COLOR,
  CATEGORIES_DEFAULT_ICON,
  CATEGORIES_DEFAULT_TEXT_COLOR,
  EXPENSE_TYPE,
  ICON_SIZES,
  INCOME_TYPE,
  PAGINATION_MIN,
  PAGINATION_ROWS_PER_PAGE_OPTIONS,
} from "../../helpers/constants";
import {
  MdArrowDownward,
  MdCategory,
  MdFilterAlt,
  MdFilterAltOff,
} from "react-icons/md";
import { useTranslation } from "react-i18next";
import CellWithBackground from "../../pages/Transactions/BackgroundCell";

import TransactionTypeCell from "../../pages/Transactions/TransactionTypeCell";
import { ALL_USER_ICONS } from "../../helpers/user-icons";
import clsx from "clsx";
import {
  formatNumber,
  getCurrencyDataByCode,
  getTotalByCategory,
  getUniqueCategoriesFromTransactions,
  rgbaObjectToString,
} from "../../helpers/utils";
import ButtonGroupCell from "../../pages/Transactions/ButtonGroupCell";
import userPreferences from "../../store/userPreferences";
import useMainFilter from "../../hooks/useMainFilter";
import useCategories from "../../hooks/useCategories";
import { useNavigate } from "react-router-dom";

interface ICategoriesSummaryTable {
  transactions: Array<any>;
  currency: string;
}

interface ICategoryIcon {
  name: string;
}

const CategoryIcon: React.FC<ICategoryIcon> = ({ name }) => {
  const Icon = ALL_USER_ICONS.find(
    ({ name: iconName }) => name === iconName
  )?.icon;

  if (Icon) {
    return (
      <>
        <Icon size={20} className="hidden md:flex" />
        <Icon size={20} className="md:hidden" />
      </>
    );
  }

  return (
    <>
      <MdCategory size={20} className="hidden md:flex" />
      <MdCategory size={20} className="md:hidden" />
    </>
  );
};

const CategoriesSummaryTable: React.FC<ICategoriesSummaryTable> = ({
  transactions,
  currency,
}) => {
  const { tableResultsPerPage, setTableResultsPerPage } = userPreferences();
  const { getCategoryByName } = useCategories();
  const navigate = useNavigate();
  const { setMainFilter, mainFilter } = useMainFilter();

  const currencySymbol =
    getCurrencyDataByCode({
      code: currency,
    })?.symbol || "";

  const availableCategories = getUniqueCategoriesFromTransactions({
    transactions,
  });

  const formattedData = () => {
    return availableCategories?.map((category: any) => {
      return {
        category,
        income: getTotalByCategory({
          type: INCOME_TYPE,
          transactions,
          category,
        }),
        expense: getTotalByCategory({
          type: EXPENSE_TYPE,
          transactions,
          category,
        }),
        ...getCategoryByName(category),
      };
    });
  };

  const { t } = useTranslation();
  const COLUMNS = [
    {
      name: "",
      width: "60px",
      cell: (row: any) => {
        return (
          <div
            style={{
              backgroundColor: rgbaObjectToString(
                row?.settings?.bg || CATEGORIES_DEFAULT_BG_COLOR
              ),
              color: rgbaObjectToString(
                row?.settings?.color || CATEGORIES_DEFAULT_TEXT_COLOR
              ),
            }}
            className={clsx([
              "rounded-md md:rounded-md",
              "h-[30px] w-[30px]",
              "flex",
              "items-center",
              "justify-center",
            ])}
          >
            <CategoryIcon
              name={row?.settings?.icon || CATEGORIES_DEFAULT_ICON}
            />
          </div>
        );
      },
      selector: (row: any) => row.category,
    },

    {
      cell: (row: any) => {
        return (
          <CellWithBackground
            background={rgbaObjectToString(
              row?.settings?.bg || CATEGORIES_DEFAULT_BG_COLOR
            )}
            color={rgbaObjectToString(
              row?.settings?.color || CATEGORIES_DEFAULT_TEXT_COLOR
            )}
            value={row.category}
          />
        );
      },
      selector: (row: any) => row.category,
      name: t("shared.category"),
      sortable: true,
    },
    {
      name: t("shared.income"),
      selector: (row: any) => row.income,
      sortable: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={INCOME_TYPE}>
            {formatNumber(row.income)}
          </TransactionTypeCell>
        );
      },
      right: true,
      omit: mainFilter?.type === EXPENSE_TYPE,
    },
    {
      name: t("shared.expenses"),
      right: true,
      selector: (row: any) => row.expense,
      sortable: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={EXPENSE_TYPE}>
            {formatNumber(row.expense)}
          </TransactionTypeCell>
        );
      },
      omit: mainFilter?.type === INCOME_TYPE,
    },
    {
      cell: (row: any) => {
        return (
          <div
            className={clsx(["flex", "w-full", "justify-end", "items-center"])}
          >
            <ButtonGroupCell
              buttons={[
                // {
                //   icon: <MdAttachment size={ICON_SIZES.SM} />,
                //   onClick: () => {},
                //   hide: !row?.attachments?.length,
                // },
                {
                  onClick: (e: any) => {
                    // if (e?.metaKey || e?.altKey) {
                    //   window.open(
                    //     `/transactions/${mainFilter?.account || ""}`,
                    //     "_blank"
                    //   );
                    // } else {
                    //   navigate(`/transactions/${mainFilter?.account || ""}`);
                    // }

                    if (
                      mainFilter?.category?.length &&
                      mainFilter?.category?.includes(row?.category as never)
                    ) {
                      setMainFilter({
                        category:
                          mainFilter?.category?.filter?.(
                            (cat) => cat !== row.category
                          ) || [],
                      });
                      // navigate(`/transactions/${mainFilter?.account || ""}`);
                    } else {
                      setMainFilter({
                        category: [row?.category],
                      });
                      navigate(`/transactions/${mainFilter?.account || ""}`);
                    }
                  },
                  icon:
                    mainFilter?.category?.length &&
                    mainFilter?.category?.includes(row?.category as never) ? (
                      <MdFilterAltOff size={ICON_SIZES.SM} />
                    ) : (
                      <MdFilterAlt size={ICON_SIZES.SM} />
                    ),
                },
              ]}
            />
          </div>
        );
      },
      selector: (row: any) => row,
      name: "",
      sortable: false,
      right: true,
    },
  ];
  return (
    <DataTable
      columns={COLUMNS}
      data={formattedData()}
      pagination={availableCategories?.length > PAGINATION_MIN}
      highlightOnHover={false}
      persistTableHead
      sortIcon={<MdArrowDownward />}
      selectableRowsVisibleOnly
      paginationRowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
      paginationPerPage={tableResultsPerPage}
      customStyles={{
        cells: {
          style: {
            marginRight: "10px",
          },
        },
        headCells: {
          style: {
            marginRight: "10px",
          },
        },
      }}
      paginationComponentOptions={{
        selectAllRowsItem: true,
        selectAllRowsItemText: t("pagination.all"),
        rowsPerPageText: t("pagination.rows_per_page"),
        rangeSeparatorText: t("pagination.separator"),
      }}
      onChangeRowsPerPage={(rows) => {
        setTableResultsPerPage(rows);
      }}
      noDataComponent={
        <div
          className={clsx([
            "px-2",
            "py-4",
            "flex",
            "items-center",
            "justify-center",
            "text-sm",
            "md:text-base",
          ])}
        >
          {t("empty_states.no_records")}
        </div>
      }
      // striped
    />
  );
};

export default CategoriesSummaryTable;
