import { create } from "zustand";
import { v4 as uuidv4 } from "uuid";
import {
  API_ENDPOINT_ADD_LINK,
  API_ENDPOINT_ALL_LINKS,
  API_ENDPOINT_DELETE_LINK,
} from "../helpers/constants";
import apiClient from "../helpers/api-client";
import { toast } from "react-toastify";

const linksStore = (set: any, get: any) => ({
  storeLinks: [],
  isLoadingStoreLinks: false,
  errorLoadingStoreLinks: false,

  getLinks: async ({
    onError,
    onResolved,
  }: {
    onResolved: (e: any) => void;
    onError?: (e: any) => void;
  }) => {
    apiClient
      .get(API_ENDPOINT_ALL_LINKS)
      .then((res: any) => {
        onResolved(res?.data || []);
      })
      .catch((e: any) => {
        if (onError) {
          onError(e);
        } else {
          toast.error(e);
        }
      });
  },

  refreshStoreLinks: async ({
    onError,
    onResolved,
  }: {
    onResolved?: (e: any) => void;
    onError?: (e: any) => void;
  }) => {
    set({ isLoadingStoreLinks: true });
    get().getLinks({
      onError: (e: any) => {
        set({
          storeLinks: [],
          isLoadingStoreLinks: false,
          errorLoadingStoreLinks: true,
        });

        if (onError) {
          onError(e);
        } else {
          toast.error(e);
        }
      },
      onResolved: (res: any) => {
        set({
          storeLinks: res,
          errorLoadingStoreLinks: false,
          isLoadingStoreLinks: false,
        });

        if (onResolved) {
          onResolved(res);
        }
      },
    });
  },

  addLink: async ({
    onResolved = () => {},
    onError,
    params,
  }: {
    onResolved: (e: any) => void;
    onError?: (e: any) => void;
    params: {
      target: "_blank" | "";
      url: string;
      type: "url" | "tel" | "email";
      name: string;
      icon?: string;
    };
  }) => {
    const linkId = uuidv4();

    apiClient
      .post(API_ENDPOINT_ADD_LINK, { link: linkId, ...params })
      .then((res: any) => {
        onResolved(res?.data || {});
      })
      .catch((e: any) => {
        if (onError) {
          onError(e);
        } else {
          toast.error(e);
        }
      });
  },

  deleteLink: async ({
    onResolved = () => {},
    onError,
    linkId = "",
  }: {
    onResolved: (e: any) => void;
    onError?: (e: any) => void;
    linkId: string;
  }) => {
    apiClient
      .delete(`${API_ENDPOINT_DELETE_LINK}/${linkId}`)
      .then((res: any) => {
        onResolved(res?.data || {});
      })
      .catch((e: any) => {
        if (onError) {
          onError(e);
        } else {
          toast.error(e);
        }
      });
  },
});

export default create(linksStore);
