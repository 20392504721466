interface IExpenserTriangle {
  className?: string;
  size?: number;
}

const ExpenserTriangle: React.FC<IExpenserTriangle> = ({
  className,
  size = 15,
}) => {
  const width = size / 2;

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 5 10"
      className={className}
      height={size}
      width={width}
    >
      <defs id="defs2" />
      <path d="M 0,10 5,5 0,0 Z" id="path1" />
      <path fill="none" d="M -10,17 V -7 h 24 v 24 z" id="path2" />
    </svg>
  );
};

export default ExpenserTriangle;
