import { create } from "zustand";
import {
  API_ENDPOINT_ADD_INVOICE,
  API_ENDPOINT_ALL_INVOICES,
  API_ENDPOINT_CLONE_TRANSACTION,
  API_ENDPOINT_DELETE_INVOICE,
  API_ENDPOINT_UPDATE_INVOICE,
} from "../helpers/constants";
import apiClient from "../helpers/api-client";

const transactionsStore = (set: any, get: any) => ({
  allTransactionsList: [],
  filters: {
    main: {
      dateFrom: "",
      dateTo: "",
      type: "",
      status: [],
      payTo: [],
      account: "",
      searchTerm: "",
      paymentMethod: [],
      tags: [],
      category: [],
      currency: [],
    },
    year: {
      selectedYear: "",
    },
    currency: {
      selectedCurrency: "",
    },
  },
  lastUsedAccount: "",
  statsChartIsActive: false,
  errorSearchingTransactions: false,
  isSearchingTransactions: false,

  setFilter: ({ name, values }: { name: string; values: any }) => {
    const leFilters = {
      ...get().filters,
      [name]: {
        ...get().filters[name],
        ...values,
      },
    };
    set({ filters: leFilters });
  },

  setLastUsedAccount: ({ account }: { account: string }) => {
    set({ lastUsedAccount: account });
  },

  setStatsChartActive: ({ active }: { active: boolean }) => {
    set({ statsChartIsActive: active });
  },

  searchTransactions: async ({
    params = new URLSearchParams(),
  }: {
    params?: URLSearchParams;
  }) => {
    return await apiClient
      .get(API_ENDPOINT_ALL_INVOICES, {
        params,
      })
      .then((data) => {
        return data?.data;
      })
      .catch((e) => {
        return e;
      });
  },

  getLast7DaysTransactions: async ({
    onResolved = () => {},
    onError = () => {},
    params = new URLSearchParams(),
  }: {
    onResolved: (e: any) => void;
    onError: (e: any) => void;
    params: URLSearchParams;
  }) => {
    // const today = moment().add(1, "days");
    // const lastDay = today.format("YYYY-MM-DD");
    // const firstDay = today.subtract(8, "days").format("YYYY-MM-DD");

    await get().searchTransactions({
      params: new URLSearchParams({
        // dateFrom: firstDay,
        // dateTo: lastDay,
        // status: TRANSACTION_STATUS_PAID,
        account: params?.get("account") || "",
      }),
      onResolved,
      onError,
    });
  },

  updateTransaction: async ({
    transactionId,
    params,
  }: {
    transactionId: string;
    params: any;
  }) => {
    return await apiClient
      .put(API_ENDPOINT_UPDATE_INVOICE, {
        invoice: transactionId,
        ...params,
      })
      .then((data) => {
        return {
          success: true,
          data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          error,
        };
      });
  },

  updateTransactions: async ({
    transactionIds,
    params,
  }: {
    transactionIds: Array<string>;
    params: any;
  }) => {
    return await apiClient
      .put(API_ENDPOINT_UPDATE_INVOICE, {
        transactionIds: JSON.stringify(transactionIds),
        params,
      })
      .then((data) => {
        return {
          error: false,
          success: true,
          data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          error,
          data: [],
        };
      });
  },

  moveTransactionsToAccount: async ({
    to,
    transactions,
  }: {
    to: string;
    transactions: Array<string>;
  }) => {
    return await get()
      .updateTransactions({
        transactionIds: transactions,
        params: {
          account: to,
          shared: false,
        },
      })
      .then((result: any) => {
        return {
          error: false,
          success: true,
          data: result,
        };
      })
      .catch(() => {
        return {
          success: false,
          data: [],
          error: true,
        };
      });
  },

  archiveTransactions: async ({
    transactionIds,
  }: {
    transactionIds: Array<string>;
  }) => {
    let hasError = false;

    try {
      const result = await get().updateTransactions({
        transactionIds,
        params: {
          archived: true,
        },
      });

      return {
        error: false,
        success: true,
        data: result,
      };
    } catch (_e) {
      hasError = true;
    }

    return {
      success: !hasError,
      data: [],
      error: hasError,
    };
  },

  unarchiveTransactions: async ({
    transactionIds,
  }: {
    transactionIds: Array<string>;
  }) => {
    let hasError = false;

    try {
      const result = await get().updateTransactions({
        transactionIds,
        params: {
          archived: false,
        },
      });

      return {
        error: false,
        success: true,
        data: result,
      };
    } catch (_e) {
      hasError = true;
    }

    return {
      success: !hasError,
      data: [],
      error: hasError,
    };
  },

  deleteTransactions: async ({
    transactionIds,
  }: {
    transactionIds: Array<string>;
  }) => {
    return await apiClient
      .delete(
        `${API_ENDPOINT_DELETE_INVOICE}/${JSON.stringify(transactionIds)}`
      )
      .then((res: any) => {
        return {
          success: true,
          data: "",
          error: false,
        };
      })
      .catch((error) => {
        return {
          error,
          success: false,
          data: "",
        };
      });
  },

  saveTransaction: async ({
    transactionId,
    params,
  }: {
    transactionId: string;
    params: any;
  }) => {
    return await apiClient
      .post(API_ENDPOINT_ADD_INVOICE, {
        invoice: transactionId,
        ...params,
      })
      .then((data) => {
        return {
          error: false,
          success: true,
          data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          error,
        };
      });
  },

  cloneTransaction: async ({
    transactionId,
    cloneId,
    date,
  }: {
    transactionId: string;
    cloneId: string;
    date: string;
  }) => {
    return await apiClient
      .post(API_ENDPOINT_CLONE_TRANSACTION, { transactionId, cloneId, date })
      .then((data) => {
        return {
          error: false,
          success: true,
          data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          error,
        };
      });
  },

  shareTransactions: async ({
    shareWith,
    transactions = [],
  }: {
    shareWith: string;
    transactions: Array<string>;
  }) => {
    return await apiClient
      .post(`/share`, {
        transactions,
        shareWith,
      })
      .then((data) => {
        return data;
      })
      .catch((_e) => {
        return _e;
      });
  },

  getTransactionsNavigation: () => {},
});

export default create(transactionsStore);
