import clsx from "clsx";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import { useNavigate } from "react-router-dom";
import { MdArrowBack, MdPersonSearch } from "react-icons/md";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  APP_USER_ID_KEY,
  BUTTON_STYLES,
  ICON_SIZES,
} from "../../helpers/constants";
import { useMediaQuery } from "react-responsive";
import Button from "../../components/Button/Button";
import TextInput from "../../components/TextInput/TextInput";
import networkStore from "../../store/networkStore";
import { useEffect, useState } from "react";
import {
  getAcceptedInvites,
  getReceivedPendingInvites,
  getSentPendingInvites,
  showNotification,
} from "../../helpers/utils";
import PeopleList from "../../components/PeopleList/PeopleList";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Badge from "../../components/Badge/Badge";
import EmptyComponentState from "../../components/EmptyComponentState/EmptyComponentState";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import useInvites from "../../hooks/useInvites";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingWithText from "../../components/LoadingWithText/LoadingWithText";
import useUsers from "../../hooks/useUsers";

const MyNetworkPage: React.FC = () => {
  const { t } = useTranslation();
  const { allInviteList, resetCache } = useInvites();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { searchNetwork, sendInvite, deleteInvite, acceptInvite } =
    networkStore();
  const [search, setSearch] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Array<any>>([]);
  const [isSendingInvite, setIsSendingInvite] = useState<boolean>(false);
  const [pendingSentInvites, setPendingSentInvites] = useState<Array<any>>([]);
  const [pendingReceivedInvites, setPendingReceivedInvites] = useState<
    Array<any>
  >([]);
  const { getUserById } = useUsers();
  const [searchPerformed, setSearchPerformed] = useState<boolean>(false);

  const currentUserId = localStorage?.getItem?.(APP_USER_ID_KEY) || "";

  useEffect(() => {
    setPendingReceivedInvites(
      getReceivedPendingInvites({
        invites: allInviteList?.data?.data?.data || [],
        user: currentUserId,
      })
    );
    setPendingSentInvites(
      getSentPendingInvites({
        invites: allInviteList?.data?.data?.data || [],
        user: currentUserId,
      })
    );
  }, [allInviteList?.data?.data?.data, currentUserId]);

  const getUserData = (_id: string) => {
    const { email, name, picture } = getUserById({ _id }) || {};

    return { email, name, picture };
  };

  const navigate = useNavigate();

  const acceptedInvites = getAcceptedInvites({
    invites: allInviteList?.data?.data?.data || [],
    user: currentUserId,
  });

  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] =
    useState<boolean>(false);
  const [showDeleteContactConfirmDialog, setShowDeleteContactConfirmDialog] =
    useState<boolean>(false);
  const [showRejectConfirmDialog, setShowRejectConfirmDialog] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [inviteIdToDelete, setInviteIdToDelete] = useState<string>("");
  const [showCancelSentInvite, setShowCancelSentInvite] =
    useState<boolean>(false);
  const [showAcceptConfirmDialog, setShowAcceptConfirmDialog] =
    useState<boolean>(false);
  const [isAccepting, setIsAccepting] = useState<boolean>(false);
  const [showSendConfirmDialog, setShowSendConfirmDialog] =
    useState<boolean>(false);

  return (
    <>
      <TwoSidebars
        right={
          <>
            {/* <SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} /> */}
          </>
        }
      >
        <div
          className={clsx([
            "flex",
            "items-center",
            "justify-center",
            "w-full",
            "gap-4",
            "flex-col",
            "text-gray-500",
          ])}
        >
          <PageTitle
            title={t("settings.title")}
            subtitle={t("my_network_page.title")}
            rightSide={
              <ButtonGroup
                align="right"
                gap={1}
                buttons={[
                  {
                    icon: <MdArrowBack size={ICON_SIZES.SM} />,
                    tooltip: "Add new",
                    onClick: () => {
                      navigate("/settings");
                    },
                    classes: isMobile ? BUTTON_STYLES.HEADING : "",
                  },
                ]}
              />
            }
          />
          <div
            className={clsx([
              "flex",
              "flex-col",
              "w-full",
              "relative",
              "py-4",
              "pb-4",
              "-mt-8",
              "before:-z-10",
              "before:content",
              "before:absolute",
              "before:top-0",
              "before:bottom-0",
              "before:-left-4",
              "before:-right-4",
              // "before:bg-[#212f3c]/20",
              "gap-2",
            ])}
          >
            <SectionTitle title="Colabora con otros usuarios" />
            <div className="text-gray-800 text-sm">
              Intercambia transacciones con otros usuarios de eXpenser. Recibe y
              envía tus transacciones fácilmente.
            </div>

            <LazyLoadImage
              src="/images/network/top.jpg"
              className="rounded-md"
              placeholder={
                <div
                  className={clsx([
                    "flex",
                    "h-full",
                    "flex",
                    "items-center",
                    "justify-center",
                    "w-full",
                    "min-h-[200px]",
                  ])}
                >
                  <LoadingWithText />
                </div>
              }
            />

            <div className="grid grid-cols-1 w-full">
              <form
                className={clsx([
                  "flex",
                  "flex-row",
                  "gap-2",
                  "bg-amber-400",
                  "px-4 py-4",
                  "rounded-md shadow",
                ])}
                onSubmit={async (e: any) => {
                  setIsSearching(true);
                  e?.preventDefault?.();

                  await searchNetwork(search)
                    .then(({ data, success }) => {
                      if (success && data?.length) {
                        const [person] = data;
                        const isAccepted = acceptedInvites?.find?.(
                          (invite: any) =>
                            [invite.from, invite.to].includes(person._id)
                        );
                        const isSent = pendingSentInvites?.find?.(
                          (invite: any) =>
                            [invite.from, invite.to].includes(person._id)
                        );
                        const isReceived = pendingReceivedInvites?.find?.(
                          (invite: any) =>
                            [invite.from, invite.to].includes(person._id)
                        );

                        if (isAccepted) {
                          showNotification({
                            kind: "success",
                            message:
                              "El usuario ya está en tu lista de contactos",
                          });
                          setSearchResults([]);
                          setSearchPerformed(false);
                        } else if (isSent) {
                          showNotification({
                            kind: "success",
                            message:
                              "Ya enviaste una invitación a este usuario",
                          });
                          setSearchResults([]);
                          setSearchPerformed(false);
                        } else if (isReceived) {
                          showNotification({
                            kind: "info",
                            message: "Tienes una invitación de este usuario",
                          });
                          setSearchResults([]);
                          setSearchPerformed(false);
                        } else {
                          setSearchResults(data || []);
                          setSearchPerformed(true);
                        }
                      } else {
                        showNotification({
                          kind: "error",
                          message: "El usuario no está disponible",
                        });
                        setSearchResults([]);
                        setSearchPerformed(false);
                      }
                    })
                    .catch(() => {
                      setSearchResults([]);
                      console.log("Error en la busqueda");
                      setSearchPerformed(true);
                    })
                    .finally(() => {
                      setIsSearching(false);
                    });
                }}
                // id={FORM_ID}
              >
                <TextInput
                  value={search}
                  onChange={(e: any) => setSearch(e?.target?.value)}
                  label={t("my_network_page.inputs.email.label")}
                  autoComplete="email"
                  placeholder={t("my_network_page.inputs.email.placeholder")}
                  type="email"
                  required
                  disabled={isSearching}
                  readOnly={isSearching}
                  // form={FORM_ID}
                />
                <div className={clsx(["flex", "items-end"])}>
                  <Button
                    type="submit"
                    kind="save"
                    onClick={() => {}}
                    icon={<MdPersonSearch size={ICON_SIZES.SM} />}
                    disabled={isSearching}
                    isLoading={isSearching}
                  />
                </div>
              </form>
            </div>
          </div>

          {/* <InfoBlock message="Puedes conectar con otros usuarios para compartir transacciones." /> */}

          {searchPerformed && (
            <div className={clsx(["flex", "flex-col", "w-full", "gap-2"])}>
              <SectionTitle title="Resultados de busqueda" />
              {searchResults?.length ? (
                <PeopleList
                  people={searchResults}
                  actions={["SEND"]}
                  onActionClick={async ({ action, invite, user }) => {
                    if (action === "SEND") {
                      setInviteIdToDelete(invite);
                      setShowSendConfirmDialog(true);
                    }
                  }}
                />
              ) : (
                <div className="text-sm">No hay resultados</div>
              )}
            </div>
          )}

          <details className={clsx(["flex", "flex-col", "gap-1", "w-full"])}>
            <summary
              className={clsx([
                "text-sm",
                "md:text-base",
                "text-gray-700",
                "font-medium",
                "items-center",
                "border-b border-dashed",
                "py-1",
              ])}
            >
              <div
                className={clsx([
                  "inline-flex",
                  "items-center",
                  "gap-2",
                  "w-[90%]",
                  "justify-between",
                ])}
              >
                <div
                  className={clsx({
                    uppercase: true,
                    "font-medium": true,
                    "text-sm": true,
                    "text-gray-900": true,
                  })}
                >
                  Contactos
                </div>
                <Badge kind="info" text={`${acceptedInvites?.length}`} />
              </div>
            </summary>
            <div className="pt-2">
              {acceptedInvites?.length ? (
                <PeopleList
                  twoColumns
                  onActionClick={async ({ action, invite, user }) => {
                    if (action === "DELETE") {
                      setInviteIdToDelete(invite);
                      setShowDeleteContactConfirmDialog(true);
                    }
                    if (action === "INFO") {
                    }
                  }}
                  people={
                    acceptedInvites?.map?.((invite: any) => {
                      return {
                        email: getUserData(
                          invite.iSentTheInvite ? invite.from : invite.to
                        )?.email,
                        picture: getUserData(
                          invite.iSentTheInvite ? invite.from : invite.to
                        )?.picture,
                        name: getUserData(
                          invite.iSentTheInvite ? invite.from : invite.to
                        )?.name,
                        _id: invite._id,
                      };
                    }) || []
                  }
                  actions={["DELETE", "INFO"]}
                />
              ) : (
                <EmptyComponentState message="Aún no tienes conexiones. Puedes conectar con otros usuarios de eXpenser por medio de su correo electrónico." />
              )}
            </div>
          </details>

          <details
            className={clsx(["flex", "flex-col", "gap-1", "w-full"])}
            open={!!pendingSentInvites?.length}
          >
            <summary
              className={clsx([
                "text-sm",
                "md:text-base",
                "text-gray-700",
                "font-medium",
                "items-center",
                "border-b border-dashed",
                "py-1",
              ])}
            >
              <div
                className={clsx([
                  "inline-flex",
                  "items-center",
                  "gap-2",
                  "w-[90%]",
                  "justify-between",
                ])}
              >
                <div
                  className={clsx({
                    uppercase: true,
                    "font-medium": true,
                    "text-sm": true,
                    "text-gray-900": true,
                  })}
                >
                  Invitaciones Enviadas
                </div>
                <Badge kind="info" text={`${pendingSentInvites?.length}`} />
              </div>
            </summary>
            <div className="pt-2">
              {pendingSentInvites?.length ? (
                <PeopleList
                  onActionClick={async ({ action, invite, user }) => {
                    if (action === "CANCEL") {
                      setInviteIdToDelete(invite);
                      setShowCancelSentInvite(true);
                    }
                  }}
                  people={
                    pendingSentInvites?.map?.((invite: any) => {
                      return {
                        email: getUserData(invite.to)?.email,
                        picture: getUserData(invite.to)?.picture,
                        name: getUserData(invite.to)?.name,
                        _id: invite._id,
                      };
                    }) || []
                  }
                  actions={["CANCEL"]}
                />
              ) : (
                <EmptyComponentState message="Las invitaciones que envíes aparecerán aquí hasta que sean aprobada o rechazadas." />
              )}
            </div>
          </details>

          <details
            className={clsx(["flex", "flex-col", "gap-1", "w-full"])}
            open={!!pendingReceivedInvites?.length}
          >
            <summary
              className={clsx([
                "text-sm",
                "md:text-base",
                "text-gray-700",
                "font-medium",
                "items-center",
                "border-b border-dashed",
                "py-1",
              ])}
            >
              <div
                className={clsx([
                  "inline-flex",
                  "items-center",
                  "gap-2",
                  "w-[90%]",
                  "justify-between",
                ])}
              >
                <div
                  className={clsx({
                    uppercase: true,
                    "font-medium": true,
                    "text-sm": true,
                    "text-gray-900": true,
                  })}
                >
                  Invitaciones Recibidas
                </div>
                <Badge kind="info" text={`${pendingReceivedInvites?.length}`} />
              </div>
            </summary>
            <div className="pt-2">
              {pendingReceivedInvites?.length ? (
                <PeopleList
                  onActionClick={async ({ action, invite, user }) => {
                    if (action === "REJECT") {
                      setInviteIdToDelete(invite);
                      setShowRejectConfirmDialog(true);
                    } else if (action === "ACCEPT") {
                      setInviteIdToDelete(invite);
                      setShowAcceptConfirmDialog(true);
                    }
                  }}
                  people={
                    pendingReceivedInvites?.map?.((invite: any) => {
                      return {
                        email: getUserData(invite.from)?.email,
                        picture: getUserData(invite.from)?.picture,
                        name: getUserData(invite.from)?.name,
                        _id: invite._id,
                      };
                    }) || []
                  }
                  actions={["REJECT", "ACCEPT"]}
                />
              ) : (
                <EmptyComponentState message="No tienes solicitudes de conexión." />
              )}
            </div>
          </details>
        </div>
      </TwoSidebars>
      {showDeleteConfirmDialog && (
        <ConfirmDialog
          description="¿Deseas eliminar la invitación?"
          title={"Eliminar invitación"}
          onClose={() => {
            if (!isDeleting) {
              setShowDeleteConfirmDialog(false);
            }
          }}
          onConfirm={async () => {
            setIsDeleting(true);
            return await deleteInvite({ inviteIds: [inviteIdToDelete] })
              .then(async () => {
                await resetCache();
                setShowDeleteConfirmDialog(false);
                showNotification({
                  kind: "success",
                  message: "Invitación eliminada correctamente",
                });
                setInviteIdToDelete("");
              })
              .catch(() => {
                showNotification({
                  kind: "error",
                  message: "Error al eliminar la invitación",
                });
              })
              .finally(() => {
                setIsDeleting(false);
              });
          }}
          confirmKind="delete"
          isLoading={isDeleting}
        />
      )}
      {showCancelSentInvite && (
        <ConfirmDialog
          description="¿Deseas cancelar la invitación?"
          title={"Cancelar invitación"}
          onClose={() => {
            if (!isDeleting) {
              setShowCancelSentInvite(false);
            }
          }}
          onConfirm={async () => {
            setIsDeleting(true);
            return await deleteInvite({ inviteIds: [inviteIdToDelete] })
              .then(async () => {
                await resetCache();
                setShowCancelSentInvite(false);
                showNotification({
                  kind: "success",
                  message: "Invitación cancelada correctamente",
                });
                setInviteIdToDelete("");
              })
              .catch(() => {
                showNotification({
                  kind: "error",
                  message: "Error al cancelar la invitación",
                });
              })
              .finally(() => {
                setIsDeleting(false);
              });
          }}
          confirmKind="delete"
          isLoading={isDeleting}
        />
      )}
      {showDeleteContactConfirmDialog && (
        <ConfirmDialog
          description="¿Deseas eliminar tu contacto?"
          title={"Eliminar contacto"}
          onClose={() => {
            if (!isDeleting) {
              setShowDeleteContactConfirmDialog(false);
            }
          }}
          onConfirm={async () => {
            setIsDeleting(true);
            return await deleteInvite({ inviteIds: [inviteIdToDelete] })
              .then(async () => {
                await resetCache();
                setShowDeleteContactConfirmDialog(false);
                showNotification({
                  kind: "success",
                  message: "Contacto eliminado correctamente",
                });
                setInviteIdToDelete("");
              })
              .catch(() => {
                showNotification({
                  kind: "error",
                  message: "Error al eliminar el contacto",
                });
              })
              .finally(() => {
                setIsDeleting(false);
              });
          }}
          confirmKind="delete"
          isLoading={isDeleting}
        />
      )}
      {showRejectConfirmDialog && (
        <ConfirmDialog
          description="¿Deseas rechazar la invitación?"
          title={"Rechazar invitación"}
          onClose={() => {
            if (!isDeleting) {
              setShowRejectConfirmDialog(false);
            }
          }}
          onConfirm={async () => {
            setIsDeleting(true);
            return await deleteInvite({ inviteIds: [inviteIdToDelete] })
              .then(async () => {
                await resetCache();
                setShowRejectConfirmDialog(false);
                showNotification({
                  kind: "success",
                  message: "Invitación rechazada",
                });
                setInviteIdToDelete("");
              })
              .catch(() => {
                showNotification({
                  kind: "error",
                  message: "Error al rechazar la invitación",
                });
              })
              .finally(() => {
                setIsDeleting(false);
              });
          }}
          confirmKind="delete"
          isLoading={isDeleting}
        />
      )}
      {showAcceptConfirmDialog && (
        <ConfirmDialog
          description="¿Deseas aceptar la invitación?"
          title={"Aceptar invitación"}
          onClose={() => {
            if (!isAccepting) {
              setShowAcceptConfirmDialog(false);
            }
          }}
          onConfirm={async () => {
            setIsAccepting(true);
            await acceptInvite({ inviteIds: [inviteIdToDelete] })
              .then(async () => {
                await resetCache();
                setShowAcceptConfirmDialog(false);
                showNotification({
                  kind: "success",
                  message: "Invitación aceptada",
                });
              })
              .catch(() => {
                showNotification({
                  kind: "error",
                  message: "Error al aceptar la invitación",
                });
              })
              .finally(() => {
                setIsAccepting(false);
              });
          }}
          confirmKind="save"
          isLoading={isAccepting}
        />
      )}
      {showSendConfirmDialog && (
        <ConfirmDialog
          description="¿Deseas enviar la invitación?"
          title={"Enviar invitación"}
          onClose={() => {
            if (!isSendingInvite) {
              setShowSendConfirmDialog(false);
            }
          }}
          onConfirm={async () => {
            setIsSendingInvite(true);
            await sendInvite({
              to: inviteIdToDelete,
            })
              .then(async (success) => {
                if (success) {
                  await resetCache();
                  showNotification({
                    kind: "success",
                    message: "Invitación enviada",
                  });
                  setSearchResults([]);
                  setSearchPerformed(false);
                  setShowSendConfirmDialog(false);
                  setInviteIdToDelete("");
                } else {
                  showNotification({
                    kind: "error",
                    message: "Error al enviar la invitación",
                  });
                }
              })
              .catch(() => {
                showNotification({
                  kind: "error",
                  message: "Error al enviar la invitación",
                });
              })
              .finally(() => {
                setIsSendingInvite(false);
              });
          }}
          confirmKind="save"
          isLoading={isSendingInvite}
        />
      )}
    </>
  );
};

export default MyNetworkPage;
