import clsx from "clsx";
import TextInput from "../TextInput/TextInput";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ITableSearch {
  onSubmit: (data: any) => void;
  onChange: (data: any) => void;
}

const TableSearch: React.FC<ITableSearch> = ({ onSubmit, onChange }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    onChange(searchTerm);
  }, [searchTerm, onChange]);

  return (
    <form
      className={clsx(["flex", "gap-2", "items-end"])}
      onSubmit={(e: any) => {
        e?.preventDefault?.();
        onSubmit(searchTerm);
      }}
    >
      <TextInput
        value={searchTerm}
        onChange={(e) => setSearchTerm(e?.target?.value)}
        // label="Buscar"
        type="search"
        placeholder={t("shared.quick_search")}
      />
    </form>
  );
};

export default TableSearch;
