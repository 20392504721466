import clsx from "clsx";
import { MdAdd, MdBugReport, MdError, MdRefresh } from "react-icons/md";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import { ICON_SIZES } from "../../helpers/constants";
import useTransactions from "../../hooks/useTransactions";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import EmptyComponentState from "../EmptyComponentState/EmptyComponentState";

const NoTransactionsBanner: React.FC = () => {
  const navigate = useNavigate();
  const { resetCache } = useTransactions();
  const [isReloading, setIsReloading] = useState<boolean>(false);

  return (
    <EmptyComponentState
      message="Aún no tienes transacciones en tu base de datos."
      buttons={[
        {
          kind: "normal",
          disabled: isReloading,
          onClick: async () => {
            setIsReloading(true);
            await resetCache();
            window.location.reload();
            setIsReloading(false);
          },
          icon: <MdRefresh size={ICON_SIZES.MD} />,
        },
        {
          text: "Agregar",
          kind: "save",
          disabled: isReloading,
          isLoading: isReloading,
          onClick: async () => {
            navigate("/transactions/expense");
          },
          icon: <MdAdd size={ICON_SIZES.MD} />,
        },
      ]}
    />
  );
};

export default NoTransactionsBanner;
