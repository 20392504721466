import clsx from "clsx";
import { MdBugReport, MdError, MdRefresh } from "react-icons/md";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import { ICON_SIZES } from "../../helpers/constants";
import useTransactions from "../../hooks/useTransactions";
import { useState } from "react";

const ConnectionErrorBanner: React.FC = () => {
  const { resetCache } = useTransactions();
  const [isReloading, setIsReloading] = useState<boolean>(false);

  return (
    <div
      className={clsx([
        "flex",
        "w-full",
        "items-center",
        "justify-center",
        "flex-col",
        "gap-3",
        "p-4",
        "border border-dashed border-red-400 rounded-md",
        // "h-[calc(100vh-220px)]",
      ])}
    >
      <div
        className={clsx([
          "flex",
          "flex-row",
          "gap-2",
          "items-center",
          "w-full",
        ])}
      >
        <div
          className={clsx([
            "flex",
            "gap-2",
            "w-full",
            "items-center",
            "justify-start",
          ])}
        >
          <MdError size={ICON_SIZES.SM} className={clsx(["text-red-600"])} />
          <div className={clsx(["font-medium", "text-red-400s", "text-sm"])}>
            Error de conexión
          </div>
        </div>
      </div>
      <div className={clsx(["flex", "flex-col", "gap-3", "text-xs", "pb-5"])}>
        <p>
          Ocurrió un error cargando algunos de tus datos. Puede deberse a una
          conexión inestable.
        </p>
        <p>Si el problema persiste puedes reportarlo.</p>
      </div>
      <ButtonGroup
        align="right"
        buttons={[
          {
            kind: "normal",
            disabled: isReloading,
            text: "Reportar",
            onClick: () => {},
            icon: (
              <MdBugReport
                size={ICON_SIZES.MD}
                className={clsx(["text-red-400"])}
              />
            ),
          },
          {
            text: "Recargar",
            kind: "save",
            disabled: isReloading,
            isLoading: isReloading,
            onClick: async () => {
              setIsReloading(true);
              await resetCache();
              window.location.reload();
              setIsReloading(false);
            },
            icon: <MdRefresh size={ICON_SIZES.MD} />,
          },
        ]}
        gap={1}
      />
    </div>
  );
};

export default ConnectionErrorBanner;
