import { useMemo } from "react";
import transactionsStore from "../store/transactionsStore";

export default function useMainFilter() {
  const { filters, setFilter } = transactionsStore();

  const {
    main = {
      dateFrom: "",
      dateTo: "",
      type: "",
      payTo: [],
      status: [],
      account: "",
      paymentMethod: [],
      tags: [],
      category: [],
    },
  } = filters || {};

  const mainFilter = useMemo(() => main, [main]);
  const isMainFilterActive = useMemo(
    () =>
      !!(
        mainFilter.dateFrom ||
        mainFilter.dateTo ||
        mainFilter.payTo?.length ||
        mainFilter.status?.length ||
        mainFilter.paymentMethod?.length ||
        mainFilter.tags?.length ||
        mainFilter?.category?.length
      ),
    [mainFilter]
  );

  return {
    mainFilter,
    setMainFilter: (values: any) => {
      setFilter({
        name: "main",
        values,
      });
    },
    isMainFilterActive,
  };
}
