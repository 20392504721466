import clsx from "clsx";

interface ISectionTitle {
  title: string;
  className?: string;
}

const SectionTitle: React.FC<ISectionTitle> = ({ title, className }) => {
  return (
    <div
      className={clsx([
        "flex",
        "flex-row",
        "items-center",
        "gap-2",
        "w-full",
        {
          uppercase: true,
          "font-medium": true,
          "text-sm": true,
          "text-gray-900": true,
        },
        className,
      ])}
    >
      <h1 className={clsx(["flex", "whitespace-nowrap"])}>{title}</h1>
      {/* <div
        className={clsx([
          "flex",
          "h-[1px]",
          "w-full",
          "bg-gradient-to-r",
          "from-primary/10 dark:from-gray-300",
          "to-primary/1 dark:to-gray-100",
        ])}
      /> */}
      {/* <Button
        icon={<MdRemove size={16} />}
        onClick={() => {}}
        classes={clsx(["p-0", "rounded-sm"])}
      /> */}
    </div>
  );
};

export default SectionTitle;
