import {
  MdAccountBalance,
  MdCategory,
  MdCheck,
  MdDelete,
  MdEdit,
  MdPlusOne,
  MdUpdate,
} from "react-icons/md";

export const CONFIRM_DIALOG_ICON_SIZE = 28;

export const DELETE_ICON = MdDelete;
export const EDIT_ICON = MdDelete;
export const UPDATE_ICON = MdCheck;
export const ADD_ICON = MdCheck;
export const PLUS_ONE_ICON = MdPlusOne;

export const ACCOUNT_ICON = MdAccountBalance;
export const CATEGORY_ICON = MdCategory;

export const DELETE_ICON_CLASSES = "";
export const EDIT_ICON_CLASSES = "";
export const ACCOUNT_ICON_CLASSES = "";
