import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getCategories } from "../api/categories";
import _ from "lodash";

export default function useCategories() {
  const queryClient = useQueryClient();

  const categories = useQuery({
    queryFn: async () => await getCategories(),
    queryKey: ["all-categories"],
  });

  const getCategoryByName = (category: string) => {
    const allCategories = categories?.data?.data;

    return _.find(allCategories, { category });
  };

  const resetCache = async () => {
    return await queryClient.refetchQueries({ queryKey: ["all-categories"] });
  };

  return {
    allCategoryList: categories,
    getCategoryByName,
    resetCache,
  };
}
